import {SimulationServiceClient} from "@wisogis/simulation-sdk";
import {getIdToken} from "./userIdentity";
import {UserServiceClient} from "@wisogis/user-details-sdk";


export const simulationApiClient = new SimulationServiceClient({
    endpoint: "https://simulation-api.wisogis-noprod.aws.tractebel-engie.com",
})

export const userApiClient = new UserServiceClient({
    endpoint: "https://user-api.wisogis-noprod.aws.tractebel-engie.com",
})


Object.values([simulationApiClient, userApiClient]).forEach((apiClient) => {
    apiClient.middlewareStack.add(
        (next, _) => async (args) => {
            args.request.headers["Authorization"] =
                await (async () => `Bearer ${getIdToken()}`)();
            return await next(args);
        },
        {
            step: "build",
            name: "addJwtToken",
            tags: [],
        },
    );
})
