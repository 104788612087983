import { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import Amplify from "aws-amplify";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import authImage from "../assets/authImage.jpg";
import tractebelLogo from "../assets/tractebelsolo.png";
import wisogisLogo from "../assets/wisogisblue.png";
import Agreement from "./Agreement";
import { showErrorSnackbar } from "../actions/snackbarActions";
import styled from "styled-components";

const LOGIN = "login";
const NEW_PASSWORD = "new_password";
const RESET_PASSWORD = "reset_password";
const RESET_PASSWORD_2 = "reset_password_2";

const NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 32px;
`;

const ImageRight = () => (
  <div style={{ flex: 1, maxWidth: "50%", position: "relative" }}>
    <img
      src={authImage}
      style={{
        maxWidth: "100%",
        maxHeight: "100vh",
        objectFit: "cover",
        minHeight: "100vh",
        objectPosition: "left",
      }}
      alt="background"
    ></img>
  </div>
);

const Title = ({ title, instruction }) => (
  <div style={{ marginBottom: 32 }}>
    <Typography variant="h5" style={{ fontWeight: "500" }}>
      {title}
    </Typography>
    <Typography>{instruction}</Typography>
  </div>
);

const Disclaimer = () => (
  <Typography
    style={{
      margin: 64,
      marginBottom: 0,
      flex: 1,
      display: "flex",
      alignItems: "flex-end",
      textAlign: "justify",
    }}
    className="copyright"
  >
    This website is fully subject to Belgian law on copyright, trademarks and,
    in general, on intellectual property. The Tractebel Belgium trademarks and
    logos (semi-figurative trademarks), including WISOGIS and its logo, shown on
    the Website are registered trademarks. Any full or partial reproduction or
    representation, alone or in company with other elements, without the prior
    express written authorisation of Tractebel Belgium is strictly forbidden.
  </Typography>
);

const EngieLogos = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    <img
      src={tractebelLogo}
      alt="logo tractebel"
      style={{ maxWidth: 200, height: "fit-content" }}
    />
    <img
      src={wisogisLogo}
      alt="logo wisogis"
      style={{ maxWidth: 172, height: "fit-content" }}
    />
  </div>
);

export default function AuthComp({ checkAuthentication }) {
  const [authScreen, setAuthScreen] = useState(LOGIN);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentEmail, setCurrentEmail] = useState(null);
  // const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  if (authScreen === LOGIN) {
    const loginValidationSchema = Yup.object().shape({
      username: Yup.string()
        .email("Valid email required")
        .required("Username required")
        .typeError("Invalid username or email"),
      password: Yup.string()
        .required("Password required")
        .typeError("Must be a valid password"),
    });

    return (
      <>
        {/* <Modal
          open={open}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
        >
          <Agreement onClose={() => setOpen(false)} />
        </Modal> */}
        <div style={{ display: "flex" }}>
          <FormContainer>
            <EngieLogos />
            <div style={{ margin: 64, flex: 1 }}>
              <Title title="Login" instruction="Fill in your account" />
              <Formik
                key="form1"
                initialValues={{
                  username: "",
                  password: "",
                }}
                validationSchema={loginValidationSchema}
                onSubmit={(values) => {
                  setCurrentEmail(values.username);
                  Amplify.Auth.signIn(values)
                    .then((user) => {
                      if (user.challengeName === NEW_PASSWORD_REQUIRED) {
                        setCurrentUser(user);
                        setAuthScreen(NEW_PASSWORD);
                      } else {
                        checkAuthentication();
                      }
                    })
                    .catch((err) => dispatch(showErrorSnackbar(err.message)));
                }}
              >
                {({ touched, errors, values, handleChange, handleSubmit }) => (
                  <form key="login-form" onSubmit={handleSubmit}>
                    <div style={{ marginBottom: 16 }}>
                      <TextField
                        type="text"
                        name="username"
                        label={(touched.username && errors.username) || "Email"}
                        error={
                          touched.username && errors.username !== undefined
                        }
                        autoComplete="username"
                        onChange={handleChange}
                        value={values.username}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div style={{ marginBottom: 16 }}>
                      <TextField
                        type="password"
                        name="password"
                        label={
                          (touched.password && errors.password) || "Password"
                        }
                        error={
                          touched.password && errors.password !== undefined
                        }
                        autoComplete="current-password"
                        onChange={handleChange}
                        value={values.password}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <Typography
                        color="primary"
                        variant="button"
                        onClick={() => setAuthScreen(RESET_PASSWORD)}
                      >
                        Forgot password?
                      </Typography>
                      <div style={{ marginTop: 16 }}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{ borderRadius: 0, padding: "12px 24px" }}
                        >
                          Login
                        </Button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
            <Disclaimer />
          </FormContainer>
          <ImageRight />
        </div>
      </>
    );
  } else if (authScreen === NEW_PASSWORD) {
    const newPasswordValidationSchema = Yup.object().shape({
      newpass: Yup.string()
        .required("Password required")
        .min(8, "Password should contain 8 characters")
        .matches(
          /(?=.*?[a-z])(?=.*?[0-9])/,
          "Password must have at least 1 letter and 1 number"
        )
        .typeError("Must be a valid password"),
      newpassrepeat: Yup.string()
        .required("Password required")
        .oneOf([Yup.ref("newpass")], "Passwords do not match")
        .typeError("Must be a valid password"),
    });

    return (
      <div style={{ display: "flex" }}>
        <FormContainer>
          <EngieLogos />
          <div style={{ margin: 64, flex: 1 }}>
            <Title
              title="New Password"
              instruction="Please enter a new password"
            />
            <Formik
              key="form2"
              initialValues={{
                newpass: "",
                newpassrepeat: "",
              }}
              validationSchema={newPasswordValidationSchema}
              onSubmit={(values) => {
                Amplify.Auth.completeNewPassword(currentUser, values.newpass, {
                  email: currentEmail,
                })
                  .then((user) => {
                    setAuthScreen(LOGIN);
                  })
                  .catch((err) => dispatch(showErrorSnackbar(err.message)));
              }}
            >
              {({ touched, errors, values, handleChange, handleSubmit }) => (
                <form key="new-password-form" onSubmit={handleSubmit}>
                  <div style={{ marginBottom: 16 }}>
                    <TextField
                      type="password"
                      name="newpass"
                      label={
                        (touched.password && errors.newpass) || "New password"
                      }
                      error={touched.newpass && errors.newpass !== undefined}
                      onChange={handleChange}
                      value={values.newpass}
                      style={{ width: "100%" }}
                    />
                    <TextField
                      type="password"
                      name="newpassrepeat"
                      label={
                        (touched.newpassrepeat && errors.newpassrepeat) ||
                        "Repeat password"
                      }
                      error={
                        touched.newpassrepeat &&
                        errors.newpassrepeat !== undefined
                      }
                      onChange={handleChange}
                      value={values.newpassrepeat}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <div style={{ marginBottom: 16 }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ borderRadius: 0, padding: "12px 24px" }}
                      >
                        Submit
                      </Button>
                    </div>
                    <Typography
                      color="primary"
                      variant="button"
                      onClick={() => setAuthScreen(LOGIN)}
                    >
                      Back to login
                    </Typography>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <Disclaimer />
        </FormContainer>
        <ImageRight />
      </div>
    );
  } else if (authScreen === RESET_PASSWORD) {
    const resetPasswordValidationSchema = Yup.object().shape({
      email_reset: Yup.string()
        .email("Valid email required")
        .required("Email required")
        .typeError("Invalid email"),
    });
    return (
      <div style={{ display: "flex" }}>
        <FormContainer>
          <EngieLogos />
          <div style={{ margin: 64, flex: 1 }}>
            <Title
              title="Reset Password"
              instruction="Please enter your email address"
            />
            <Formik
              key="form3"
              initialValues={{
                email_reset: "",
              }}
              validationSchema={resetPasswordValidationSchema}
              onSubmit={(values) => {
                setCurrentEmail(values.email_reset);
                Amplify.Auth.forgotPassword(values.email_reset)
                  .then((user) => {
                    setAuthScreen(RESET_PASSWORD_2);
                  })
                  .catch((err) => {
                    dispatch(showErrorSnackbar(err.message));
                    setAuthScreen(LOGIN);
                  });
              }}
            >
              {({ touched, errors, values, handleChange, handleSubmit }) => (
                <form key="reset-password-form" onSubmit={handleSubmit}>
                  <TextField
                    type="text"
                    name="email_reset"
                    label={
                      (touched.email_reset && errors.email_reset) || "Email"
                    }
                    error={
                      touched.email_reset && errors.email_reset !== undefined
                    }
                    autoComplete="email_reset"
                    onChange={handleChange}
                    value={values.email_reset}
                    style={{ width: "100%" }}
                  />
                  <div style={{ textAlign: "right" }}>
                    <div style={{ marginTop: 16, marginBottom: 16 }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ borderRadius: 0, padding: "12px 24px" }}
                      >
                        Send
                      </Button>
                    </div>
                    <Typography
                      color="primary"
                      variant="button"
                      onClick={() => setAuthScreen(LOGIN)}
                    >
                      Back to login
                    </Typography>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <Disclaimer />
        </FormContainer>
        <ImageRight />
      </div>
    );
  } else if (authScreen === RESET_PASSWORD_2) {
    const resetPassword2ValidationSchema = Yup.object().shape({
      code: Yup.string()
        .required("Confirmation code required")
        .typeError("Invalid code"),
      password: Yup.string()
        .required("Password required")
        .typeError("Must be a valid password")
        .min(8, "Password should contain 8 characters")
        .matches(
          /(?=.*?[a-z])(?=.*?[0-9])/,
          "Password must have at least 1 letter and 1 number"
        ),
    });
    return (
      <div style={{ display: "flex" }}>
        <FormContainer>
          <EngieLogos />
          <div style={{ margin: 64, flex: 1 }}>
            <Formik
              key="form4"
              initialValues={{
                code: "",
                password: "",
              }}
              validationSchema={resetPassword2ValidationSchema}
              onSubmit={(values) => {
                Amplify.Auth.forgotPasswordSubmit(
                  currentEmail,
                  values.code,
                  values.password
                )
                  .then((user) => {
                    setAuthScreen(LOGIN);
                  })
                  .catch((err) => dispatch(showErrorSnackbar(err.message)));
              }}
            >
              {({ touched, errors, values, handleChange, handleSubmit }) => (
                <form key="reset-password-2-form" onSubmit={handleSubmit}>
                  <TextField
                    type="text"
                    name="code"
                    label={
                      (touched.code && errors.code) ||
                      "Confirmation code from email"
                    }
                    error={touched.code && errors.code !== undefined}
                    onChange={handleChange}
                    value={values.code}
                    style={{ width: "100%" }}
                  />
                  <div />
                  <TextField
                    type="password"
                    name="password"
                    label={
                      (touched.password && errors.password) || "New password"
                    }
                    error={touched.password && errors.password !== undefined}
                    onChange={handleChange}
                    value={values.password}
                    style={{ width: "100%" }}
                  />
                  <div style={{ textAlign: "right" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{
                        marginTop: 32,
                        marginBottom: 16,
                        borderRadius: 0,
                        padding: "12px 24px",
                      }}
                    >
                      Submit
                    </Button>
                    <div />
                    <Typography
                      color="primary"
                      variant="button"
                      onClick={() => setAuthScreen(LOGIN)}
                    >
                      Back to login
                    </Typography>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <Disclaimer />
        </FormContainer>
        <ImageRight />
      </div>
    );
  }
}
