export const SimpleSpinner = ({ message = "Generating PDF..." }) => (
  <div
    style={{
      display: "grid",
      alignItems: "center",
      justifyItems: "center",
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(143, 143, 143, 0.45)",
      zIndex: "9999",
    }}
  >
    <div style={{ alignSelf: "flex-end" }} className="nj-spinner" role="status">
      <span className="sr-only">Loading...</span>
    </div>
    <div style={{ alignSelf: "flex-start" }}>
      <div
        style={{
          fontSize: 18,
          color: "white",
          fontWeight: "bold",
          marginTop: 24,
        }}
      >
        {message}
      </div>
    </div>
  </div>
);

const Spinner = ({ isAuthenticated, isApiLoadingLocationData }) => {
  if (isAuthenticated && !isApiLoadingLocationData) return null;
  return (
    <div
      style={{
        display: "grid",
        alignItems: "center",
        justifyItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(143, 143, 143, 0.45)",
        zIndex: "9999",
      }}
    >
      <div
        style={{ alignSelf: "flex-end" }}
        className="nj-spinner"
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
      <div style={{ alignSelf: "flex-start" }}></div>
    </div>
  );
};

export default Spinner;
