import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Content, PageContainer, StyledTableRow } from "./Common";

const Page4 = ({ locations, locationsResults }) => (
  <PageContainer id="pdf-overview">
    <Typography variant="h3" style={{ color: "#40adf8" }}>
      2. Simulation overview and site coordinates
    </Typography>
    <Content>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>NAME</TableCell>
              <TableCell>COUNTRY</TableCell>
              <TableCell align="right">LONGITUDE</TableCell>
              <TableCell align="right">LATITUDE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locations.map((coordinate, i) => (
              <StyledTableRow key={`${coordinate.country}-${i}`}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>
                  {locations[i].name
                    ? locations[i].name
                    : `User choice ${i + 1}`}
                </TableCell>
                <TableCell>{locationsResults[i].country}</TableCell>
                <TableCell align="right">{coordinate.lng}°</TableCell>
                <TableCell align="right">{coordinate.lat}°</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Content>
  </PageContainer>
);

export default Page4;
