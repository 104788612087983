import styled from "styled-components";
import wisogisblue from "../../assets/wisogisblue.png";
import tractebelsolo from "../../assets/tractebelsolo.png";

import { withStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";

export const PageContainer = styled.div`
  background-color: white;
  width: 808px;
  padding: 36px;
`;

export const Content = styled.div`
  margin: 24px;
`;

export const BlueContainer = styled.div`
  background-color: #40adf8;
  color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

export const TractebelLogo = () => (
  <img
    src={tractebelsolo}
    alt="Tractebel"
    style={{ height: "fit-content", width: 200 }}
  />
);
export const WISOGISLogo = () => (
  <img
    src={wisogisblue}
    alt="WISOGIS"
    style={{ height: "fit-content", width: 200 }}
  />
);

const BothLogosStyling = styled.div`
  height: 128px;
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const BothLogos = () => (
  <BothLogosStyling>
    <TractebelLogo />
    <WISOGISLogo />
  </BothLogosStyling>
);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f4f4f4",
    },
  },
}))(TableRow);
