import React from "react";
import styled from "styled-components";
import JSZip from "jszip";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { saveAs } from "file-saver";
import { Storage } from "aws-amplify";
import { toggleModal, toggleMapControl } from "kepler.gl/actions";
import {
    CONTACT_US_EMAIL,
    PRIVACY_STATEMENT_LINK,
    TERMS_OF_USE, WEBSITE_URL,
} from "../../constants";
import History, {getLayerLabelFromId, layersIdToApiLayersKeys} from "./History";
import generateHeatmapPDF from "../../utils/generateResults";
import "./style.scss";
import {useAuth} from "react-oidc-context";
import {simulationApiClient} from "../../apiClient";
import {GetSimulationResultsCommand} from "@wisogis/simulation-sdk";
import Processors from "kepler.gl/processors";

const fetchLayer = async (fileKey, identityId) => {
  const res_file = await Storage.get(fileKey, {
    level: "protected",
    identityId,
    contentType: "application/json",
    download: true,
  });

  return await new Response(res_file.Body).json();
};

const exportLayers = async (s3fileId, identityId) => {
  var zip = new JSZip();

  const simulationResults = await simulationApiClient.send(new GetSimulationResultsCommand({
      uid: identityId,
      id: s3fileId,
  }))

  const LAYERS_ID_TO_LOAD_IF_POSSIBLE = [
      "heatmap", "power_lines", "urban", "airports", "protected_area", "complex_terrain", "forest", "water", "wetland"
  ]
  for (const layerId of LAYERS_ID_TO_LOAD_IF_POSSIBLE) {
      try {
          const apiLayerField = layersIdToApiLayersKeys(layerId)
          const layerMap = await (await fetch(simulationResults[apiLayerField])).json()
          zip.file(`${getLayerLabelFromId(layerId)}.json`, JSON.stringify(layerMap))
      } catch (e) {
          // we don't care of errors
          // This code is not smart at all as we just try to load all layers and skip the layers not asked by the user
          // landing here means that this layer does not exist for this simulation
          // Fixme: load only the computed layers. It could be possible to know them using the GetSimulationDetail command
      }
  }
  zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, `${s3fileId}.zip`);
  });
};

const Tab = styled.div`
  min-width: 120px;
  right: 46px;
  text-align: left;
  background-color: #f7f7f7;
  position: absolute;
  min-height: 31px;
  vertical-align: middle;
  display: flex;
  padding-left: 4px;
  align-items: center;
  margin-right: 2px;
`;

const Actions = ({
  s3fileId,
  formReducer,
  dispatch,
  setHistoryVisible,
  exportImage,
}) => (
  <>
    {s3fileId && (
      <>
        {exportImage?.imageDataUri?.length > 0 && (
          <Tab
            style={{
              top: -81,
              cursor: "pointer",
            }}
            onClick={() =>
              generateHeatmapPDF(
                formReducer.historyInputs || formReducer,
                () => dispatch(toggleMapControl("mapLegend")),
                exportImage
              )
            }
          >
            Heatmap PDF
          </Tab>
        )}

        <Tab
          style={{
            top: -49,
            cursor: "pointer",
          }}
          onClick={() => exportLayers(s3fileId, formReducer.user.email)}
        >
          Export layers
        </Tab>
      </>
    )}
    <Tab
      style={{
        top: -17,
        cursor: "pointer",
      }}
      onClick={() => {
        document.querySelector(
          "#kepler-gl__map > div.ReactModalPortal"
        ).style.display = "block";
        dispatch(toggleModal("addData"));
      }}
    >
      Import layers
    </Tab>

    <Tab
      style={{
        top: 15,
        cursor: "pointer",
      }}
      onClick={() => setHistoryVisible((oldValue) => !oldValue)}
    >
      Show History
    </Tab>
  </>
);
const Settings = () => {
    const auth = useAuth()

    return (
        <>
            <a href={`mailto:${CONTACT_US_EMAIL}`} target="_blank" rel="noreferrer">
                <Tab
                    style={{
                        top: -36,
                        color: "#212121",
                        cursor: "pointer",
                    }}
                >
                    Contact us
                </Tab>
            </a>
            <a href={PRIVACY_STATEMENT_LINK} target="_blank" rel="noreferrer">
                <Tab
                    style={{
                        top: -4,
                        color: "#212121",
                        cursor: "pointer",
                    }}
                >
                    Privacy statement
                </Tab>
            </a>
            <a href={TERMS_OF_USE} target="_blank" rel="noreferrer">
                <Tab
                    style={{
                        top: 28,
                        color: "#212121",
                        cursor: "pointer",
                    }}
                >
                    Terms of Use
                </Tab>
            </a>
            <Tab
                style={{
                    top: 60,
                    color: "#e62b87",
                    cursor: "pointer",
                }}
                onClick={async () => {
                    await auth.signoutRedirect({
                        post_logout_redirect_uri: WEBSITE_URL
                    })
                }}
            >
                Logout
            </Tab>
        </>
    )
};

const BottomRightMenu = ({ formReducer, geoJSONReducer, keplerGl }) => {
  const [isHistoryVisible, setHistoryVisible] = React.useState(false);
  const [isActionsVisible, setActionsVisible] = React.useState(false);
  const [isSettingsVisible, setSettingsVisible] = React.useState(false);

  const { s3fileId } = geoJSONReducer;
  const dispatch = useDispatch();

  const license_type = formReducer.user.license_type;
  const userId = formReducer.user.user_id;

  const exportImage = keplerGl?.map?.uiState?.exportImage;

  return (
    <>
      {isHistoryVisible && (
        <History
          dispatch={dispatch}
          identityId={formReducer.user.email}
          userId={userId}
          setHistoryVisible={setHistoryVisible}
        />
      )}
      <div className="topmenu-container">
        <div className="actions-menu">
          {isActionsVisible && (
            <div>
              <Actions
                s3fileId={s3fileId}
                formReducer={formReducer}
                dispatch={dispatch}
                setHistoryVisible={setHistoryVisible}
                exportImage={exportImage}
              />
            </div>
          )}
        </div>
        <div className="settings-menu">
          {isSettingsVisible && (
            <div>
              <Settings />
            </div>
          )}
        </div>
        {license_type !== "locations" && (
          <div
            onClick={() => {
              setSettingsVisible(false);
              setActionsVisible((oldValue) => !oldValue);
            }}
            style={{
              marginBottom: 9,
              background: "white",
              padding: 6,
              cursor: "pointer",
            }}
          >
            <svg
              fill="#6A7485"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m22.683 9.394-1.88-.239c-.155-.477-.346-.937-.569-1.374l1.161-1.495c.47-.605.415-1.459-.122-1.979l-1.575-1.575c-.525-.542-1.379-.596-1.985-.127l-1.493 1.161c-.437-.223-.897-.414-1.375-.569l-.239-1.877c-.09-.753-.729-1.32-1.486-1.32h-2.24c-.757 0-1.396.567-1.486 1.317l-.239 1.88c-.478.155-.938.345-1.375.569l-1.494-1.161c-.604-.469-1.458-.415-1.979.122l-1.575 1.574c-.542.526-.597 1.38-.127 1.986l1.161 1.494c-.224.437-.414.897-.569 1.374l-1.877.239c-.753.09-1.32.729-1.32 1.486v2.24c0 .757.567 1.396 1.317 1.486l1.88.239c.155.477.346.937.569 1.374l-1.161 1.495c-.47.605-.415 1.459.122 1.979l1.575 1.575c.526.541 1.379.595 1.985.126l1.494-1.161c.437.224.897.415 1.374.569l.239 1.876c.09.755.729 1.322 1.486 1.322h2.24c.757 0 1.396-.567 1.486-1.317l.239-1.88c.477-.155.937-.346 1.374-.569l1.495 1.161c.605.47 1.459.415 1.979-.122l1.575-1.575c.542-.526.597-1.379.127-1.985l-1.161-1.494c.224-.437.415-.897.569-1.374l1.876-.239c.753-.09 1.32-.729 1.32-1.486v-2.24c.001-.757-.566-1.396-1.316-1.486zm-10.683 7.606c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z" />
            </svg>
          </div>
        )}
        <div
          onClick={() => {
            setActionsVisible(false);
            setSettingsVisible((oldValue) => !oldValue);
          }}
          style={{ background: "white", padding: 6, cursor: "pointer" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#6A7485"
            width="22px"
            height="22px"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
          </svg>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    formReducer: state.formReducer,
    geoJSONReducer: state.geoJSONReducer,
    keplerGl: state.keplerGl,
  };
};

const dispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, dispatchToProps)(BottomRightMenu);
