import Typography from "@material-ui/core/Typography";
import { BothLogos, PageContainer, BlueContainer, Flex } from "./Common";
import TopLeftCorner from "../../assets/TopLeftCorner.png";
import BottomRightCorner from "../../assets/BottomRightCorner.png";

const Page1 = () => (
  <PageContainer id="pdf-front-cover">
    <BothLogos />
    <Flex>
      <BlueContainer>
        <img
          src={TopLeftCorner}
          style={{ width: 120, height: 120, margin: 24 }}
          alt="top-left-corner"
        />
        <div style={{ margin: 24 }}>
          <Typography variant="h2">Wisogis simulation report</Typography>
          <Typography variant="h4">Locations</Typography>
        </div>
        <img
          src={BottomRightCorner}
          style={{ alignSelf: "flex-end", width: 120, height: 120, margin: 24 }}
          alt="bottom-right-corner"
        />
      </BlueContainer>
    </Flex>
  </PageContainer>
);

export default Page1;
