import {AuthProvider, hasAuthParams, useAuth} from "react-oidc-context";
import {useEffect} from "react";
import {getUserLicense} from "./service/get_user_license";
import {connect} from "react-redux"
import {OIDC_CONFIG} from "./constants";

function Authentication (props) {
    const onSigninCallback = async (user) => {
        window.history.replaceState({}, document.title, window.location.pathname)
        if (user !== undefined) {
            await getUserLicense(props)
        }
    }

    const oidcConfig = {
        authority: OIDC_CONFIG.AUTHORITY,
        client_id: OIDC_CONFIG.CLIENT_ID,
        redirect_uri: OIDC_CONFIG.REDIRECT_URI,
        scope: "openid email offline_access",
        onSigninCallback: onSigninCallback
    }

    return (
        <AuthProvider {...oidcConfig}>
            <Auth>
                {props.children}
            </Auth>
        </AuthProvider>
    )
}
const mapStateToProps = (state) => state;
const dispatchToProps = (dispatch) => ({ dispatch });
export default connect(mapStateToProps, dispatchToProps)(Authentication);


function Auth ({children}) {

    const auth = useAuth()

    useEffect(() => {
        const isUserAuthenticated = hasAuthParams() || auth.isAuthenticated || auth.activeNavigator
        if (!(isUserAuthenticated || auth.isLoading)) {
            void auth.signinRedirect()
        }
    }, [
        auth.isAuthenticated,
        auth.activeNavigator,
        auth.isLoading,
        auth.signinRedirect
    ])

    useEffect(() => {
        auth.events.addAccessTokenExpiring(async () => {
        await auth.signinSilent()
        })
    }, [])

    return <> {auth.isAuthenticated && children} </>

}
