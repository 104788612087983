import Typography from "@material-ui/core/Typography";
import { Content, PageContainer } from "./Common";

const Page10 = () => (
  <PageContainer id="pdf-disclaimer">
    <Typography variant="h3" style={{ color: "#40adf8" }}>
      Disclaimer
    </Typography>
    <Content>
      <Typography variant="body1">
        Given the uncertainty of the data and calculations, Tractebel S.A. does
        not guarantee the accuracy of the estimates. Tractebel S.A. cannot be
        held liable for any direct, incidental, consequential, indirect or
        punitive damages resulting or alleged to result from the use of the
        report provided. More details:{" "}
        <a href="https://wisogis.tractebel.com/TermsandConditions">
          wisogis.tractebel.com/TermsandConditions
        </a>
      </Typography>
    </Content>
  </PageContainer>
);

export default Page10;
