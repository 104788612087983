// Generates the maps in S3
import { Storage } from "aws-amplify";

import { toggleMapControl } from "kepler.gl/actions";
import Processors from "kepler.gl/processors";
import { heatmapConfig } from "../map-configs";
import { addDataToMap, toggleModal } from "kepler.gl/actions";
import { generateBody } from "./index";

import {
  clearSnackbar,
  showSuccessSnackbar,
  showInfoSnackbar,
  showErrorSnackbar,
} from "../actions/snackbarActions";
import {simulationApiClient} from "../apiClient";
import {
  CreateSimulationCommand,
  GetSimulationCommand,
  GetSimulationResultsCommand,
  StartSimulationCommand
} from "@wisogis/simulation-sdk";
import {multipolygonToGeojson, polygonToGeojson} from "./coordsToGeojson";
import {getUserEmail} from "../userIdentity";

const HEATMAP = "heatmap";
const POWER_LINES = "power_lines";
const URBAN = "urban";
const AIRPORTS = "airports";
const PROTECTED_AREA = "protected_area";
const MILITARY = "military";
const HV_LINE_DISTANCE = "hv_line_distance";
const SUBSTATION_DISTANCE = "substation_distance";
const COMPLEX_TERRAIN = "complex_terrain";
const FOREST = "forest";
const WATER = "water";
const WETLAND = "wetland";
const FOREST_TOOBIG = "forest_toobig";
const URBAN_TOOBIG = "urban_toobig";
const ERROR = "error";

const getFile = async (fileName, id, new_files, identityId) => {
  if (
      new_files.find((cf) => cf.key.includes(`${fileName}.json`)) !== undefined
  ) {
    const res = await Storage.get(`${id}_${fileName}.json`, {
      level: "protected",
      identityId,
      contentType: "application/json",
      download: true,
    });
    return res;
  }
};

const pushZone = async (fileUrl, label, id, datasets) => {
  const jsonContent = await (await fetch(fileUrl)).json();
  const data = Processors.processGeojson(jsonContent);
  datasets.push({ info: { label, id }, data });

  return true;
};

const requestDataFromApi = async (props) => {
  props.dispatch(showInfoSnackbar("Fetching results"));
  props.dispatch({ type: "SET_API_LOADING_HEATMAP", payload: true });

  const userId = props.formReducer.user.user_id

  const bodyGenerated = generateBody(
      props,
      null,
      null
  );
  const zone = bodyGenerated.polygonType === "MultiPolygon"
  	? multipolygonToGeojson(bodyGenerated.polygon)
    : polygonToGeojson(bodyGenerated.polygon);

  try {
    const createSimulationResponse = await simulationApiClient.send(new CreateSimulationCommand({
      uid: userId,
      name: bodyGenerated.name,
      parameters: {
        optimization: bodyGenerated.opti.toUpperCase(),
        combined: bodyGenerated.combined_slider ?? (props.formReducer.renewableTechFormData.wind ? 1. : 0.),
        grid_connection_type: bodyGenerated.grid_connection_type.toUpperCase(),
        grid_cost_per_km: bodyGenerated.grid_cost_per_km,
        slope_cost_per_percent: bodyGenerated.slope_cost_per_percent,
        pv_tracking: bodyGenerated.pv_tracking ?? false,
        model_wt: bodyGenerated.model_WT,
        lcoe_params: bodyGenerated.LCOE_params,
        layer_params: {
          power_lines: 0,
          protected_areas: bodyGenerated.layers.protected_area,
          ...bodyGenerated.layers,
        },
        max_slope: bodyGenerated.layers.complex_terrain ?? 100
      },
      zone: zone,
    }))

    props.dispatch({ type: "SAVE_S3_FILE_ID", payload: createSimulationResponse.id }); //no s3 file anymore but kept in case it is required for the frontend
    await simulationApiClient.send(new StartSimulationCommand({
      uid: createSimulationResponse.uid,
      id: createSimulationResponse.id
    }))

    return { message: "call success", files_id: createSimulationResponse.id }
  } catch (err) {
    props.dispatch(clearSnackbar());
    props.dispatch(showErrorSnackbar(err.toString()));
  }
};

// S3 fetching layers by id and depending on type of layer requested in the Form in the sidepanel
const getData = async (id, props) => {

  let res_heatmap;
  let res_power_lines;
  let res_urban;
  let res_airports;
  let res_protected_area;
  let res_military;
  let res_hv_line_distance;
  let res_substation_distance;
  let res_complex_terrain;
  let res_forest;
  let res_water;
  let res_wetland;

  let res_forest_toobig;
  let res_urban_toobig;
  let res_error;

  let pushed_heatmap;
  let pushed_power_lines;
  let pushed_urban;
  let pushed_airports;
  let pushed_protected_area;
  let pushed_military;
  let pushed_hv_line_distance;
  let pushed_substation_distance;
  let pushed_complex_terrain;
  let pushed_forest;
  let pushed_water;
  let pushed_wetland;


  let getSimulationResponse
  do {
    await sleep(5000)
    getSimulationResponse = await simulationApiClient.send(new GetSimulationCommand({
      uid: getUserEmail(),
      id: id,
    }))
  } while (!["READY", "ERROR"].includes(getSimulationResponse?.state))

  const simulationResults = await simulationApiClient.send(new GetSimulationResultsCommand({
    uid: getUserEmail(),
    id: id,
  }))


  let datasets = [];
  const { exclusionAreasFormData } = props.formReducer;

  if (!res_heatmap)
    res_heatmap = simulationResults.lcoe_heatmap;
  if (!res_power_lines)
    res_power_lines = simulationResults.powerlines_layer;
  if (!res_urban) res_urban = simulationResults.urban_layer;
  if (!res_airports)
    res_airports = simulationResults.airports_layer;
  if (!res_protected_area)
    res_protected_area = simulationResults.protected_areas_layer;
  if (!res_military)
    res_military = null;
  if (!res_hv_line_distance)
    res_hv_line_distance = null;
  if (!res_substation_distance)
    res_substation_distance = null;
  if (!res_complex_terrain)
    res_complex_terrain = simulationResults.complex_heatmap;
  if (!res_forest)
    res_forest = simulationResults.forest_layer;
  if (!res_water) res_water = simulationResults.water_layer;
  if (!res_wetland)
    res_wetland = simulationResults.wetland_layer;
  if (!res_forest_toobig) {
    res_forest_toobig = false;  // ?
    if (res_forest_toobig)
      props.dispatch(
          showErrorSnackbar("Ignored Forest exclusion: zone is too big.")
      );
  }
  if (!res_urban_toobig) {
    res_urban_toobig = false;  // ?
    if (res_urban_toobig)
      props.dispatch(
          showErrorSnackbar("Ignored urban exclusion: zone is too big.")
      );
  }
  if (!res_error) res_error = getSimulationResponse?.state === "ERROR";

  if (res_error) {
    props.dispatch({ type: "FETCH_HEATMAP_ERROR" });
    props.dispatch(
        showErrorSnackbar("Error: one or multiple layers failed.")
    );
  } else if (res_heatmap) {

    await pushZone(res_heatmap, "Heatmap", HEATMAP, datasets);

    if (res_power_lines)
      await pushZone(res_power_lines, "Power lines", POWER_LINES, datasets);

    if (exclusionAreasFormData.is_urban) {
      await pushZone(res_urban, "Urban", URBAN, datasets);
    }
    if (exclusionAreasFormData.is_airports) {
      await pushZone(res_airports, "Airports", AIRPORTS, datasets);
    }
    if (exclusionAreasFormData.is_protected_area) {
      await pushZone(res_protected_area, "Protected area", PROTECTED_AREA, datasets);
    }
    if (exclusionAreasFormData.military && false) {  // ExclusionLayer not used
      await pushZone(res_military, "Military", MILITARY, datasets);
    }
    if (exclusionAreasFormData.water) {
      await pushZone(res_water, "Water", WATER, datasets);
    }
    if (exclusionAreasFormData.wetland) {
      await pushZone(res_wetland, "Wetland", WETLAND, datasets);
    }
    if (exclusionAreasFormData.is_forest) {
      await pushZone(res_forest, "Forest", FOREST, datasets);
    }
    if (exclusionAreasFormData.ui_show_substation_distance) {
      await pushZone(res_substation_distance, "Substation distance", SUBSTATION_DISTANCE, datasets);
    }
    if (exclusionAreasFormData.ui_show_hv_line_distance) {
      await pushZone(res_hv_line_distance, "HV line distance", HV_LINE_DISTANCE, datasets);
    }
    if (exclusionAreasFormData.ui_show_complex_terrain) {
      await pushZone(res_complex_terrain, "Complex terrain", COMPLEX_TERRAIN, datasets);
    }


    let heatmapIndex
    datasets.forEach((dataSet, index) => {
      if (dataSet.info && dataSet.info.id === "heatmap") {
        heatmapIndex = index
      }
    })
    datasets[heatmapIndex].data.fields.forEach((field) => {
      if (field.name === "lcoe") {
        field.name = "LCOE [$/MWh]"
      }
    })
    datasets[heatmapIndex].data.rows.forEach((row) => {
      if (row[0].properties["lcoe"]) {
        row[0].properties["LCOE [$/MWh]"] = row[0].properties["lcoe"].toFixed(4).slice(0, -1)
        delete row[0].properties["lcoe"]
      }
    })

    props.dispatch(
        addDataToMap({
          datasets,
          options: {
            readOnly: false,
            keepExistingConfig: false,
            centerMap: true,
            autoCreateLayers: true,
          },
          config: heatmapConfig,
        })
    );

    props.dispatch(showSuccessSnackbar("Loaded all layers successfully"));
    props.dispatch({ type: "REORDER_LAYERS" });
    props.dispatch(toggleMapControl("mapLegend"));
    props.dispatch(toggleModal("exportImage"));
    document.querySelector(
        "#kepler-gl__map > div.ReactModalPortal"
    ).style.display = "none";
  }
};

export const getMapsFromBackend = async (props) => {
  try {
    requestDataFromApi(props).then(async (res) => {
      if (res !== undefined && res.files_id !== undefined) {
        getData(res.files_id, props);
        props.dispatch({
          type: "SET_ZONE_OR_COUNTRY",
          payload: { country: false, zone: false },
        });
        if (
            props.keplerReducer.map.uiState.mapControls.mapDraw.active === true
        ) {
          props.dispatch({
            type: "@@kepler.gl/TOGGLE_MAP_CONTROL",
            payload: {
              panelId: "mapDraw",
              index: 0,
            },
          });
        }
      }
    });
  } catch (e) {
    props.dispatch(showErrorSnackbar("An error occurred"))
  }
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
