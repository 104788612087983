import React from "react";
import { toggleSidePanel } from "kepler.gl/actions";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import HelpText from "../HelpText";
import "./styles.scss";
import tractebelGrey from "../../assets/tractebel-grey.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(1),
    color: "#000000",
  },
  radio: {
    marginLeft: "20px",
  },
  radioLabel: {
    fontSize: "12px",
  },
}));

export const ExclusionAreasPanelComp = (props) => {
  const classes = useStyles();
  const {
    airports,
    complex_terrain,
    forest,
    protected_area,
    urban,
    water,
    wetland,
    ui_show_complex_terrain,
    is_forest,
    is_airports,
    is_urban,
    is_protected_area,
  } = props.formReducer.exclusionAreasFormData || {};

  const handleChangeRedux = (event) => {
    props.dispatch({
      type: "SET_FORM_EXCLUSION_AREAS",
      payload: { [event.target.name]: event.target.checked },
    });
  };

  const handleChangeTextInputRedux = (event) => {
    props.dispatch({
      type: "SET_FORM_EXCLUSION_AREAS",
      payload: { [event.target.id]: event.target.value },
    });
  };

  return (
    <>
      <div
        className="exclusion-panel"
        style={{ display: "flex", flexDirection: "column", paddingBottom: 0 }}
      >
        <div className={classes.root} style={{ flex: 1 }}>
          <FormControl
            component="fieldset"
            className={classes.formControl}
            style={{ width: "100%" }}
          >
            <FormGroup>
              <HelpText element="Permanent water">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={water}
                      onChange={handleChangeRedux}
                      name="water"
                      disableRipple
                      style={{ color: "#009de9" }}
                    />
                  }
                  label="Permanent water"
                />
              </HelpText>

              <HelpText element="Protected areas">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={is_protected_area}
                        onChange={handleChangeRedux}
                        name="is_protected_area"
                        disableRipple
                        style={{ color: "#009de9" }}
                      />
                    }
                    label="Protected areas"
                  />
                  {is_protected_area && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        value={protected_area}
                        id="protected_area"
                        helperText="m"
                        onChange={handleChangeTextInputRedux}
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          maxWidth: 60,
                          flex: 1,
                        }}
                      />
                    </div>
                  )}
                </div>
              </HelpText>

              <HelpText element="Urban or built up">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={is_urban}
                        onChange={handleChangeRedux}
                        name="is_urban"
                        disableRipple
                        style={{ color: "#009de9" }}
                      />
                    }
                    label="Urban or built up"
                  />
                  {is_urban && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        value={urban}
                        id="urban"
                        name="urban"
                        helperText="m"
                        onChange={handleChangeTextInputRedux}
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          maxWidth: 60,
                          flex: 1,
                        }}
                      />
                    </div>
                  )}
                </div>
              </HelpText>

              <HelpText element="Forest">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={is_forest}
                        onChange={handleChangeRedux}
                        name="is_forest"
                        disableRipple
                        style={{ color: "#009de9" }}
                      />
                    }
                    label="Forest"
                  />
                  {is_forest && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        value={forest}
                        id="forest"
                        name="forest"
                        helperText="m"
                        onChange={handleChangeTextInputRedux}
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          maxWidth: 60,
                          flex: 1,
                        }}
                      />
                    </div>
                  )}
                </div>
              </HelpText>

              <HelpText element="Maximum local slope">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ui_show_complex_terrain}
                        onChange={handleChangeRedux}
                        name="ui_show_complex_terrain"
                        disableRipple
                        style={{ color: "#009de9", flex: 2 }}
                      />
                    }
                    label="Maximum local slope"
                  />
                  {ui_show_complex_terrain && (
                    <TextField
                      value={complex_terrain}
                      id="complex_terrain"
                      helperText="%"
                      onChange={handleChangeTextInputRedux}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        maxWidth: 50,
                        flex: 1,
                      }}
                    />
                  )}
                </div>
              </HelpText>

              <HelpText element="Airports">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={is_airports}
                        onChange={handleChangeRedux}
                        name="is_airports"
                        disableRipple
                        style={{ color: "#009de9" }}
                      />
                    }
                    label="Airports"
                  />
                  {is_airports && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        value={airports}
                        id="airports"
                        name="airports"
                        helperText="m"
                        onChange={handleChangeTextInputRedux}
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          maxWidth: 60,
                          flex: 1,
                        }}
                      />
                    </div>
                  )}
                </div>
              </HelpText>

              <HelpText element="Herbaceous wetland">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wetland}
                      onChange={handleChangeRedux}
                      name="wetland"
                      disableRipple
                      style={{ color: "#009de9" }}
                    />
                  }
                  label="Herbaceous wetland"
                />
              </HelpText>
            </FormGroup>
          </FormControl>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            onClick={() => props.dispatch(toggleSidePanel("advanced-lcoe"))}
            className="previous-button"
          >
            Previous
          </div>
          <div
            onClick={() => props.dispatch(toggleSidePanel("summary"))}
            className="run-button"
          >
            Next
          </div>
        </div>
      </div>
      <div style={{ padding: "14px 30px", textAlign: "center" }}>
        <img src={tractebelGrey} style={{ maxWidth: "50%" }} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    formReducer: state.formReducer,
    uiStateReducer: state.uiStateReducer,
    appReducer: state.appReducer,
    keplerReducer: state.keplerGl,
  };
};
const dispatchToProps = (dispatch) => ({ dispatch });

export const ExclusionAreasPanel = connect(
  mapStateToProps,
  dispatchToProps
)(ExclusionAreasPanelComp);
