import React from "react";
import { connect } from "react-redux";
import { toggleSidePanel } from "kepler.gl/actions";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Slider } from "antd";
import HelpText from "../HelpText";
import "antd/dist/antd.css";
import tractebelGrey from "../../assets/tractebel-grey.jpg";

import "./styles.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: "1px",
    color: "#000000",
    width: "100%",
  },
  radio: {
    marginLeft: "20px",
  },
  radioLabel: {
    fontSize: "12px",
  },
  title: {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Lato",
  },
}));

export const RenewableSizingPanelComp = (props) => {
  const classes = useStyles();

  const handleChange = (event) => {
    props.dispatch({
      type: "SET_FORM_RENEWABLE_TECH",
      payload: { [event.target.name]: event.target.checked },
    });
  };

  const handleChangeRadioRedux = (event) => {
    props.dispatch({
      type: "SET_FORM_RENEWABLE_TECH",
      payload: { [event.target.name]: event.target.value==="true" },  // gets event.target.value as a string, even if we pass it a boolean
    });
  };

  const dispatchOnSlider = (combined_slider) => {
    props.dispatch({
      type: "SET_FORM_RENEWABLE_TECH",
      payload: { combined_slider: combined_slider / 100 },
    });
  };

  return (
    <div
      className="renewable-sizing-container"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div
        className="renewable-tech-panel"
        style={{ display: "flex", flexDirection: "column", flex: 1 }}
      >
        <div className={classes.root}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formReducer.renewableTechFormData.solar}
                    onChange={handleChange}
                    name="solar"
                    disableRipple
                    style={{ color: "#009de9" }}
                  />
                }
                label="Solar Energy"
              />
              <RadioGroup
                style={
                  props.formReducer.renewableTechFormData.solar === true
                    ? { display: "block" }
                    : { display: "none" }
                }
                aria-label="tilt"
                name="pv_tracking"
                value={
                  props.formReducer.renewableTechFormData.pv_tracking
                }
                onChange={handleChangeRadioRedux}
              >
                <HelpText element="Fixed tilt">
                  <FormControlLabel
                    className={classes.radioLabel}
                    value={false}
                    control={
                      <Radio
                        className={classes.radio}
                        style={{ color: "#009de9" }}
                      />
                    }
                    label="Fixed tilt"
                  />
                </HelpText>
                <HelpText element="Single axis tracking">
                  <FormControlLabel
                    className={classes.radioLabel}
                    value={true}
                    control={
                      <Radio
                        className={classes.radio}
                        style={{ color: "#009de9" }}
                      />
                    }
                    label="Single axis tracking"
                  />
                </HelpText>
              </RadioGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.formReducer.renewableTechFormData.wind}
                    onChange={handleChange}
                    name="wind"
                    disableRipple
                    style={{ color: "#009de9" }}
                  />
                }
                label="Wind Energy"
              />
            </FormGroup>
          </FormControl>
        </div>
        {props.formReducer.renewableTechFormData.solar === true &&
          props.formReducer.renewableTechFormData.wind === true && (
            <div className="renewable-sizing-panel">
              <HelpText element="Optimising renewable mix for">
                <div className={classes.title}>
                  Optimising renewable mix for:
                </div>
              </HelpText>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  <div
                    style={{ display: "block" }}
                    className="slider-container"
                  >
                    <Slider
                      disabled={
                        props.formReducer.renewableTechFormData.solar ===
                          true &&
                        props.formReducer.renewableTechFormData.wind === true
                          ? false
                          : true
                      }
                      onChange={dispatchOnSlider}
                      value={
                        parseFloat(
                          props.formReducer.renewableTechFormData
                            .combined_slider
                        ) * 100
                      }
                      min={0}
                      max={100}
                      tipFormatter={(slider_value) =>
                        slider_value === 50 ? (
                          <div>
                            The mix selected will be a trade-off between LCOE
                            and Stability, establish by linear regression.
                          </div>
                        ) : (
                          <div>
                            <div>LCOE: {slider_value || "0"}%</div>
                            <div>STABILITY: {100 - slider_value}%</div>
                          </div>
                        )
                      }
                      step={5}
                      style={{ color: "#009de9" }}
                    />
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <HelpText element="Stability">
                      <div
                        style={{
                          color: "#888",
                          fontWeight: "bold",
                          marginTop: -10,
                        }}
                      >
                        STABILITY
                      </div>
                    </HelpText>
                    <HelpText element="LCOE">
                      <div
                        style={{
                          color: "#888",
                          fontWeight: "bold",
                          marginTop: -10,
                        }}
                      >
                        LCOE
                      </div>
                    </HelpText>
                  </div>
                </FormGroup>
              </FormControl>
            </div>
          )}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          onClick={() => props.dispatch(toggleSidePanel("zone"))}
          className="previous-button"
        >
          Previous
        </div>
        <div
          onClick={() => props.dispatch(toggleSidePanel("advanced-lcoe"))}
          className="run-button"
        >
          Next
        </div>
      </div>
      <div style={{ padding: "14px 30px", textAlign: "center" }}>
        <img src={tractebelGrey} style={{ maxWidth: "50%" }} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    formReducer: state.formReducer,
    uiStateReducer: state.uiStateReducer,
    appReducer: state.appReducer,
    keplerReducer: state.keplerGl,
  };
};
const dispatchToProps = (dispatch) => ({ dispatch });

export const RenewableSizingPanel = connect(
  mapStateToProps,
  dispatchToProps
)(RenewableSizingPanelComp);
