import { SimulationServiceServiceException as __BaseException } from "./SimulationServiceServiceException";
export const ValidationExceptionFieldFilterSensitiveLog = (obj) => ({
    ...obj,
});
export class ValidationException extends __BaseException {
    constructor(opts) {
        super({
            name: "ValidationException",
            $fault: "client",
            ...opts
        });
        this.name = "ValidationException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ValidationException.prototype);
        this.fieldList = opts.fieldList;
    }
}
export class BadRequest extends __BaseException {
    constructor(opts) {
        super({
            name: "BadRequest",
            $fault: "client",
            ...opts
        });
        this.name = "BadRequest";
        this.$fault = "client";
        Object.setPrototypeOf(this, BadRequest.prototype);
    }
}
export const CoordFilterSensitiveLog = (obj) => ({
    ...obj,
});
export class InvalidCoordinates extends __BaseException {
    constructor(opts) {
        super({
            name: "InvalidCoordinates",
            $fault: "client",
            ...opts
        });
        this.name = "InvalidCoordinates";
        this.$fault = "client";
        Object.setPrototypeOf(this, InvalidCoordinates.prototype);
        this.coord = opts.coord;
    }
}
export class InvalidState extends __BaseException {
    constructor(opts) {
        super({
            name: "InvalidState",
            $fault: "client",
            ...opts
        });
        this.name = "InvalidState";
        this.$fault = "client";
        Object.setPrototypeOf(this, InvalidState.prototype);
        this.stateFound = opts.stateFound;
        this.stateExpected = opts.stateExpected;
    }
}
export class NoSuchResource extends __BaseException {
    constructor(opts) {
        super({
            name: "NoSuchResource",
            $fault: "client",
            ...opts
        });
        this.name = "NoSuchResource";
        this.$fault = "client";
        Object.setPrototypeOf(this, NoSuchResource.prototype);
        this.resourceType = opts.resourceType;
        this.resourceId = opts.resourceId;
    }
}
export var GridConnectionType;
(function (GridConnectionType) {
    GridConnectionType["HV_LINE"] = "HV_LINE";
    GridConnectionType["SUBSTATION"] = "SUBSTATION";
})(GridConnectionType || (GridConnectionType = {}));
export const LayerParametersFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const LcoeParametersFilterSensitiveLog = (obj) => ({
    ...obj,
});
export var ModelWt;
(function (ModelWt) {
    ModelWt["V126"] = "V126";
})(ModelWt || (ModelWt = {}));
export var Optimization;
(function (Optimization) {
    Optimization["COMBINED"] = "COMBINED";
    Optimization["LCOE"] = "LCOE";
    Optimization["SOLAR"] = "SOLAR";
    Optimization["STABILITY"] = "STABILITY";
    Optimization["WIND"] = "WIND";
})(Optimization || (Optimization = {}));
export const SimulationParametersFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const CreateSimulationInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const SimulationDateFilterSensitiveLog = (obj) => ({
    ...obj,
});
export var SimulationState;
(function (SimulationState) {
    SimulationState["COMPUTING"] = "COMPUTING";
    SimulationState["CREATED"] = "CREATED";
    SimulationState["ERROR"] = "ERROR";
    SimulationState["READY"] = "READY";
})(SimulationState || (SimulationState = {}));
export const CreateSimulationOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const DeleteSimulationInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const DeleteSimulationOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const GetLocationDetailsInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const GetLocationDetailsOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const GetLocationDetailsFromSimulationInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const GetLocationDetailsFromSimulationOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const GetSimulationInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const GetSimulationOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const GetSimulationResultsInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const GetSimulationResultsOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const ListSimulationsInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const SimulationItemFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const ListSimulationsOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const RenameSimulationInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const RenameSimulationOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const StartSimulationInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const StartSimulationOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
