export const API_KEY = "IzpxRauCop656A5i0OjyfaGM6H2RkZ5G4GyJplWy";
// export const BASE_URL =
//   "https://wh96518y8g.execute-api.eu-west-1.amazonaws.com/noprod";

// export const BASE_URL =
//   "https://bqn3wg5dy9.execute-api.eu-west-1.amazonaws.com/prod";

// export const BASE_URL =
//   "https://ojre63umni.execute-api.eu-west-1.amazonaws.com/prod";

export const BASE_URL = "https://api.wisogis.tractebel-engie.com";

export const SINGLE_AXIS = "single";
export const EMPTY_VALUE = "";
export const V126 = "V126";
export const SOLAR = "solar";
export const WIND = "wind";
export const SUBSTATION = "substation";

export { getMapsFromBackend } from "./heatmap";
export { getLocationDetails } from "./location_details";

export const generateBody = (
  props,
  uniqueS3FileID = null,
  coords = null,
  csv = null
) => {
  const {
    renewableTechFormData,
    advancedLCOEFormData,
    exclusionAreasFormData,
  } = props.formReducer;

  let opti;
  if (renewableTechFormData.solar && renewableTechFormData.wind) {
    opti = renewableTechFormData.opti;
  } else {
    opti = renewableTechFormData.solar
      ? SOLAR
      : renewableTechFormData.wind
      ? WIND
      : null;
  }

  const bodyGenerated = {
    name: props.formReducer.zoneSelectionData.name,
    opti: opti,
    grid_connection_type:
      advancedLCOEFormData.grid_connection_type || SUBSTATION,
    LCOE_params: {},
    model_WT: V126,
    layers: {},
  };

  // if HEATMAP call, uniqueS3FileID isn't null
  if (uniqueS3FileID) {
    bodyGenerated.heatmap_id = uniqueS3FileID;
    bodyGenerated.layers.power_lines = 0;
  }
  if (coords) {
    bodyGenerated.coords = coords;
  } else {
    bodyGenerated.polygon = props.appReducer.selectedPolygon || [
      [4.403999, 50.836752],
      [4.403999, 50.836752],
      [4.403118, 51.010261],
      [4.903087, 50.994434],
      [4.403999, 50.836752],
    ];
    bodyGenerated.polygonType = props.appReducer.selectedPolygonType
  }
  if (advancedLCOEFormData.wind_capex) {
    bodyGenerated.LCOE_params.wind_capex = parseFloat(
      advancedLCOEFormData.wind_capex
    );
  }
  if (advancedLCOEFormData.wind_opex) {
    bodyGenerated.LCOE_params.wind_opex = parseFloat(
      advancedLCOEFormData.wind_opex
    );
  }
  if (advancedLCOEFormData.solar_capex) {
    bodyGenerated.LCOE_params.solar_capex = parseFloat(
      advancedLCOEFormData.solar_capex
    );
  }
  if (advancedLCOEFormData.solar_opex) {
    bodyGenerated.LCOE_params.solar_opex = parseFloat(
      advancedLCOEFormData.solar_opex
    );
  }
  if (advancedLCOEFormData.lifetime) {
    bodyGenerated.LCOE_params.lifetime = parseFloat(
      advancedLCOEFormData.lifetime
    );
  }
  if (advancedLCOEFormData.wacc) {
    bodyGenerated.LCOE_params.wacc = parseFloat(advancedLCOEFormData.wacc);
  }
  if (renewableTechFormData.wind && renewableTechFormData.solar) {
    if (renewableTechFormData.combined_slider < 0.05) {
      bodyGenerated.opti = "stability";
    } else if (renewableTechFormData.combined_slider > 0.95) {
      bodyGenerated.opti = "lcoe";
    } else {
      bodyGenerated.opti = "combined";
      bodyGenerated.combined_slider =
        1 - renewableTechFormData.combined_slider || 0.5;
    }
  }
  if (renewableTechFormData.solar) {
    bodyGenerated.pv_tracking = renewableTechFormData.pv_tracking;
  }
  if (exclusionAreasFormData.is_urban) {
    bodyGenerated.layers.urban = parseFloat(exclusionAreasFormData.urban);
  }
  if (exclusionAreasFormData.is_protected_area) {
    bodyGenerated.layers.protected_area = parseFloat(
      exclusionAreasFormData.protected_area
    );
  }
  if (exclusionAreasFormData.is_forest) {
    bodyGenerated.layers.forest = parseFloat(exclusionAreasFormData.forest);
  }
  if (exclusionAreasFormData.is_airports) {
    bodyGenerated.layers.airports = parseFloat(exclusionAreasFormData.airports);
  }
  if (exclusionAreasFormData.power_lines) {
    bodyGenerated.layers.power_lines = 0;
  }
  if (
    exclusionAreasFormData.ui_show_hv_line_distance &&
    !isNaN(exclusionAreasFormData.hv_line_distance)
  ) {
    bodyGenerated.layers.hv_line_distance = parseFloat(
      exclusionAreasFormData.hv_line_distance
    ); // exclusion (in km)
  }
  if (
    exclusionAreasFormData.ui_show_substation_distance &&
    !isNaN(exclusionAreasFormData.substation_distance)
  ) {
    bodyGenerated.layers.substation_distance = parseFloat(
      exclusionAreasFormData.substation_distance
    ); // exclusion (in km)
  }
  if (
    exclusionAreasFormData.ui_show_complex_terrain &&
    !isNaN(exclusionAreasFormData.complex_terrain)
  ) {
    bodyGenerated.layers.complex_terrain = parseFloat(
      exclusionAreasFormData.complex_terrain
    ); // scale (in degree)
  }

  if (exclusionAreasFormData.military) {
    bodyGenerated.layers.military = exclusionAreasFormData.military;
  }

  if (exclusionAreasFormData.water) {
    bodyGenerated.layers.water = 0;
  }
  if (exclusionAreasFormData.wetland) {
    bodyGenerated.layers.wetland = 0;
  }

  if (!advancedLCOEFormData.grid_cost_per_km.checked) {
    bodyGenerated.grid_cost_per_km = 0;
  } else {
    if (
      advancedLCOEFormData.grid_cost_per_km.value &&
      !isNaN(advancedLCOEFormData.grid_cost_per_km.value)
    ) {
      const grid_cost_per_km_value = parseFloat(
        advancedLCOEFormData.grid_cost_per_km.value
      );
      if (grid_cost_per_km_value > 0)
        bodyGenerated.grid_cost_per_km = grid_cost_per_km_value;
    }
  }
  if (!advancedLCOEFormData.slope_cost_per_percent.checked) {
    bodyGenerated.slope_cost_per_percent = 0;
  } else if (advancedLCOEFormData.slope_cost_per_percent.value) {
    bodyGenerated.slope_cost_per_percent = parseFloat(
      advancedLCOEFormData.slope_cost_per_percent.value
    );
  }
  if (csv) {
    bodyGenerated.csv = true;
  }
  return bodyGenerated;
};
