import React from "react";
import { connect } from "react-redux";
import { layerConfigChange, toggleMapControl } from "kepler.gl/actions";
import StyleSelector from "./StyleSelector/StyleSelector";
import LayerSelector from "./LayerSelector/LayerSelector";
import MapLayersSelector from "./MapLayersSelector/MapLayersSelector";
import "./style.scss";

const toggleLayer = (layerData, dispatch, visibility) => {
  dispatch(layerConfigChange(layerData, { isVisible: !visibility }));
};

function CustomMapControl(props) {
  let layerForList = props.map?.visState?.layers || [];

  React.useEffect(() => {
    if (document.getElementsByClassName("show-legend").length)
      document
        .getElementsByClassName("show-legend")[0]
        .addEventListener("click", () => {
          const layerOptions = document.querySelector(".layer-options");
          const maplayerOptions = document.querySelector(".maplayer-options");
          const styleOptions = document.querySelector(".style-options");

          layerOptions.style.visibility = "hidden";
          maplayerOptions.style.visibility = "hidden";
          styleOptions.style.visibility = "hidden";
        });
  }, [document.getElementsByClassName("show-legend").length]);

  // React.useEffect(() => {
  //   // let i = 0;
  //   // for (const el of document.getElementsByClassName("legend--layer_name")) {
  //   //   const layer = layerForList.filter(
  //   //     (l) => el.innerText === l.config.label
  //   //   )[0];

  //   //   const eye = document.createElement("div");
  //   //   eye.style.backgroundColor = "red";
  //   //   eye.style.height = "16px";
  //   //   eye.style.width = "16px";
  //   //   eye.id = el.innerText;

  //   //   eye.onclick = () => {
  //       // if (layer.config.isVisible) {
  //       //   const elExist = document.getElementById(`empty-${el.innerText}`);
  //       //   console.log({ elExist });

  //       //   if (elExist) elExist.style.display = "block";
  //       //   else {
  //       //     const emptyLegend = document.createElement("div");
  //       //     emptyLegend.id = `empty-${el.innerText}`;
  //       //     emptyLegend.classList.add("legend--layer");
  //       //     emptyLegend.innerText = el.innerText;
  //       //     emptyLegend.append(eye);

  //       //     const legendWrapper = document.getElementsByClassName("map-legend");
  //       //     // legendWrapper[0].appendChild(emptyLegend);
  //       //     legendWrapper[0].insertBefore(
  //       //       emptyLegend,
  //       //       legendWrapper[0].nextSibling
  //       //     );
  //       //     console.log({ emptyLegend, legendWrapper });
  //       //   }
  //       // } else {
  //       //   const emptyLegend = document.getElementById(`empty-${el.innerText}`);
  //       //   console.log({ emptyLegend });
  //       //   emptyLegend.remove();
  //       // }
  //       toggleLayer(layer, props.dispatch, layer.config.isVisible);
  //     };
  //     if (!document.getElementById(el.innerText)) el.appendChild(eye);
  //   }
  //   i += 1;
  // });

  return (
    <div className="custom-map-control">
      <StyleSelector />
      <LayerSelector />
      <MapLayersSelector />
    </div>
  );
}

const dispatchToProps = (dispatch) => ({ dispatch });
const mapStateToProps = (state) => state.keplerGl;

export default connect(mapStateToProps, dispatchToProps)(CustomMapControl);
