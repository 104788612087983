import { useState } from "react";
import { connect } from "react-redux";
import { mapStyleChange, toggleMapControl } from "kepler.gl/actions";
import mapLight from "../../../assets/map-light.png";
import mapDark from "../../../assets/map-dark.png";
import mapSatellite from "../../../assets/map-satellite.png";
import ReactTooltip from "react-tooltip";
import "./style.scss";

function StyleSelector(props) {
  // State used to hide tooltip when submenu is open
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleStyleSelection = () => {
    const layerOptions = document.querySelector(".layer-options");
    const maplayerOptions = document.querySelector(".maplayer-options");
    const styleOptions = document.querySelector(".style-options");

    layerOptions.style.visibility = "hidden";
    maplayerOptions.style.visibility = "hidden";

    const legendOptions = document.querySelector(".close-map-control-item");
    if (legendOptions) props.dispatch(toggleMapControl("mapLegend"));

    if (styleOptions.style.visibility === "hidden") {
      styleOptions.style.visibility = "visible";
      setIsMenuVisible(true);
    } else {
      styleOptions.style.visibility = "hidden";
      setIsMenuVisible(false);
    }
  };

  return (
    <div
      data-tip
      data-for="style-selector-tip"
      className="custom-style-selector"
    >
      <div onClick={() => toggleStyleSelection()} className="svg-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="#6A7485"
          width="20px"
          height="20px"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM15 19l-6-2.11V5l6 2.11V19z" />
        </svg>
      </div>
      <ReactTooltip
        disable={isMenuVisible}
        id="style-selector-tip"
        type="info"
        effect="solid"
        className="custom-tooltip-class"
        arrowColor="#1869b5"
      >
        <span>Map styles</span>
      </ReactTooltip>
      <div style={{ visibility: "hidden" }} className="style-options">
        <div
          onClick={() => props.dispatch(mapStyleChange("light"))}
          className="style-option-wrapper"
        >
          <img src={mapLight} alt="Light"></img>
          <div>LIGHT</div>
        </div>
        <div
          onClick={() => props.dispatch(mapStyleChange("dark"))}
          className="style-option-wrapper"
        >
          <img src={mapDark} alt="Dark"></img>
          <div>DARK</div>
        </div>
        <div
          onClick={() => props.dispatch(mapStyleChange("satellite"))}
          className="style-option-wrapper"
        >
          <img src={mapSatellite} alt="Satellite"></img>
          <div>SATELLITE</div>
        </div>
      </div>
    </div>
  );
}

const dispatchToProps = (dispatch) => ({ dispatch });

export default connect(dispatchToProps)(StyleSelector);
