export const bordersConfig = {
  version: "v1",
  config: {
    visState: {
      filters: [],
      layers: [
        {
          id: "2fshagi",
          type: "geojson",
          config: {
            dataId: "countries",
            label: "Countries",
            color: [255, 254, 230],
            columns: {
              geojson: "_geojson",
            },
            isVisible: true,
            visConfig: {
              opacity: 0,
              strokeOpacity: 0.8,
              thickness: 0.5,
              strokeColor: [47, 197, 204],
              colorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              strokeColorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              radius: 1,
              sizeRange: [0, 1],
              radiusRange: [0, 5],
              heightRange: [0, 50],
              elevationScale: 5,
              stroked: true,
              filled: true,
              enable3d: false,
              wireframe: false,
            },
            hidden: false,
            textLabel: [
              {
                field: null,
                color: [255, 255, 255],
                size: 18,
                offset: [0, 0],
                anchor: "start",
                alignment: "center",
              },
            ],
          },
          visualChannels: {
            colorField: null,
            colorScale: "quantile",
            sizeField: null,
            sizeScale: "linear",
            strokeColorField: null,
            strokeColorScale: "quantile",
            heightField: null,
            heightScale: "linear",
            radiusField: null,
            radiusScale: "linear",
          },
        },
      ],
      interactionConfig: {
        tooltip: {
          fieldsToShow: {
            countries: [
              {
                name: "CNTRY_NAME",
                format: null,
              },
            ],
          },
          compareMode: false,
          compareType: "absolute",
          enabled: false,
        },
        brush: {
          size: 0.5,
          enabled: false,
        },
        geocoder: {
          enabled: false,
        },
        coordinate: {
          enabled: false,
        },
      },
      layerBlending: "normal",
      splitMaps: [],
      animationConfig: {
        currentTime: null,
        speed: 1,
      },
    },
    mapState: {
      bearing: 0,
      dragRotate: false,
      latitude: 50.88914502316644,
      longitude: 4.16190113171105,
      pitch: 0,
      zoom: 1.42,
      isSplit: false,
    },
    mapStyle: {
      styleType: "light",
      topLayerGroups: {},
      visibleLayerGroups: {
        label: true,
        road: true,
        border: true,
        building: false,
        water: true,
        land: true,
        "3d building": false,
      },
      threeDBuildingColor: [
        9.665468314072013, 17.18305478057247, 31.1442867897876,
      ],
      mapStyles: {},
    },
  },
};

/*
Military zones : violet (#944ba3)
Protected area: vert foncé (#007800)
Airports: gris (#b8b8b8)
Urban areas: lavande (#5A69A5)
Complex terrain: marron (#947b4b)
Forests: vert (#00ab00)
Permanent or seasonal water: bleu (#202080)
Herbaceous wetlands: bleu (#2F47AA)
Distance to a HV line: noir
Distance to a substation: noir
*/

const power_lines = { id: "power42", color: [255, 247, 230] };
const military = { id: "mili42e", color: [148, 75, 163] };
const substation_distance = { id: "subs42e", color: [60, 60, 60] };

const water = { id: "water42", color: [12, 32, 207] };
const protected_area = { id: "prote42", color: [0, 120, 0] };
const urban = { id: "resi42e", color: [90, 105, 165] };
const forest = { id: "fores42", color: [16, 156, 60] };
const complex_terrain = { id: "cmpl42e", color: [206, 157, 169] };
const airports = { id: "airp42e", color: [184, 184, 184] };
const wetland = { id: "wetla42", color: [32, 32, 128] };

const hv_line_distance = { id: "hvln42e", color: [60, 60, 60] };

export const heatmapConfig = {
  version: "v1",
  config: {
    visState: {
      filters: [],
      layers: [
        // power_lines
        {
          id: power_lines.id,
          type: "geojson",
          config: {
            dataId: "power_lines",
            label: "Power Lines",
            color: power_lines.color,
            columns: {
              geojson: "_geojson",
            },
            isVisible: true,
            visConfig: {
              opacity: 0.8,
              strokeOpacity: 0.8,
              thickness: 0.5,
              strokeColor: power_lines.color,
              colorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              strokeColorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              radius: 1,
              sizeRange: [0, 1],
              radiusRange: [0, 5],
              heightRange: [0, 50],
              elevationScale: 5,
              stroked: false,
              filled: true,
              enable3d: false,
              wireframe: false,
            },
            hidden: false,
            textLabel: [
              {
                field: null,
                color: [255, 255, 255],
                size: 18,
                offset: [0, 0],
                anchor: "start",
                alignment: "center",
              },
            ],
          },
          visualChannels: {
            colorField: null,
            colorScale: "quantile",
            sizeField: null,
            sizeScale: "linear",
            strokeColorField: null,
            strokeColorScale: "quantile",
            heightField: null,
            heightScale: "linear",
            radiusField: null,
            radiusScale: "linear",
          },
        },
        // Permanent Water (water)
        {
          id: water.id,
          type: "geojson",
          config: {
            dataId: "water",
            label: "Water",
            color: water.color,
            columns: {
              geojson: "_geojson",
            },
            isVisible: true,
            visConfig: {
              opacity: 0.8,
              strokeOpacity: 0.8,
              thickness: 0.5,
              strokeColor: water.color,
              colorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              strokeColorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              radius: 1,
              sizeRange: [0, 1],
              radiusRange: [0, 5],
              heightRange: [0, 50],
              elevationScale: 5,
              stroked: false,
              filled: true,
              enable3d: false,
              wireframe: false,
            },
            hidden: false,
            textLabel: [
              {
                field: null,
                color: [255, 255, 255],
                size: 18,
                offset: [0, 0],
                anchor: "start",
                alignment: "center",
              },
            ],
          },
          visualChannels: {
            colorField: null,
            colorScale: "quantile",
            sizeField: null,
            sizeScale: "linear",
            strokeColorField: null,
            strokeColorScale: "quantile",
            heightField: null,
            heightScale: "linear",
            radiusField: null,
            radiusScale: "linear",
          },
        },
        // Protected Areas (protected_area)
        {
          id: protected_area.id,
          type: "geojson",
          config: {
            dataId: "protected_area",
            label: "Protected area",
            color: protected_area.color,
            columns: {
              geojson: "_geojson",
            },
            isVisible: true,
            visConfig: {
              opacity: 0.8,
              strokeOpacity: 0.8,
              thickness: 0.5,
              strokeColor: protected_area.color,
              colorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              strokeColorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              radius: 1,
              sizeRange: [0, 1],
              radiusRange: [0, 5],
              heightRange: [0, 50],
              elevationScale: 5,
              stroked: false,
              filled: true,
              enable3d: false,
              wireframe: false,
            },
            hidden: false,
            textLabel: [
              {
                field: null,
                color: [255, 255, 255],
                size: 18,
                offset: [0, 0],
                anchor: "start",
                alignment: "center",
              },
            ],
          },
          visualChannels: {
            colorField: null,
            colorScale: "quantile",
            sizeField: null,
            sizeScale: "linear",
            strokeColorField: null,
            strokeColorScale: "quantile",
            heightField: null,
            heightScale: "linear",
            radiusField: null,
            radiusScale: "linear",
          },
        },
        // Urban or built up (urban)
        {
          id: urban.id,
          type: "geojson",
          config: {
            dataId: "urban",
            label: "Urban",
            color: urban.color,
            columns: {
              geojson: "_geojson",
            },
            isVisible: true,
            visConfig: {
              opacity: 0.8,
              strokeOpacity: 0.8,
              thickness: 0.5,
              strokeColor: urban.color,
              colorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              strokeColorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              radius: 1,
              sizeRange: [0, 1],
              radiusRange: [0, 5],
              heightRange: [0, 50],
              elevationScale: 5,
              stroked: false,
              filled: true,
              enable3d: false,
              wireframe: false,
            },
            hidden: false,
            textLabel: [
              {
                field: null,
                color: [255, 255, 255],
                size: 18,
                offset: [0, 0],
                anchor: "start",
                alignment: "center",
              },
            ],
          },
          visualChannels: {
            colorField: null,
            colorScale: "quantile",
            sizeField: null,
            sizeScale: "linear",
            strokeColorField: null,
            strokeColorScale: "quantile",
            heightField: null,
            heightScale: "linear",
            radiusField: null,
            radiusScale: "linear",
          },
        },
        // Forest (forest)
        {
          id: forest.id,
          type: "geojson",
          config: {
            dataId: "forest",
            label: "Forest",
            color: forest.color,
            columns: {
              geojson: "_geojson",
            },
            isVisible: true,
            visConfig: {
              opacity: 0.8,
              strokeOpacity: 0.8,
              thickness: 0.5,
              strokeColor: forest.color,
              colorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              strokeColorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              radius: 1,
              sizeRange: [0, 1],
              radiusRange: [0, 5],
              heightRange: [0, 50],
              elevationScale: 5,
              stroked: false,
              filled: true,
              enable3d: false,
              wireframe: false,
            },
            hidden: false,
            textLabel: [
              {
                field: null,
                color: [255, 255, 255],
                size: 18,
                offset: [0, 0],
                anchor: "start",
                alignment: "center",
              },
            ],
          },
          visualChannels: {
            colorField: null,
            colorScale: "quantile",
            sizeField: null,
            sizeScale: "linear",
            strokeColorField: null,
            strokeColorScale: "quantile",
            heightField: null,
            heightScale: "linear",
            radiusField: null,
            radiusScale: "linear",
          },
        },
        // Maximum local slope (complex_terrain)
        {
          id: complex_terrain.id,
          type: "geojson",
          config: {
            dataId: "complex_terrain",
            label: "Complex terrain",
            color: complex_terrain.color,
            columns: {
              geojson: "_geojson",
            },
            isVisible: true,
            visConfig: {
              opacity: 0.8,
              strokeOpacity: 0.8,
              thickness: 0.5,
              strokeColor: complex_terrain.color,
              colorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              strokeColorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              radius: 1,
              sizeRange: [0, 1],
              radiusRange: [0, 5],
              heightRange: [0, 50],
              elevationScale: 5,
              stroked: false,
              filled: true,
              enable3d: false,
              wireframe: false,
            },
            hidden: false,
            textLabel: [
              {
                field: null,
                color: [255, 255, 255],
                size: 18,
                offset: [0, 0],
                anchor: "start",
                alignment: "center",
              },
            ],
          },
          visualChannels: {
            colorField: null,
            colorScale: "quantile",
            sizeField: null,
            sizeScale: "linear",
            strokeColorField: null,
            strokeColorScale: "quantile",
            heightField: null,
            heightScale: "linear",
            radiusField: null,
            radiusScale: "linear",
          },
        },
        // Airports (airports)
        {
          id: airports.id,
          type: "geojson",
          config: {
            dataId: "airports",
            label: "Airports",
            color: airports.color,
            columns: {
              geojson: "_geojson",
            },
            isVisible: true,
            visConfig: {
              opacity: 0.8,
              strokeOpacity: 0.8,
              thickness: 0.5,
              strokeColor: airports.color,
              colorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              strokeColorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              radius: 1,
              sizeRange: [0, 1],
              radiusRange: [0, 5],
              heightRange: [0, 50],
              elevationScale: 5,
              stroked: false,
              filled: true,
              enable3d: false,
              wireframe: false,
            },
            hidden: false,
            textLabel: [
              {
                field: null,
                color: [255, 255, 255],
                size: 18,
                offset: [0, 0],
                anchor: "start",
                alignment: "center",
              },
            ],
          },
          visualChannels: {
            colorField: null,
            colorScale: "quantile",
            sizeField: null,
            sizeScale: "linear",
            strokeColorField: null,
            strokeColorScale: "quantile",
            heightField: null,
            heightScale: "linear",
            radiusField: null,
            radiusScale: "linear",
          },
        },
        // Herbaceous Wetland (wetland)
        {
          id: wetland.id,
          type: "geojson",
          config: {
            dataId: "wetland",
            label: "Herbaceous wetlands",
            color: wetland.color,
            columns: {
              geojson: "_geojson",
            },
            isVisible: true,
            visConfig: {
              opacity: 0.8,
              strokeOpacity: 0.8,
              thickness: 0.5,
              strokeColor: wetland.color,
              colorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              strokeColorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              radius: 1,
              sizeRange: [0, 1],
              radiusRange: [0, 5],
              heightRange: [0, 50],
              elevationScale: 5,
              stroked: false,
              filled: true,
              enable3d: false,
              wireframe: false,
            },
            hidden: false,
            textLabel: [
              {
                field: null,
                color: [255, 255, 255],
                size: 18,
                offset: [0, 0],
                anchor: "start",
                alignment: "center",
              },
            ],
          },
          visualChannels: {
            colorField: null,
            colorScale: "quantile",
            sizeField: null,
            sizeScale: "linear",
            strokeColorField: null,
            strokeColorScale: "quantile",
            heightField: null,
            heightScale: "linear",
            radiusField: null,
            radiusScale: "linear",
          },
        },
        // military
        {
          id: military.id,
          type: "geojson",
          config: {
            dataId: "military",
            label: "Military zones",
            color: military.color,
            columns: {
              geojson: "_geojson",
            },
            isVisible: true,
            visConfig: {
              opacity: 0.8,
              strokeOpacity: 0.8,
              thickness: 0.5,
              strokeColor: military.color,
              colorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              strokeColorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              radius: 1,
              sizeRange: [0, 1],
              radiusRange: [0, 5],
              heightRange: [0, 50],
              elevationScale: 5,
              stroked: false,
              filled: true,
              enable3d: false,
              wireframe: false,
            },
            hidden: false,
            textLabel: [
              {
                field: null,
                color: [255, 255, 255],
                size: 18,
                offset: [0, 0],
                anchor: "start",
                alignment: "center",
              },
            ],
          },
          visualChannels: {
            colorField: null,
            colorScale: "quantile",
            sizeField: null,
            sizeScale: "linear",
            strokeColorField: null,
            strokeColorScale: "quantile",
            heightField: null,
            heightScale: "linear",
            radiusField: null,
            radiusScale: "linear",
          },
        },
        // substation_distance
        {
          id: "subs56e",
          type: "geojson",
          config: {
            dataId: "substation_distance",
            label: "Distance to a substation",
            color: substation_distance.color,
            columns: {
              geojson: "_geojson",
            },
            isVisible: true,
            visConfig: {
              opacity: 0.8,
              strokeOpacity: 0.8,
              thickness: 0.5,
              strokeColor: substation_distance.color,
              colorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              strokeColorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              radius: 1,
              sizeRange: [0, 1],
              radiusRange: [0, 5],
              heightRange: [0, 50],
              elevationScale: 5,
              stroked: false,
              filled: true,
              enable3d: false,
              wireframe: false,
            },
            hidden: false,
            textLabel: [
              {
                field: null,
                color: [255, 255, 255],
                size: 18,
                offset: [0, 0],
                anchor: "start",
                alignment: "center",
              },
            ],
          },
          visualChannels: {
            colorField: null,
            colorScale: "quantile",
            sizeField: null,
            sizeScale: "linear",
            strokeColorField: null,
            strokeColorScale: "quantile",
            heightField: null,
            heightScale: "linear",
            radiusField: null,
            radiusScale: "linear",
          },
        },
        // hv_line_distance
        {
          id: hv_line_distance.id,
          type: "geojson",
          config: {
            dataId: "hv_line_distance",
            label: "Distance to a HV line",
            color: hv_line_distance.color,
            columns: {
              geojson: "_geojson",
            },
            isVisible: true,
            visConfig: {
              opacity: 0.8,
              strokeOpacity: 0.8,
              thickness: 0.5,
              strokeColor: hv_line_distance.color,
              colorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              strokeColorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              radius: 1,
              sizeRange: [0, 1],
              radiusRange: [0, 5],
              heightRange: [0, 50],
              elevationScale: 5,
              stroked: false,
              filled: true,
              enable3d: false,
              wireframe: false,
            },
            hidden: false,
            textLabel: [
              {
                field: null,
                color: [255, 255, 255],
                size: 18,
                offset: [0, 0],
                anchor: "start",
                alignment: "center",
              },
            ],
          },
          visualChannels: {
            colorField: null,
            colorScale: "quantile",
            sizeField: null,
            sizeScale: "linear",
            strokeColorField: null,
            strokeColorScale: "quantile",
            heightField: null,
            heightScale: "linear",
            radiusField: null,
            radiusScale: "linear",
          },
        },
        // heatmap
        {
          id: "lrep52",
          type: "geojson",
          config: {
            dataId: "heatmap",
            label: "Heatmap",
            color: [130, 154, 227],
            columns: {
              geojson: "_geojson",
            },
            isVisible: true,
            visConfig: {
              opacity: 0.8,
              strokeOpacity: 0.8,
              thickness: 0.5,
              strokeColor: [231, 159, 213],
              colorRange: {
                name: "Custom Palette",
                type: "custom",
                category: "Custom",
                colors: [
                  "#ee4d5a",
                  "#f66356",
                  "#f97b57",
                  "#f7945d",
                  "#f3ad6a",
                  "#efc47e",
                  "#ecda9a",
                ],
              },
              strokeColorRange: {
                name: "Global Warming",
                type: "sequential",
                category: "Uber",
                colors: [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300",
                ],
              },
              radius: 1,
              sizeRange: [0, 1],
              radiusRange: [0, 5],
              heightRange: [0, 50],
              elevationScale: 5,
              stroked: false,
              filled: true,
              enable3d: false,
              wireframe: false,
            },
            hidden: false,
            textLabel: [
              {
                field: null,
                color: [255, 255, 255],
                size: 18,
                offset: [0, 0],
                anchor: "start",
                alignment: "center",
              },
            ],
          },
          visualChannels: {
            colorField: {
              name: "LCOE [$/MWh]",
              type: "real",
            },
            colorScale: "quantile",
            sizeField: null,
            sizeScale: "linear",
            strokeColorField: null,
            strokeColorScale: "quantile",
            heightField: null,
            heightScale: "linear",
            radiusField: null,
            radiusScale: "linear",
          },
        },
      ],
      interactionConfig: {
        tooltip: {
          fieldsToShow: {
            power_lines: [],
            urban: [
              {
                name: "exclusion_ratio",
                format: null,
              },
            ],
            airports: [
              {
                name: "exclusion_ratio",
                format: null,
              },
            ],
            heatmap: [
              {
                name: "LCOE [$/MWh]",
                format: null,
              },
            ],
          },
          compareMode: false,
          compareType: "absolute",
          enabled: true,
        },
        brush: {
          size: 0.5,
          enabled: false,
        },
        geocoder: {
          enabled: false,
        },
        coordinate: {
          enabled: false,
        },
      },
      layerBlending: "normal",
      splitMaps: [],
      animationConfig: {
        currentTime: null,
        speed: 1,
      },
    },
    mapState: {
      bearing: 0,
      dragRotate: false,
      pitch: 0,
      isSplit: false,
    },
    mapStyle: {
      styleType: "light",
      topLayerGroups: {
        label: true,
      },
      visibleLayerGroups: {
        label: true,
        road: true,
        border: true,
        building: true,
        water: true,
        land: true,
        "3d building": false,
      },
      threeDBuildingColor: [
        9.665468314072013, 17.18305478057247, 31.1442867897876,
      ],
      mapStyles: {},
    },
  },
};
