import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { Icon } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { clearSnackbar } from "../actions/snackbarActions";

const MySnackbar = ({ message, open, duration = 10000, classes }) => {
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(clearSnackbar());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      ContentProps={{
        classes: {
          root: classes.root,
        },
      }}
      onClose={handleClose}
      aria-describedby="client-snackbar"
      message={
        <span
          id="client-snackbar"
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <Icon style={{ marginRight: 8, color: "white" }}>check_circle</Icon>
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <Icon style={{ color: "white" }}>close</Icon>
        </IconButton>,
      ]}
    />
  );
};

const styles = {
  root: {
    color: "white",
    width: 360,
    height: 64,
    fontWeight: "bold",
    fontSize: "1rem",
  },
};

const successStyles = {
  root: {
    ...styles.root,
    background: "#469a36",
  },
};

const SuccessSnackbar = ({ classes }) => {
  const { successSnackbarMessage, successSnackbarOpen } = useSelector(
    (state) => state.snackbarReducer
  );

  return (
    <MySnackbar
      message={successSnackbarMessage}
      open={successSnackbarOpen}
      classes={classes}
    />
  );
};

const infoStyles = {
  root: {
    ...styles.root,
    background: "#2375c0",
  },
};

const InfoSnackbar = ({ classes }) => {
  const { infoSnackbarMessage, infoSnackbarOpen } = useSelector(
    (state) => state.snackbarReducer
  );

  return (
    <MySnackbar
      message={infoSnackbarMessage}
      open={infoSnackbarOpen}
      classes={classes}
    />
  );
};

const errorStyles = {
  root: {
    ...styles.root,
    background: "#c03",
  },
};

const ErrorSnackbar = ({ classes }) => {
  const { errorSnackbarMessage, errorSnackbarOpen } = useSelector(
    (state) => state.snackbarReducer
  );

  return (
    <MySnackbar
      message={errorSnackbarMessage}
      open={errorSnackbarOpen}
      classes={classes}
    />
  );
};

const Success = withStyles(successStyles)(SuccessSnackbar);
const Info = withStyles(infoStyles)(InfoSnackbar);
const Error = withStyles(errorStyles)(ErrorSnackbar);

const SnackbarComponent = () => {
  const { successSnackbarOpen, infoSnackbarOpen, errorSnackbarOpen } =
    useSelector((state) => state.snackbarReducer);

  if (successSnackbarOpen) return <Success />;
  else if (infoSnackbarOpen) return <Info />;
  else if (errorSnackbarOpen) return <Error />;
  else return null;
};

export default SnackbarComponent;
