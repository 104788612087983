import {OIDC_CONFIG} from "./constants";
import jwtDecode from "jwt-decode";

export function getIdToken() {
    const tokens = window.sessionStorage.getItem(
        `oidc.user:${OIDC_CONFIG.AUTHORITY}:${OIDC_CONFIG.CLIENT_ID}`
    )
    if (tokens === null) {
        return undefined
    }
    return JSON.parse(tokens).id_token
}


export function getUserEmail() {
    const idToken = getIdToken()
    if (idToken === undefined) {
        return idToken
    }
    const decodedIdToken = jwtDecode(idToken)
    return decodedIdToken["email"]
}