import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable"; // https://www.npmjs.com/package/jspdf-autotable

import tractebelsolo from "../assets/tractebelsolo.png";
import wisogisblue from "../assets/wisogisblue.png";
import TopLeftCorner from "../assets/TopLeftCorner.png";
import BottomRightCorner from "../assets/BottomRightCorner.png";
import { TERMS_OF_USE } from "../constants";

import {
  getRenewableTechnologiesFormatted,
  getAdvancedLCOEFormatted,
  getExclusionAreasFormatted,
} from "./getSimulationParameters";

import latoRegular from "./Lato-Regular";
import latoBold from "./Lato-Bold";
import latoLight from "./Lato-Light";

const TITLE_SIZE = 28;
const SUBTITLE_SIZE = 24;
const BODY_SIZE = 16;
const LESSER_SIZE = 14;
const SMALL_SIZE = 12;
const SMALLER_SIZE = 10;

const BLUE_TEXT_COLOR = "#40adf8";
const WHITE_TEXT_COLOR = "#ffffff";
const RED_TEXT_COLOR = "#bf1b28";
const BLACK_TEXT_COLOR = "#000000";
const DARK_TEXT_COLOR = "#180D59";
const LIGHT_TEXT_COLOR = "#aaaaaa";

const date = new Date();
const FOOTER_CONTENT = `Wisogis simulation report - ${date.getDate()}-${
  date.getMonth() + 1
}-${date.getFullYear()}`;

const generatePDF = async (
  locations,
  locationsDetails,
  formReducer,
  exportImage,
  cb = () => {}
) => {
  const doc = new jsPDF();

  for (let i in locationsDetails) {
    locationsDetails[i].name = locations[i]
      ? locations[i].name
      : `User selection ${i + 1}`;
  }

  doc.addFileToVFS("Lato.ttf", latoRegular);
  doc.addFont("Lato.ttf", "Lato", "normal");

  doc.addFileToVFS("LatoBold.ttf", latoBold);
  doc.addFont("LatoBold.ttf", "Lato", "bold");

  doc.addFileToVFS("LatoLight.ttf", latoLight);
  doc.addFont("LatoLight.ttf", "Lato", "light");

  doc.setFont("Lato", "bold");

  // Page 1
  doc.addImage(tractebelsolo, "PNG", 15, 23, 71, 15);
  doc.addImage(wisogisblue, "PNG", 147, 23, 48, 16);

  doc.setFillColor(64, 173, 248);
  doc.rect(8, 46, 194, 187, "F");
  doc.addImage(TopLeftCorner, "PNG", 18, 56, 30, 30);
  doc.setTextColor(WHITE_TEXT_COLOR);
  doc.setFontSize(34);
  doc.text(18, 127, "Wisogis simulation report");
  doc.setFontSize(18);
  doc.text(18, 138, "Locations");
  doc.addImage(BottomRightCorner, "PNG", 162, 195, 30, 30);

  doc.setFontSize(16);
  doc.setTextColor(RED_TEXT_COLOR);
  doc.text(8, 248, "RESTRICTED");
  doc.setTextColor(BLUE_TEXT_COLOR);
  doc.text(
    8,
    258,
    `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
  );
  doc.setTextColor(BLACK_TEXT_COLOR);

  doc.setFont("Lato", "normal");
  doc.setFontSize(SMALL_SIZE);
  doc.text(8, 280, FOOTER_CONTENT);
  doc.text(195, 280, "1");

  // Page 2
  doc.addPage();
  doc.addImage(wisogisblue, "PNG", 8, 8, 48, 16);

  doc.setTextColor(BLUE_TEXT_COLOR);
  doc.setFontSize(TITLE_SIZE);
  doc.setFont("Lato", "light");

  doc.setFontSize(50);
  doc.text(16, 100, "Table of contents");

  doc.setTextColor(BLACK_TEXT_COLOR);
  doc.setFontSize(BODY_SIZE);
  doc.text(20, 116, "1. Introduction");
  doc.text(180, 116, "2");
  doc.text(20, 122, "2. Simulation overview and site coordinates");
  doc.text(180, 122, "3");
  doc.text(20, 128, "3. Simulation results");
  doc.text(180, 128, "5");
  doc.setFontSize(LESSER_SIZE);
  doc.text(20, 134, "    3.a. Results overview");
  doc.text(180, 134, "5");

  const { wind_capacity_factor, solar_capacity_factor } = locationsDetails[0];
  let i = 0;
  while (i < locations.length) {
    doc.text(
      20,
      140 + 6 * i,
      `    3.${String.fromCharCode(98 + i)}. Detailed report ${
        locations[i].name ? locations[i].name : "site n°" + (i + 1)
      }`
    );
    doc.text(
      180,
      140 + 6 * i,
      `${6 + i * (wind_capacity_factor && solar_capacity_factor ? 2 : 1)}`
    );
    i += 1;
  }

  doc.setFontSize(BODY_SIZE);

  doc.setFont("Lato", "normal");
  doc.setFontSize(SMALL_SIZE);
  doc.text(8, 280, FOOTER_CONTENT);
  doc.text(195, 280, "2");

  // Page 3
  doc.addPage();
  doc.addImage(wisogisblue, "PNG", 8, 8, 48, 16);

  doc.setTextColor(BLUE_TEXT_COLOR);
  doc.setFontSize(TITLE_SIZE);
  doc.setFont("Lato", "light");

  doc.text(16, 48, "1. Introduction");

  doc.setFont("Lato", "normal");
  doc.setTextColor(BLACK_TEXT_COLOR);
  doc.setFontSize(SMALL_SIZE);
  doc.text(
    20,
    60,
    "This report presents all the results of the simulations performed with the Wisogis"
  );
  doc.text(
    20,
    66,
    "tool, developed by the company Tractebel. The tool's renewable energy"
  );
  doc.text(
    20,
    72,
    "production models are based on high resolution, state of the art climate models"
  );
  doc.text(
    20,
    78,
    "combined with operational data from renewable assets around the world. In"
  );
  doc.text(
    20,
    84,
    "addition, Tractebel's extensive expertise in site selection and financial"
  );
  doc.text(
    20,
    90,
    "optimization of renewable projects has enabled the tool to develop an accurate"
  );
  doc.text(
    20,
    96,
    "and up to date financial model. This approach provides reliable preliminary results"
  );
  doc.text(
    20,
    102,
    "to secure project decision making, helping you to choose the most suitable site."
  );

  doc.text(20, 110, "More information on the methodology available:");

  doc.setTextColor(BLUE_TEXT_COLOR);

  doc.textWithLink("wisogis.tractebel.com/documentation", 20, 116, {
    url: "https://wisogis.tractebel.com/documentation",
  });

  doc.setTextColor(BLACK_TEXT_COLOR);

  doc.setFontSize(18);
  doc.setFont("Lato", "bold");
  doc.text(20, 160, "Abbreviations");
  doc.setFontSize(SMALL_SIZE);

  doc.setFont("Lato", "normal");

  const abbreviationsData = [
    {
      abb: "GHI",
      fullname: "Global Horrizontal Irradiance",
      explanation:
        "Average annual, monthly or daily sum of global horizontal irradiation in kWh/m",
    },
    {
      abb: "LCOE",
      fullname: "Levelized Cost of Electricity",
      explanation:
        "Full price of electricity over the lifetime of the renewable plant in $/kWh",
    },

    {
      abb: "CAPEX",
      fullname: "Capital Expenditure",
      explanation:
        "Initial investment required to build the generating asset in $/kW",
    },

    {
      abb: "OPEX",
      fullname: "Operationnal Expenditure",
      explanation:
        "Operationnal & Maintenance costs during the lifetime of the generating asset in $/kW/yr",
    },

    {
      abb: "WACC",
      fullname: "Weighted Average Cost of Capital",
      explanation:
        "Discount rate used to perform a discounted cash flow analysis of the energy project.",
    },
  ];

  var abbreviationsColumns = [
    { title: "ABB.", dataKey: "abb" },
    { title: "FULL NAME", dataKey: "fullname" },
    { title: "EXPLANATION", dataKey: "explanation" },
  ];

  doc.autoTable(abbreviationsColumns, abbreviationsData, {
    tableWidth: 140,
    styles: {
      fillColor: [255, 255, 255],
      lineColor: 240,
      textColor: [42, 42, 42],
      lineWidth: 0,
      minCellHeight: "15",
      fontSize: 12,
      startY: 55,
    },
    headStyles: {
      fontStyle: "normal",
    },
    columnStyles: {
      abb: { cellWidth: 25 },
      fullname: { cellWidth: 45 },
      explanation: { cellWidth: 100 },
    },
    margin: { top: 170 },
  });

  doc.text(8, 280, FOOTER_CONTENT);
  doc.text(195, 280, "3");

  // Page 4
  doc.addPage();
  doc.addImage(wisogisblue, "PNG", 8, 8, 48, 16);

  doc.setTextColor(BLUE_TEXT_COLOR);
  doc.setFontSize(TITLE_SIZE);
  doc.setFont("Lato", "light");
  doc.text(16, 48, "2. Simulation overview and sites");
  doc.text(16, 58, "coordinates");

  const { imageDataUri } = exportImage;
  doc.addImage(
    imageDataUri,
    "JPEG",
    14,
    66,
    doc.internal.pageSize.getWidth() - 28,
    doc.internal.pageSize.getWidth() / 3
  );

  doc.setTextColor(BLACK_TEXT_COLOR);
  doc.setFontSize(SMALL_SIZE);

  const simulationData = locations.map((l, i) => ({
    ID: `${i + 1}`,
    NAME: locations[i].name ? locations[i].name : `User choice ${i + 1}`,
    COUNTRY: locationsDetails[i].country,
    LONGITUDE: `${l.lng}°`,
    LATITUDE: `${l.lat}°`,
  }));

  var simulationColumns = [
    { title: "ID", dataKey: "ID" },
    { title: "NAME", dataKey: "NAME" },
    { title: "COUNTRY", dataKey: "COUNTRY" },
    { title: "LONGITUDE", dataKey: "LONGITUDE" },
    { title: "LATITUDE", dataKey: "LATITUDE" },
  ];

  doc.autoTable(simulationColumns, simulationData, {
    styles: {
      fillColor: [255, 255, 255],
      lineColor: 240,
      textColor: [42, 42, 42],
      lineWidth: 0,
      fontSize: 12,
      minCellHeight: "15",
    },
    headStyles: {
      fontStyle: "normal",
    },
    columnStyles: {
      ID: {},
      NAME: {},
      COUNTRY: {},
      LONGITUDE: {},
      LATITUDE: {},
    },
    margin: { top: 140 },
    addPageContent: function (data) {
      doc.text("", 40, 30);
    },
  });

  doc.text(8, 280, FOOTER_CONTENT);
  doc.text(195, 280, "4");

  // Page 9
  doc.addPage();
  doc.addImage(wisogisblue, "PNG", 8, 8, 48, 16);

  doc.setTextColor(LIGHT_TEXT_COLOR);
  doc.setFontSize(SUBTITLE_SIZE);
  doc.setFont("Lato", "light");
  doc.text(16, 42, "Simulation parameters summary");

  doc.setFont("Lato", "normal");
  doc.setTextColor(BLACK_TEXT_COLOR);
  doc.setFontSize(SMALL_SIZE);

  const {
    renewableTechFormData,
    advancedLCOEFormData,
    exclusionAreasFormData,
  } = formReducer;

  const renewableTechnParams = getRenewableTechnologiesFormatted(
    renewableTechFormData
  );
  const advancedLCOEParams = getAdvancedLCOEFormatted(advancedLCOEFormData);
  const exclusionAreasParams = getExclusionAreasFormatted(
    exclusionAreasFormData
  );

  let parametersColumns = [
    { title: "", dataKey: "name" },
    { title: "", dataKey: "selection", halign: "right" },
  ];

  doc.setTextColor(BLACK_TEXT_COLOR);
  doc.setFontSize(BODY_SIZE);
  doc.text(16, 60, "Renewable technologies");

  doc.autoTable(parametersColumns, renewableTechnParams, {
    pageBreak: "avoid",
    rowPageBreak: "avoid",
    horizontalPageBreak: false,
    tableWidth: 50,
    startY: 65,
    styles: {
      overflow: "hidden",
      theme: "plain",
      fillColor: [255, 255, 255],
      lineColor: 240,
      textColor: [42, 42, 42],
      lineWidth: 0,
      fontSize: 12,
    },
    headStyles: {
      fontStyle: "normal",
    },
    columnStyles: {
      name: { fillColor: false, cellWidth: 80 },
      selection: { fillColor: false, halign: "right", cellWidth: 80 },
    },
  });

  if (advancedLCOEParams.length) {
    doc.setTextColor(BLACK_TEXT_COLOR);
    doc.setFontSize(BODY_SIZE);
    doc.text(16, 105, "Advanced LCOE");

    doc.autoTable(parametersColumns, advancedLCOEParams, {
      pageBreak: "avoid",
      rowPageBreak: "avoid",
      horizontalPageBreak: false,
      tableWidth: 50,
      startY: 110,
      headStyles: {
        fontStyle: "normal",
      },
      styles: {
        overflow: "hidden",
        theme: "plain",
        fillColor: [255, 255, 255],
        lineColor: 240,
        textColor: [42, 42, 42],
        lineWidth: 0,
        fontSize: 12,
      },
      columnStyles: {
        name: { fillColor: false, cellWidth: 60 },
        selection: { fillColor: false, halign: "right", cellWidth: 100 },
      },
    });
  }

  if (exclusionAreasParams.length) {
    doc.setTextColor(BLACK_TEXT_COLOR);
    doc.setFontSize(BODY_SIZE);
    doc.text(16, 195, "Exclusion Areas");

    doc.autoTable(parametersColumns, exclusionAreasParams, {
      pageBreak: "avoid",
      rowPageBreak: "avoid",
      horizontalPageBreak: false,
      tableWidth: 50,
      startY: 200,
      headStyles: {
        fontStyle: "normal",
      },
      styles: {
        overflow: "hidden",
        theme: "plain",
        fillColor: [255, 255, 255],
        lineColor: 240,
        textColor: [42, 42, 42],
        lineWidth: 0,
        fontSize: 12,
      },
      columnStyles: {
        name: { fillColor: false, cellWidth: 80 },
        selection: { fillColor: false, halign: "right", cellWidth: 80 },
      },
    });
  }

  doc.setFontSize(SMALL_SIZE);
  doc.text(8, 280, FOOTER_CONTENT);

  doc.text(195, 280, "5");

  // Page 5
  doc.addPage();
  doc.addImage(wisogisblue, "PNG", 8, 8, 48, 16);

  doc.setTextColor(BLUE_TEXT_COLOR);
  doc.setFontSize(TITLE_SIZE);
  doc.setFont("Lato", "light");

  doc.text(16, 48, "3. Simulation results");

  doc.setTextColor(LIGHT_TEXT_COLOR);
  doc.setFontSize(SUBTITLE_SIZE);

  doc.text(24, 60, "3.a. Results overview");

  doc.setTextColor(BLACK_TEXT_COLOR);
  doc.setFontSize(SMALL_SIZE);
  doc.setFont("Lato", "normal");

  const resultsData = locationsDetails
    .map((lr, i) => ({
      id: i + 1,
      name: lr.name,
      LCOE: lr.LCOE,
    }))
    .sort((a, b) => a.LCOE - b.LCOE)
    .map((lr, i) => ({
      id: `${lr.id}`,
      ranking: `${i + 1}`,
      name: lr.name,
      LCOE: `${lr.LCOE.toFixed(2)}$/MWh`,
    }));

  let resultsColumns = [
    { title: "RANKING", dataKey: "ranking" },
    { title: "ID", dataKey: "id" },
    { title: "NAME", dataKey: "name" },
    { title: "LCOE", dataKey: "LCOE" },
  ];

  doc.autoTable(resultsColumns, resultsData, {
    styles: {
      fillColor: [255, 255, 255],
      lineColor: 240,
      textColor: [42, 42, 42],
      lineWidth: 0,
      minCellHeight: "15",
      fontSize: 12,
    },
    headStyles: {
      fontStyle: "normal",
    },
    columnStyles: {},
    margin: { top: 80 },
    addPageContent: function (data) {
      doc.text("", 40, 30);
    },
  });

  doc.setFontSize(SMALL_SIZE);
  doc.text(8, 280, FOOTER_CONTENT);
  doc.text(195, 280, "6");

  // Page 6

  for (let j = 0; j < locations.length; ++j) {
    const solar_monthly_graph = document.getElementById(
      `solar-capacity-factor-${j}`
    );
    let solar_monthly_canvas, solar_monthly_image;
    if (solar_monthly_graph) {
      solar_monthly_canvas = await html2canvas(solar_monthly_graph);
      solar_monthly_image = solar_monthly_canvas.toDataURL("image/png");
    }
    const solar_hourly_graph = document.getElementById(
      `solar-hourly-capacity-factor-${j}`
    );
    let solar_hourly_canvas, solar_hourly_image;
    if (solar_hourly_graph) {
      solar_hourly_canvas = await html2canvas(solar_hourly_graph);
      solar_hourly_image = solar_hourly_canvas.toDataURL("image/png");
    }

    const wind_monthly_graph = document.getElementById(
      `wind-capacity-factor-${j}`
    );
    let wind_monthly_canvas, wind_monthly_image;
    if (wind_monthly_graph) {
      wind_monthly_canvas = await html2canvas(wind_monthly_graph);
      wind_monthly_image = wind_monthly_canvas.toDataURL("image/png");
    }

    const wind_hourly_graph = document.getElementById(
      `wind-hourly-capacity-factor-${j}`
    );
    let wind_hourly_canvas, wind_hourly_image;
    if (wind_hourly_graph) {
      wind_hourly_canvas = await html2canvas(wind_hourly_graph);
      wind_hourly_image = wind_hourly_canvas.toDataURL("image/png");
    }

    const hybrid_monthly_graph = document.getElementById(
      `hybrid-capacity-factor-${j}`
    );
    let hybrid_monthly_canvas, hybrid_monthly_image;
    if (hybrid_monthly_graph) {
      hybrid_monthly_canvas = await html2canvas(hybrid_monthly_graph);
      hybrid_monthly_image = hybrid_monthly_canvas.toDataURL("image/png");
    }

    const hybrid_hourly_graph = document.getElementById(
      `hybrid-hourly-capacity-factor-${j}`
    );
    let hybrid_hourly_canvas, hybrid_hourly_image;
    if (hybrid_hourly_graph) {
      hybrid_hourly_canvas = await html2canvas(hybrid_hourly_graph);
      hybrid_hourly_image = hybrid_hourly_canvas.toDataURL("image/png");
    }

    doc.addPage();
    doc.addImage(wisogisblue, "PNG", 8, 8, 48, 16);

    doc.setTextColor(LIGHT_TEXT_COLOR);
    doc.setFontSize(SUBTITLE_SIZE);
    doc.setFont("Lato", "light");

    doc.text(
      24,
      48,
      `3.${String.fromCharCode(98 + j)}. Detailed report ${
        locations[j].name ? locations[j].name : "site n°" + (j + 1)
      }`
    );

    doc.setTextColor(BLACK_TEXT_COLOR);
    doc.setFont("Lato", "normal");
    const {
      LCOE,
      average_capacity_factor,
      country,
      electricity_ranking,
      electricity_tarif,
      ghi,
      grid_connection_time,
      grid_distance,
      layers,
      mix,
      slope,
      solar_capacity_factor,
      solar_capex,
      solar_monthly_capacity_factor,
      solar_opex,
      wind_capacity_factor,
      wind_capex,
      wind_monthly_capacity_factor,
      wind_opex,
      wind_speed,
    } = locationsDetails[j];

    doc.setFontSize(BODY_SIZE);
    doc.text(20, 60, "Site description");
    doc.setFontSize(SMALL_SIZE);
    doc.text(
      20,
      66,
      locations[j].name ? locations[j].name : `Site ID: ${j + 1}`
    );
    doc.text(20, 72, `Longitude: ${locations[j].lng}`);
    doc.text(20, 78, `Latitude: ${locations[j].lat}`);
    doc.text(20, 84, `Country: ${country}`);

    doc.setFontSize(BODY_SIZE);
    doc.text(20, 92, "Energy yield assessment");
    doc.setFontSize(SMALL_SIZE);

    if (wind_monthly_capacity_factor) {
      doc.setFontSize(BODY_SIZE);
      doc.setFont("Lato", "light");
      doc.setTextColor(LIGHT_TEXT_COLOR);
      doc.text(20, 100, "Wind energy");
      doc.setFont("Lato", "normal");
      doc.setFontSize(SMALL_SIZE);
      doc.setTextColor(BLACK_TEXT_COLOR);

      doc.text(
        20,
        108,
        `Average wind capacity factor: ${(wind_capacity_factor * 100).toFixed(
          2
        )}%`
      );
      doc.text(
        20,
        114,
        `Average wind speed at 100m: ${wind_speed.toFixed(2)}m/s`
      );
      if (wind_monthly_image)
        doc.addImage(wind_monthly_image, "JPEG", 20, 122, 80, 60);
      if (wind_hourly_image)
        doc.addImage(wind_hourly_image, "JPEG", 110, 122, 80, 60);
    }

    if (solar_monthly_capacity_factor) {
      doc.setFontSize(BODY_SIZE);
      doc.setFont("Lato", "light");
      doc.setTextColor(LIGHT_TEXT_COLOR);
      doc.text(20, wind_monthly_capacity_factor ? 190 : 100, "Solar energy");
      doc.setFont("Lato", "normal");
      doc.setFontSize(SMALL_SIZE);
      doc.setTextColor(BLACK_TEXT_COLOR);

      doc.text(
        20,
        wind_monthly_capacity_factor ? 198 : 108,
        `Average solar capacity factor: ${(solar_capacity_factor * 100).toFixed(
          2
        )}%`
      );
      doc.text(
        20,
        wind_monthly_capacity_factor ? 204 : 114,
        `Average GHI: ${ghi.toFixed(2)}W/m²`
      );
      if (solar_monthly_image)
        doc.addImage(
          solar_monthly_image,
          "JPEG",
          20,
          wind_monthly_capacity_factor ? 210 : 122,
          80,
          60
        );
      if (solar_hourly_image)
        doc.addImage(
          solar_hourly_image,
          "JPEG",
          110,
          wind_monthly_capacity_factor ? 210 : 122,
          80,
          60
        );
    }
    doc.setFontSize(SMALL_SIZE);
    doc.text(8, 280, FOOTER_CONTENT);
    doc.text(195, 280, `${7 + 2 * j}`);

    // Page 7
    if (wind_monthly_capacity_factor && solar_monthly_capacity_factor) {
      doc.addPage();
      doc.addImage(wisogisblue, "PNG", 8, 8, 48, 16);
      doc.setFontSize(BODY_SIZE);
      doc.setFont("Lato", "light");
      doc.setTextColor(LIGHT_TEXT_COLOR);
      doc.text(20, 36, "Optimized renewable mix");
      doc.setFont("Lato", "normal");
      doc.setFontSize(SMALL_SIZE);
      doc.setTextColor(BLACK_TEXT_COLOR);
      doc.text(
        20,
        44,
        `Mix: ${(mix * 100).toFixed(2)}% Wind and ${(100 - mix * 100).toFixed(
          2
        )}% Solar`
      );
      doc.text(
        20,
        50,
        `Average mix capacity factor: ${(average_capacity_factor * 100).toFixed(
          2
        )}%`
      );

      if (hybrid_monthly_image)
        doc.addImage(hybrid_monthly_image, "JPEG", 20, 58, 80, 60);
      if (hybrid_hourly_image)
        doc.addImage(hybrid_hourly_image, "JPEG", 110, 58, 80, 60);

      doc.text(8, 280, FOOTER_CONTENT);
      doc.text(195, 280, `${7 + 2 * j + 1}`);
    }

    doc.setFontSize(BODY_SIZE);
    doc.text(16, 202, "Levelized cost of electricity (LCOE) analysis");

    doc.setFontSize(11);
    doc.text(16, 210, `LCOE: ${LCOE.toFixed(2)}$/MWh`);
    doc.text(16, 216, `Wind CAPEX: ${wind_capex.toFixed(2)}$/kW`);
    doc.text(16, 222, `Wind OPEX: ${wind_opex.toFixed(2)}$/kW/year`);
    doc.text(100, 216, `Solar CAPEX: ${solar_capex.toFixed(2)}$/kW`);
    doc.text(100, 222, `Solar OPEX: ${solar_opex.toFixed(2)}$/kW/year`);

    if (formReducer.advancedLCOEFormData.grid_cost_per_km.checked)
      doc.text(
        16,
        228,
        `Distance to the nearest HV line: ${grid_distance.toFixed(3)}km`
      );

    if (formReducer.advancedLCOEFormData.slope_cost_per_percent.checked)
      doc.text(16, 234, `Slope: ${slope.toFixed(2)}%`);

    doc.setFontSize(BODY_SIZE);
    doc.text(16, 244, "Additional information about the location");
    doc.setFontSize(11);
    doc.text(16, 252, "Electricity tarif:");
    doc.text(100, 252, `${electricity_tarif} $/MWh`);
    doc.text(16, 258, "Getting Electricity ranking:");
    doc.text(100, 258, `${electricity_ranking}/187`);
    doc.text(16, 264, "Typical time for a grid connection:");
    doc.text(100, 264, `${grid_connection_time} days`);

    if (!(wind_monthly_capacity_factor && solar_monthly_capacity_factor)) {
      doc.addImage(wisogisblue, "PNG", 8, 8, 48, 16);
      doc.addPage();
      doc.text(8, 280, FOOTER_CONTENT);
      doc.text(195, 280, `${7 + 2 * j + 1}`);
    }

    doc.setFontSize(BODY_SIZE);
    doc.text(
      20,
      wind_monthly_capacity_factor && solar_monthly_capacity_factor ? 126 : 36,
      "Exclusions areas"
    );
    doc.setTextColor(BLACK_TEXT_COLOR);
    doc.setFontSize(BODY_SIZE);

    let exclusion_layers_par = [];
    let exclusion_layers_col = [
      { title: "Layer", dataKey: "layer" },
      { title: "", dataKey: "value" },
    ];

    if (layers) {
      if (layers.urban === true || layers.urban === false) {
        exclusion_layers_par.push({
          layer: `Residential areas with ${exclusionAreasFormData.urban}m buffer:`,
          value: layers.urban ? "In" : "Out",
        });
      }

      if (layers.protected_area === true || layers.protected_area === false) {
        exclusion_layers_par.push({
          layer: `Protected Areas with ${exclusionAreasFormData.protected_area}m buffer:`,
          value: layers.protected_area ? "In" : "Out",
        });
      }

      if (layers.forest === true || layers.forest === false) {
        exclusion_layers_par.push({
          layer: `Forests:`,
          value: layers.forest ? "In" : "Out",
        });
      }

      if (layers.airports === true || layers.airports === false) {
        exclusion_layers_par.push({
          layer: `Airports:`,
          value: layers.airports ? "In" : "Out",
        });
      }

      if (layers.complex_terrain === true || layers.complex_terrain === false) {
        exclusion_layers_par.push({
          layer: `Complex terrain:`,
          value: layers.complex_terrain ? "In" : "Out",
        });
      }

      if (layers.water === true || layers.water === false) {
        exclusion_layers_par.push({
          layer: `Water:`,
          value: layers.water ? "In" : "Out",
        });
      }

      if (layers.wetland === true || layers.wetland === false) {
        exclusion_layers_par.push({
          layer: `Wetland:`,
          value: layers.wetland ? "In" : "Out",
        });
      }
    }

    doc.autoTable(exclusion_layers_col, exclusion_layers_par, {
      pageBreak: "avoid",
      rowPageBreak: "avoid",
      horizontalPageBreak: false,
      startY:
        wind_monthly_capacity_factor && solar_monthly_capacity_factor
          ? 130
          : 40,
      headStyles: {
        fontStyle: "normal",
        value: { halign: "right" },
      },
      styles: {
        overflow: "hidden",
        theme: "plain",
        fillColor: [255, 255, 255],
        lineColor: 240,
        textColor: [42, 42, 42],
        lineWidth: 0,
        fontSize: 11,
      },
      columnStyles: {
        layer: { fillColor: false, cellWidth: 80 },
        value: { fillColor: false, halign: "right", cellWidth: 60 },
      },
    });
  }

  // Page 10
  doc.addPage();
  doc.addImage(wisogisblue, "PNG", 8, 8, 48, 16);

  doc.setTextColor(BLUE_TEXT_COLOR);
  doc.setFontSize(TITLE_SIZE);
  doc.setFont("Lato", "light");

  doc.text(16, 48, "Disclaimer");

  doc.setFont("Lato", "normal");
  doc.setTextColor(BLACK_TEXT_COLOR);
  doc.setFontSize(SMALL_SIZE);
  doc.text(
    20,
    60,
    "Tractebel S.A. cannot be held liable for any direct, incidental, consequential,"
  );
  doc.text(
    20,
    66,
    "indirect or punitive damages resulting or alleged to result from the use of the"
  );
  doc.text(20, 72, "report provided.");
  doc.text(20, 78, "More details : ");

  doc.setTextColor(BLUE_TEXT_COLOR);
  doc.textWithLink(TERMS_OF_USE, 46, 78, {
    url: TERMS_OF_USE,
  });

  doc.setTextColor(BLACK_TEXT_COLOR);
  doc.text(8, 280, FOOTER_CONTENT);
  doc.text(195, 280, `${7 + 2 * locations.length}`);

  // Page 11
  doc.addPage();
  doc.addImage(wisogisblue, "PNG", 8, 8, 48, 16);

  doc.setFillColor(64, 173, 248);
  doc.rect(8, 42, 194, 190, "F");
  doc.addImage(TopLeftCorner, "PNG", 20, 52);
  doc.addImage(BottomRightCorner, "PNG", 160, 190);

  doc.setTextColor(WHITE_TEXT_COLOR);
  doc.setFontSize(SMALLER_SIZE);
  doc.text(
    20,
    170,
    "Tractebel is a global engineering company delivering game-changing"
  );
  doc.text(
    20,
    174,
    "solutions for a carbon-neutral future. Insights gathered during our"
  );
  doc.text(
    20,
    178,
    "more than 150 years of experience in energy, urban, nuclear and"
  );
  doc.text(
    20,
    182,
    "water projects combined with local expertise allow us to tackle"
  );
  doc.text(
    20,
    186,
    "complex future-oriented projects. By connecting strategy, design,"
  );
  doc.text(
    20,
    190,
    "engineering and project management, our community of 5,000"
  );
  doc.text(
    20,
    194,
    "imaginative experts helps companies and public authorities create"
  );
  doc.text(
    20,
    198,
    "positive impact towards a sustainable world, where people, planet and"
  );
  doc.text(
    20,
    202,
    "profit collectively thrive. With offices in Europe, Africa, Asia, the"
  );
  doc.text(
    20,
    206,
    "Middle East and Latin America, the company registered a turnover of"
  );
  doc.text(20, 210, "671 million.");

  doc.setFontSize(BODY_SIZE);
  doc.setTextColor(DARK_TEXT_COLOR);
  doc.text(8, 250, "TRACTEBEL ENGINEERING S.A.");
  doc.setTextColor(BLACK_TEXT_COLOR);
  doc.text(8, 256, "Wisogis");

  doc.text(8, 262, "Boulevard Simon Bolivar 34-36");

  doc.text(8, 268, "tractebel-engie.com");

  doc.setFontSize(SMALL_SIZE);
  doc.text(8, 280, "wisogis@tractebel.engie.com");
  doc.text(195, 280, `${8 + 2 * locations.length}`);

  // File outpout file
  doc.save("Wisogis report.pdf");
  cb();
};

export default generatePDF;
