import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { PageContainer } from "./Common";

import TopLeftCorner from "../../assets/TopLeftCorner.png";
import BottomRightCorner from "../../assets/BottomRightCorner.png";

const BlueContainer = styled.div`
  background-color: #40adf8;
  color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const Page11 = () => (
  <PageContainer id="pdf-back-cover">
    <Flex>
      <BlueContainer>
        <img
          src={TopLeftCorner}
          style={{ width: 120, height: 120, margin: 24 }}
          alt="top-left-corner"
        />
        <span style={{ margin: 24, display: "flex" }}>
          <Typography variant="subtitle2">
            Tractebel is a global engineering company delivering game-changing
            solutions for a carbon-neutral future. Insights gathered during our
            more than 150 years of experience in energy, urban, nuclear and
            water projects combined with local expertise allow us to tackle
            complex future-oriented projects. By connecting strategy, design,
            engineering and project management, our community of 5,000
            imaginative experts helps companies and public authorities create
            positive impact towards a sustainable world, where people, planet
            and profit collectively thrive. With offices in Europe, Africa,
            Asia, the Middle East and Latin America, the company registered a
            turnover of 671 million.
          </Typography>
          <img
            src={BottomRightCorner}
            style={{
              alignSelf: "flex-end",
              width: 120,
              height: 120,
              margin: 24,
              marginBottom: 0,
            }}
            alt="bottom-right-corner"
          />
        </span>
      </BlueContainer>
      <div>
        <div>&nbsp;</div>
        <Typography>TRACTEBEL ENGINEERING S.A.</Typography>
        <Typography>Wisogis</Typography>
        <Typography>Boulevard Simon Bolivar 34-36</Typography>
        <Typography>tractebel-engie.com</Typography>
        <div>&nbsp;</div>
        <Typography>wisogis@tractebel.engie.com</Typography>
      </div>
    </Flex>
  </PageContainer>
);

export default Page11;
