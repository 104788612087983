import { generateBody } from "./index";
import { SET_API_LOADING_LOCATION_DATA } from "../constants";
import {simulationApiClient} from "../apiClient";
import {GetLocationDetailsCommand} from "@wisogis/simulation-sdk";
import {showErrorSnackbar} from "../actions/snackbarActions";

export const getLocationDetails = async (
  props,
  coords,
  successCb,
  csv = null
) => {

  try {
    props.dispatch({ type: SET_API_LOADING_LOCATION_DATA, payload: true });

    const userId = props.formReducer.user.user_id
    const bodyGenerated = generateBody(props, null, coords)

    const locationDetails = await simulationApiClient.send(new GetLocationDetailsCommand({
      uid: userId,
      lon: coords[0],
      lat: coords[1],
      parameters: {
        optimization: bodyGenerated.opti.toUpperCase(),
        combined: bodyGenerated.combined_slider ?? (props.formReducer.renewableTechFormData.wind ? 1. : 0.),
        grid_connection_type: bodyGenerated.grid_connection_type.toUpperCase(),
        grid_cost_per_km: bodyGenerated.grid_cost_per_km,
        slope_cost_per_percent: bodyGenerated.slope_cost_per_percent,
        pv_tracking: bodyGenerated.pv_tracking ?? false,
        model_wt: bodyGenerated.model_WT,
        lcoe_params: bodyGenerated.LCOE_params,
        layer_params: bodyGenerated.layers,
        max_slope: bodyGenerated.layers.complex_terrain ?? 100
      }
    }))

    props.dispatch({ type: SET_API_LOADING_LOCATION_DATA, payload: false })
    return successCb({
      ...locationDetails,
      LCOE: locationDetails.lcoe,
      electricity_tarif: locationDetails.electricity_price,
    })
  } catch (e) {
    props.dispatch(showErrorSnackbar("An error occurred"))
  }
};
