import { UserServiceServiceException as __BaseException } from "./UserServiceServiceException";
export const ValidationExceptionFieldFilterSensitiveLog = (obj) => ({
    ...obj,
});
export class ValidationException extends __BaseException {
    constructor(opts) {
        super({
            name: "ValidationException",
            $fault: "client",
            ...opts
        });
        this.name = "ValidationException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ValidationException.prototype);
        this.fieldList = opts.fieldList;
    }
}
export class NoSuchResource extends __BaseException {
    constructor(opts) {
        super({
            name: "NoSuchResource",
            $fault: "client",
            ...opts
        });
        this.name = "NoSuchResource";
        this.$fault = "client";
        Object.setPrototypeOf(this, NoSuchResource.prototype);
        this.resourceType = opts.resourceType;
        this.resourceId = opts.resourceId;
    }
}
export const GetUserDetailsInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export var License;
(function (License) {
    License["REGION"] = "region";
    License["WORLDWIDE"] = "worldwide";
})(License || (License = {}));
export var Region;
(function (Region) {
    Region["AFRICA"] = "africa";
    Region["ASIA"] = "asia";
    Region["EURASIA"] = "eurasia";
    Region["EUROPE"] = "europe";
    Region["MIDDLE_EAST"] = "middle_east";
    Region["NORTH_AMERICA"] = "north_america";
    Region["OCEANIA"] = "oceania";
    Region["SOUTH_CENTRAL_AMERICA"] = "south_central_america";
})(Region || (Region = {}));
export const GetUserDetailsOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
