import countries from "../sampledata/countries.json";

const isPolygonInRegion = (coordinates, license) => {
  let isValid = false;
  const countries_in_license = countries.filter(
    (r) => r[0].properties["IRENA-Regi"] === license
  );
  for (const coordinate of coordinates) {
    for (let c of countries_in_license) {
      isValid = pointInZone(c, coordinate[0], coordinate[1]);
      if (isValid) break;
    }
    if (!isValid) return false;
  }
  return isValid;
};

const pointInZone = (c, x, y) => {
  let isValid = false;
  for (const zones of c[0].geometry.coordinates) {
    for (const zone of zones) {
      let zoneToTest = zone.length === 2 ? zones : zone;
      isValid = isPointInPolygon(x, y, zoneToTest);
      if (isValid) return true;
    }
  }
  return false;
};

function isPointInPolygon(latitude, longitude, polygon) {
  if (typeof latitude !== "number" || typeof longitude !== "number") {
    throw new TypeError("Invalid latitude or longitude. Numbers are expected");
  } else if (!polygon || !Array.isArray(polygon)) {
    throw new TypeError("Invalid polygon. Array with locations expected");
  } else if (polygon.length === 0) {
    throw new TypeError("Invalid polygon. Non-empty Array expected");
  }

  const x = latitude;
  const y = longitude;

  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i][0];
    const yi = polygon[i][1];
    const xj = polygon[j][0];
    const yj = polygon[j][1];

    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

export default isPolygonInRegion;
