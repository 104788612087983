import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";
import { faDrawPolygon } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import {
  addDataToMap,
  removeDataset,
  deleteFeature,
  toggleSidePanel,
} from "kepler.gl/actions";
import CountriesData from "../../sampledata/countries.json";
import { bordersConfig } from "../../map-configs";
import HelpText from "../HelpText";
import styled from "styled-components";
import tractebelGrey from "../../assets/tractebel-grey.jpg";
import "./styles.scss";

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 64px;
  color: #6a7485;
  align-self: center;
  &:hover {
    color: #0b74c0;
  }
`;

const container = {
  display: "flex",
  flex: 1,
  maxHeight: 100,
  flexDirection: "column",
  color: "#000000",
  justifyItems: "center",
  justifyContent: "space-around",
  textAlign: "center",
};

// Toggler for selected zone type. Resets polygon selection in state and keplerGl selectedFeature state
const toggleActiveZoneType = (type, props) => {
  props.dispatch({ type: "CUSTOM_DELETE_POLYGON" });
  const countryContainer = document.getElementsByClassName(
    "country-type-container"
  )[0];
  const areaContainer = document.getElementsByClassName(
    "area-type-container"
  )[0];
  if (type === "country") {
    // Deactivates draw mode if active
    if (props.keplerReducer.map.uiState.mapControls.mapDraw.active === true) {
      props.dispatch({
        type: "@@kepler.gl/TOGGLE_MAP_CONTROL",
        payload: {
          panelId: "mapDraw",
          index: 0,
        },
      });
    }
    if (countryContainer.classList.contains("active")) {
      props.dispatch({
        type: "SET_ZONE_OR_COUNTRY",
        payload: { country: false, zone: false },
      });
      props.dispatch(removeDataset("countries"));
    } else {
      props.dispatch({
        type: "SET_ZONE_OR_COUNTRY",
        payload: { country: true, zone: false },
      });
      // Keeping current mapState ( zoom level etc ) to inject into config
      const currentMap = props.keplerReducer.map;
      const mapConfigClone = bordersConfig;
      mapConfigClone.config.mapState = currentMap.mapState;

      props.dispatch(
        addDataToMap({
          datasets: {
            info: {
              label: "Countries",
              id: "countries",
            },
            data: {
              fields: [
                {
                  name: "_geojson",
                  type: "geojson",
                  format: "",
                  analyzerType: "GEOMETRY",
                },
                {
                  name: "OBJECTID",
                  type: "integer",
                  format: "",
                  analyzerType: "INT",
                },
                {
                  name: "CNTRY_NAME",
                  type: "string",
                  format: "",
                  analyzerType: "STRING",
                },
                {
                  name: "IRENA-Regi",
                  type: "string",
                  format: "",
                  analyzerType: "STRING",
                },
              ],
              rows:
                props.formReducer.user.license_type === "region"
                  ? CountriesData.filter(
                      (a) =>
                        a[0].properties["IRENA-Regi"] ===
                        props.formReducer.user.region
                    )
                  : [...CountriesData],
            },
          },
          options: {
            readOnly: false,
            keepExistingConfig: false,
            centerMap: false,
          },
          config: mapConfigClone,
        })
      );
    }
  } else if (type === "area") {
    if (areaContainer.classList.contains("active")) {
      props.dispatch({
        type: "SET_ZONE_OR_COUNTRY",
        payload: { country: false, zone: false },
      });
      // Deactivates draw mode
      if (props.keplerReducer.map.uiState.mapControls.mapDraw.active === true) {
        props.dispatch({
          type: "@@kepler.gl/TOGGLE_MAP_CONTROL",
          payload: {
            panelId: "mapDraw",
            index: 0,
          },
        });
      }
      if (props.keplerReducer.map.visState.editor.features?.length !== 0) {
        props.dispatch(
          deleteFeature(props.keplerReducer.map.visState.editor.features[0])
        );
        props.dispatch({ type: "CUSTOM_DELETE_POLYGON" });
      }
    } else {
      props.dispatch({
        type: "SET_ZONE_OR_COUNTRY",
        payload: { country: false, zone: true },
      });
      props.dispatch(removeDataset("countries"));
      // Activates draw mode
      if (
        props.keplerReducer.map.uiState.mapControls.mapDraw.active === false
      ) {
        props.dispatch({
          type: "@@kepler.gl/TOGGLE_MAP_CONTROL",
          payload: {
            panelId: "mapDraw",
            index: 0,
          },
        });
        props.dispatch({
          type: "@@kepler.gl/SET_EDITOR_MODE",
          mode: "DRAW_POLYGON",
        });
      }
    }
  }
};

const Locations = (props) => {
  const [pickupLocation, setPickupLocation] = React.useState(false);
  const [currentCoord, setCurrentCoord] = React.useState();

  const addLocation = () => {
    props.dispatch({
      type: "ADD_LOCATION",
    });
  };

  const updateLocation = (id, lng, lat, name) => {
    props.dispatch({
      type: "UPDATE_LOCATION",
      payload: { id, lng, lat, name },
    });
  };

  const removeLocation = (id) => {
    props.dispatch({
      type: "REMOVE_LOCATION",
      payload: id,
    });
  };

  const geolocations = props.formReducer.zoneSelectionData.locations;

  if (pickupLocation) {
    if (props.appReducer.coordinate) {
      const [lng, lat] = props.appReducer.coordinate;
      if (
        geolocations &&
        !geolocations.filter((gl) => gl.lng === lng && gl.lat === lat).length &&
        currentCoord !== props.appReducer.coordinate
      ) {
        props.dispatch({
          type: "ADD_LOCATION",
          payload: { lng, lat },
        });
        setPickupLocation(false);
      }
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <>
        <div style={{ flex: 1 }}>
          <form noValidate autoComplete="off">
            {geolocations.map((geolocation) => (
              <div
                key={geolocation.id}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  label="Name"
                  placeholder="New Site"
                  size="small"
                  onChange={(e) =>
                    updateLocation(
                      geolocation.id,
                      geolocation.lng,
                      geolocation.lat,
                      e.target.value
                    )
                  }
                  value={geolocation.name}
                  style={{ paddingRight: 4 }}
                />
                <TextField
                  label="Latitude"
                  placeholder="51.260197"
                  size="small"
                  error={
                    !(
                      isFinite(geolocation.lat) &&
                      Math.abs(geolocation.lat) <= 90
                    )
                  }
                  onChange={(e) =>
                    updateLocation(
                      geolocation.id,
                      geolocation.lng,
                      e.target.value,
                      geolocation.name
                    )
                  }
                  value={geolocation.lat}
                  style={{ paddingRight: 4 }}
                />
                <TextField
                  label="Longitude"
                  placeholder="4.402771"
                  error={
                    !(
                      isFinite(geolocation.lng) &&
                      Math.abs(geolocation.lng) <= 180
                    )
                  }
                  onChange={(e) =>
                    updateLocation(
                      geolocation.id,
                      e.target.value,
                      geolocation.lat,
                      geolocation.name
                    )
                  }
                  value={geolocation.lng}
                  size="small"
                  style={{ paddingRight: 4 }}
                />
                <IconButton onClick={() => removeLocation(geolocation.id)}>
                  <Icon style={{ color: "#fb5c5c" }}>close</Icon>
                </IconButton>
              </div>
            ))}
          </form>
          <div
            style={{ width: "100%" }}
            onClick={() => addLocation({ id: uuidv4(), lat: "", lng: "" })}
            className="run-button"
          >
            ADD A NEW LOCATION
          </div>
          <div
            style={{
              width: "100%",
              backgroundColor: pickupLocation ? "#fb5c5c" : "#009de9",
            }}
            onClick={() => {
              props.dispatch({
                type: "SET_ZONE_OR_COUNTRY",
                payload: { country: false, zone: false },
              });
              if (
                props.keplerReducer.map.uiState.mapControls.mapDraw.active ===
                true
              ) {
                props.dispatch({
                  type: "@@kepler.gl/TOGGLE_MAP_CONTROL",
                  payload: {
                    panelId: "mapDraw",
                    index: 0,
                  },
                });
              }
              setPickupLocation((o) => {
                setCurrentCoord(props.appReducer.coordinate);
                return !o;
              });
            }}
            className="run-button"
          >
            {!pickupLocation ? "PICK LOCATION" : "STOP PICK LOCATION"}
          </div>
        </div>
      </>
      <div className="button-wrapper" style={{ justifyContent: "flex-end" }}>
        <div
          onClick={() => props.dispatch(toggleSidePanel("renewable-sizing"))}
          className="run-button"
        >
          Next
        </div>
      </div>
    </div>
  );
};

const Region = (props) => (
  <>
    <div
      style={{
        textAlign: "center",
        fontSize: "1.2em",
        marginTop: 12,
        marginBottom: 4,
        fontWeight: "bold",
      }}
    >
      Choose selection type:
    </div>
    <div
      className="zone-selector"
      style={{
        display: "flex",
        flex: 1,
      }}
    >
      <HelpText element="Country">
        <div
          onClick={() => toggleActiveZoneType("country", props)}
          className={
            props.formReducer.zoneSelectionData.country === true
              ? "country-type-container active"
              : "country-type-container"
          }
          style={container}
        >
          <StyledIcon icon={faGlobeAmericas} />
          <div className="country-title">Country</div>
        </div>
      </HelpText>
      <HelpText element="Zone">
        <div
          onClick={() => toggleActiveZoneType("area", props)}
          className={
            props.formReducer.zoneSelectionData.zone === true
              ? "area-type-container active"
              : "area-type-container"
          }
          style={container}
        >
          <StyledIcon icon={faDrawPolygon} />
          <div className="zone-title">Zone</div>
        </div>
      </HelpText>
    </div>

    {props.formReducer.user.license_type === "region" ? (
      <div>License type region: {props.formReducer.user.region}</div>
    ) : null}

    <div className="button-wrapper">
      <div />
      <div
        onClick={() => props.dispatch(toggleSidePanel("renewable-sizing"))}
        className="run-button"
      >
        Next
      </div>
    </div>
  </>
);

const ZonePanelComp = (props) => {
  const toggleChecked = (e) => {
    props.dispatch(removeDataset("countries"));
    props.dispatch({
      type: "SET_ZONE_OR_COUNTRY",
      payload: { country: false, zone: false },
    });
    props.dispatch({
      type: "SET_HEATMAP_OR_LOCATIONS",
      payload: e.target.checked,
    });
  };

  const setConfigurationName = (name) => {
    props.dispatch({ type: "SET_ZONE_OR_COUNTRY", payload: { name } });
  };

  const license_type = props.formReducer.user.license_type;

  // Case where LOCATIONS is selected
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <TextField
          label="Simulation name"
          placeholder="Name"
          size="small"
          onChange={(e) => setConfigurationName(e.target.value)}
          value={props.formReducer.zoneSelectionData.name}
          style={{ marginBottom: 8 }}
          InputLabelProps={{ style: { fontSize: "1.2rem" } }}
        />

        {license_type === "worldwide" || license_type === "region" ? (
          <div className="switch-container">
            <HelpText element="Heatmap">
              <div className="heatmap-switch">HEATMAP</div>
            </HelpText>
            <Switch
              onChange={(e) => toggleChecked(e)}
              checked={props.uiStateReducer.is_locations}
              className="custom-switch"
              disableRipple
              disableFocusRipple
            />
            <HelpText element="Locations">
              <div className="locations-switch">LOCATIONS</div>
            </HelpText>
          </div>
        ) : null}

        {props.uiStateReducer.is_locations ? <Locations {...props} /> : null}

        {!props.uiStateReducer.is_locations &&
        (license_type === "worldwide" || license_type === "region") ? (
          <Region {...props} />
        ) : null}
      </div>
      <div style={{ padding: "14px 30px", textAlign: "center" }}>
        <img src={tractebelGrey} style={{ maxWidth: "50%" }} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    formReducer: state.formReducer,
    uiStateReducer: state.uiStateReducer,
    appReducer: state.appReducer,
    keplerReducer: state.keplerGl,
  };
};
const dispatchToProps = (dispatch) => ({ dispatch });

export const ZonePanel = connect(
  mapStateToProps,
  dispatchToProps
)(ZonePanelComp);
