import Typography from "@material-ui/core/Typography";
import { Content, PageContainer } from "./Common";

const Page2 = ({ locations, locationsLength }) => (
  <PageContainer id="pdf-table-of-contents">
    <Typography variant="h3" style={{ color: "#40adf8" }}>
      Table of contents
    </Typography>
    <hr />
    <Content>
      <Typography variant="h4">1. Introduction</Typography>
      <Typography variant="h4">
        2. Simulation overview and site coordinates
      </Typography>
      <Typography variant="h4">3. Simulation results</Typography>
      <Typography variant="h5">3.a. Results overview</Typography>
      {[...new Array(locationsLength)].map((_, i) => (
        <Typography key={i} variant="h5">
          3.{String.fromCharCode(98 + i)}. Detailed report{" "}
          {locations[i].name ? locations[i].name : "site n°" + (i + 1)}
        </Typography>
      ))}
    </Content>
  </PageContainer>
);

export default Page2;
