import {simulationApiClient} from "../apiClient";
import {RenameSimulationCommand} from "@wisogis/simulation-sdk";
import {getUserEmail} from "../userIdentity";
import {showErrorSnackbar} from "../actions/snackbarActions";

export const updateInputName = async (input_id, name, successCb) => {

  try {
    await simulationApiClient.send(new RenameSimulationCommand({
      id: input_id,
      uid: getUserEmail(),
      name: name
    }))
  } catch (e) {
  }
};
