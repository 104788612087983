import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { addDataToMap, toggleSidePanel } from "kepler.gl/actions";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { getMapsFromBackend } from "../../service";
import { showErrorSnackbar } from "../../actions/snackbarActions";
import Report from "../report";
import "./styles.scss";
import isPolygonInRegion from "../../utils/pointInPolygon";
import generatePDF from "../../utils/generateResults";
import tractebelGrey from "../../assets/tractebel-grey.jpg";

const addPoint = (coordinates, dispatch) => {
  dispatch(
    addDataToMap({
      datasets: coordinates.map((coordinate) => ({
        info: {
          label: "Marker",
          id: coordinate.id,
        },
        data: {
          fields: [
            {
              name: "_geojson",
              type: "geojson",
              format: "",
              analyzerType: "GEOMETRY",
            },
          ],
          rows: [
            [
              {
                type: "Feature",
                properties: {
                  fillColor: [230, 43, 137],
                  color: "#e62b87",
                  strokeColor: [77, 193, 156],
                  strokeWidth: 32,
                  pointRadius: 42,
                  latitude: coordinate.lat,
                  longitude: coordinate.lng,
                },
                geometry: {
                  type: "Point",
                  coordinates: [coordinate.lng, coordinate.lat],
                },
              },
            ],
          ],
        },
      })),
      options: {
        readOnly: false,
        keepExistingConfig: false,
        centerMap: false,
        autoCreateLayers: true,
      },
    })
  );
};

const Row = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    margin: "1px",
    color: "#000000",
    width: "100%",
  },
  radio: {
    marginLeft: "20px",
  },
  radioLabel: {
    fontSize: "12px",
  },
  title: {
    fontSize: "18px",
    color: "#000000",
    fontWeight: 300,
    fontFamily: "Lato",
  },
}));

const checkWarnings = (advancedLCOEFormData) => {
  const {
    solar_capex,
    wind_capex,
    solar_opex,
    wind_opex,
    wacc,
    lifetime,
    grid_cost_per_km,
    slope_cost_per_percent,
  } = advancedLCOEFormData;

  const warnings = [];

  if (solar_capex && (solar_capex < 500 || solar_capex > 3000))
    warnings.push(
      "Are you sure you want to use a custom value for the Solar CAPEX ?"
    );
  if (wind_capex && (wind_capex < 500 || wind_capex > 3000))
    warnings.push(
      "Are you sure you want to use a custom value for the Wind CAPEX ?"
    );
  if (solar_opex && (solar_opex < 5 || solar_opex > 100))
    warnings.push(
      "Are you sure you want to use a custom value for the Solar OPEX ?"
    );
  if (wind_opex && (wind_opex < 5 || wind_opex > 100))
    warnings.push(
      "Are you sure you want to use a custom value for the Wind OPEX ?"
    );
  if (wacc && (wacc < 1 || wacc > 20))
    warnings.push("Are you sure you want to use a custom value for the WACC ?");
  if (lifetime && (lifetime < 5 || lifetime > 50))
    warnings.push(
      "Are you sure you want to use a custom value for the lifetime ?"
    );
  if (
    grid_cost_per_km.checked &&
    grid_cost_per_km.value !== "" &&
    (grid_cost_per_km.value < 1 || grid_cost_per_km.value > 100)
  )
    warnings.push(
      "Are you sure you want to use a custom value for the Grid connection cost ?"
    );
  if (
    slope_cost_per_percent.checked &&
    slope_cost_per_percent.value !== "" &&
    (slope_cost_per_percent.value < 1 || slope_cost_per_percent.value > 100)
  )
    warnings.push(
      "Are you sure you want to use a custom value for the Grading ?"
    );

  return warnings;
};

const Panel = ({ title, data }) => (
  <div style={{ display: "flex", flexDireciton: "column" }}>
    <Typography>{title}</Typography>
    {Object.keys(data)
      .filter((d) => !!data[d])
      .map((key) => (
        <div key={key}>
          {typeof data[key] === "object" ? (
            <Panel title={key} data={data[key]} />
          ) : (
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
              }}
            >
              <Typography>
                {key.charAt(0).toUpperCase() +
                  key.slice(1).split("_").join(" ")}
              </Typography>
              <Typography>{data[key]}</Typography>
            </div>
          )}
        </div>
      ))}
  </div>
);

const ZoneSelection = (props) => {
  let innerText = "";
  if (props.uiStateReducer.is_locations) {
    innerText = "LOCATIONS";
  } else if (props.formReducer.zoneSelectionData.country) {
    innerText = "COUNTRY";
  } else if (props.formReducer.zoneSelectionData.zone) {
    innerText = "ZONE";
  } else if (props.keplerReducer?.map?.visState?.datasets?.heatmap) {
    innerText = "LOADED";
  } else if (props.loadingReducer.isApiLoadingHeatmap) {
    innerText = "LOADING...";
  } else {
    return <Typography color="error">MISSING</Typography>;
  }

  return <Typography color="primary">{innerText}</Typography>;
};

const AdvancedLCOE = ({ advancedLCOEFormData, isWind, isSolar }) => (
  <>
    <Typography variant="subtitle2" style={{ fontSize: "0.99rem" }}>
      Advanced LCOE
    </Typography>

    <Row>
      <Typography>&bull; Criteria</Typography>
      <div>
        {advancedLCOEFormData.grid_cost_per_km.checked ? (
          <Typography color="primary" align="right">
            <span>Grid: </span>
            {advancedLCOEFormData.grid_connection_type === "substation"
              ? "Substation"
              : "HV Line"}
            :
            <span>
              {advancedLCOEFormData.grid_cost_per_km.value
                ? `${advancedLCOEFormData.grid_cost_per_km.value}$/kW/km`
                : "DEFAULT"}
            </span>
          </Typography>
        ) : null}

        {advancedLCOEFormData.slope_cost_per_percent.checked ? (
          <Typography color="primary" align="right">
            Slope:{" "}
            <span>
              {advancedLCOEFormData.slope_cost_per_percent.value
                ? `${advancedLCOEFormData.slope_cost_per_percent.value}$/kW/%slope`
                : "DEFAULT"}
            </span>
          </Typography>
        ) : null}
      </div>
    </Row>

    <br />

    <Row>
      <Typography>&bull; CAPEX</Typography>
      <div>
        {isWind ? (
          <Typography color="primary" align="right">
            Wind:{" "}
            {advancedLCOEFormData.wind_capex
              ? `${advancedLCOEFormData.wind_capex} $/kW`
              : "DEFAULT"}
          </Typography>
        ) : null}

        {isSolar ? (
          <Typography color="primary" align="right">
            Solar:{" "}
            {advancedLCOEFormData.solar_capex
              ? `${advancedLCOEFormData.solar_capex} $/kW`
              : "DEFAULT"}
          </Typography>
        ) : null}
      </div>
    </Row>

    <br />

    <Row>
      <Typography>&bull; OPEX</Typography>
      <div>
        {isWind ? (
          <Typography color="primary" align="right">
            Wind:{" "}
            {advancedLCOEFormData.wind_opex
              ? `${advancedLCOEFormData.wind_opex} $/kW/yr`
              : "DEFAULT"}
          </Typography>
        ) : null}

        {isSolar ? (
          <Typography color="primary" align="right">
            Solar:{" "}
            {advancedLCOEFormData.solar_opex
              ? `${advancedLCOEFormData.solar_opex} $/kW/yr`
              : "DEFAULT"}
          </Typography>
        ) : null}
      </div>
    </Row>

    <br />

    <Typography>&bull; Others</Typography>

    {advancedLCOEFormData.lifetime ? (
      <Row>
        <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&bull; Lifetime:</Typography>
        <Typography color="primary">
          {advancedLCOEFormData.lifetime}yrs
        </Typography>
      </Row>
    ) : null}

    <Row>
      <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&bull; Wacc:</Typography>
      <Typography color="primary">
        {advancedLCOEFormData.wacc
          ? `${advancedLCOEFormData.wacc} %`
          : "DEFAULT"}
      </Typography>
    </Row>
  </>
);

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Warnings = ({ warnings, open, setOpen, runCalculation }) => (
  <Fade in={open}>
    <Box
      style={{
        background: "white",
        padding: 24,
        margin: "25% auto",
        position: "fixed",
        left: 0,
        right: 0,
        width: 600,
      }}
    >
      <Typography variant="h6" component="h2">
        Warning: unrecommended custom values used
      </Typography>
      <div style={{ padding: "12px 0" }}>
        {warnings.map((warning) => (
          <Typography key={warning}>&bull; {warning}</Typography>
        ))}
      </div>
      <ButtonWrapper>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(false)}
          style={{ marginRight: 6, borderRadius: 0 }}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={runCalculation}
          style={{ borderRadius: 0 }}
        >
          Run
        </Button>
      </ButtonWrapper>
    </Box>
  </Fade>
);

export const SummaryPanelComp = (props) => {
  const [report, showReport] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const zoneSelected =
    props.appReducer.selectedPolygon || props.uiStateReducer.is_locations;

  const isWind = props.formReducer.renewableTechFormData.wind;
  const isSolar = props.formReducer.renewableTechFormData.solar;

  const renewableSelected = isWind || isSolar;

  const warnings = checkWarnings(props.formReducer.advancedLCOEFormData);

  const runCalculation = () => {
    let notValid = false;

    if (
      props.formReducer.user.license_type === "region" &&
      props.formReducer.zoneSelectionData.country === false &&
      props.formReducer.zoneSelectionData.zone === true &&
      !props.uiStateReducer.is_locations
    )
      notValid = !isPolygonInRegion(
        props.appReducer.selectedPolygon,
        props.formReducer.user.region
      );
    setOpen(false);
    if (notValid)
      return props.dispatch(
        showErrorSnackbar(
          `Point(s) outside of region ${props.formReducer.user.region}.`
        )
      );

    // props.dispatch("REMOVE_HISTORY_INPUTS");

    if (zoneSelected && renewableSelected) {
      if (props.uiStateReducer.is_locations) {
        addPoint(props.formReducer.zoneSelectionData.locations, props.dispatch);
        showReport(true);
      } else {
        getMapsFromBackend(props);
      }
    } else {
      props.dispatch(
        toggleSidePanel(!renewableSelected ? "renewable-sizing" : "zone")
      );
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick
      >
        <Warnings
          warnings={warnings}
          open={open}
          setOpen={setOpen}
          runCalculation={runCalculation}
        />
      </Modal>
      <Modal
        open={report}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onClose={() => showReport(false)}
      >
        <Report close={() => showReport(false)} />
      </Modal>
      <div className={classes.root} style={{ flex: 1 }}>
        <Row>
          <Typography variant="subtitle2" style={{ fontSize: "0.99rem" }}>
            Zone Selection
          </Typography>

          <ZoneSelection {...props} />
        </Row>

        <br />

        <Row>
          <Typography variant="subtitle2" style={{ fontSize: "0.99rem" }}>
            Renewable Technology
          </Typography>

          <div>
            {renewableSelected ? (
              <div>
                <div>
                  <Typography color="primary" align="right">
                    {isWind && "Wind"}
                    {isWind && isSolar ? " & " : " "}
                    {isSolar && "Solar"}
                    {isSolar ? (
                      props.formReducer.renewableTechFormData.pv_tracking === false ? (
                        <div> (Fixed tilt)</div>
                      ) : (
                        <div> (Single axis tracking)</div>
                      )
                    ) : (
                      " "
                    )}
                  </Typography>
                </div>
              </div>
            ) : (
              <Typography color="error">MISSING</Typography>
            )}
          </div>
        </Row>

        <br />

        <Row>
          <Typography>&bull; Renewable mix opti:</Typography>
          <div>
            <Typography color="primary">
              {isSolar
                ? isWind
                  ? `LCOE: ${(
                      100 *
                      props.formReducer.renewableTechFormData.combined_slider
                    ).toFixed()}%`
                  : "SOLAR"
                : "WIND"}
            </Typography>
            <Typography color="primary" align="right">
              {isSolar
                ? isWind
                  ? `Stability: ${(
                      100 -
                      100 *
                        props.formReducer.renewableTechFormData.combined_slider
                    ).toFixed()}%`
                  : "SOLAR"
                : "WIND"}
            </Typography>
          </div>
        </Row>

        <AdvancedLCOE
          advancedLCOEFormData={props.formReducer.advancedLCOEFormData}
          isWind={isWind}
          isSolar={isSolar}
        />

        <br />

        <Row>
          <Typography variant="subtitle2" style={{ fontSize: "0.99rem" }}>
            Exclusion Areas
          </Typography>

          <div>
            {props.formReducer.exclusionAreasFormData.water ? (
              <div>
                <Typography color="primary" align="right">
                  Permanent water
                </Typography>
              </div>
            ) : null}
            {props.formReducer.exclusionAreasFormData.wetland ? (
              <div>
                <Typography color="primary" align="right">
                  Herbaceous wetland
                </Typography>
              </div>
            ) : null}
            {props.formReducer.exclusionAreasFormData.is_protected_area ? (
              <div>
                <Typography color="primary" align="right">
                  Protected areas
                </Typography>
              </div>
            ) : null}
            {props.formReducer.exclusionAreasFormData.is_urban ? (
              <div>
                <Typography color="primary" align="right">
                  Urban or built up
                </Typography>
              </div>
            ) : null}
            {props.formReducer.exclusionAreasFormData.is_forest ? (
              <div>
                <Typography color="primary" align="right">
                  Forest
                </Typography>
              </div>
            ) : null}
            {props.formReducer.exclusionAreasFormData
              .ui_show_complex_terrain ? (
              <div>
                <Typography color="primary" align="right">
                  Maximum local slope
                </Typography>
              </div>
            ) : null}
            {props.formReducer.exclusionAreasFormData.is_airports ? (
              <div>
                <Typography color="primary" align="right">
                  Airports
                </Typography>
              </div>
            ) : null}
          </div>
        </Row>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          onClick={() => props.dispatch(toggleSidePanel("exclusion"))}
          className="previous-button"
        >
          Previous
        </div>
        <div
          className="run-button"
          onClick={warnings.length > 0 ? () => setOpen(true) : runCalculation}
          style={
            !(zoneSelected && renewableSelected)
              ? {
                  backgroundColor: "#e62b87",
                }
              : {}
          }
        >
          {zoneSelected && renewableSelected ? "Run" : "Fix"}
        </div>
      </div>
      <div style={{ padding: "14px 30px", textAlign: "center" }}>
        <img src={tractebelGrey} style={{ maxWidth: "50%" }} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingReducer: state.loadingReducer,
    formReducer: state.formReducer,
    uiStateReducer: state.uiStateReducer,
    appReducer: state.appReducer,
    keplerReducer: state.keplerGl,
  };
};
const dispatchToProps = (dispatch) => ({ dispatch });

export const SummaryPanel = connect(
  mapStateToProps,
  dispatchToProps
)(SummaryPanelComp);
