import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import {
  addDataToMap,
  toggleModal,
  updateMap,
  setExportImageSetting,
} from "kepler.gl/actions";
import generatePDF from "../../utils/generatePDF";
import jsPDF from "jspdf";
import { getLocationDetails } from "../../service/";
import { SimpleSpinner } from "../Spinner";
import html2canvas from "html2canvas";
import wisogisblue from "../../assets/wisogisblue.png";
import FrontCover from "./FrontCover";
import TableOfContents from "./TableOfContents";
import Introduction from "./Introduction";
import Overview from "./Overview";
import Summary from "./Summary";
import Results from "./Results";
import DetailedReport from "./DetailedReport";
import Parameters from "./Parameters";
import Disclaimer from "./Disclaimer";
import BackCover from "./BackCover";
import { SET_API_LOADING_LOCATION_DATA } from "../../constants";

const PagesContainer = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  position: absolute;
  height: 100%;
  z-index: 9998;
  align-self: center;
  margin: auto;
  width: 100%;
  overflow: auto;
  margin-bottom: 24px;
`;

const ExportButtonContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  min-height: 54px;
  justify-content: center;
  z-index: 9999;
`;

const PDFReport = ({ locationsResults, locations, formReducer }) => (
  <PagesContainer>
    <FrontCover />
    <TableOfContents locations={locations} locationsLength={locations.length} />
    <Introduction />
    <Overview locations={locations} locationsResults={locationsResults} />
    <Summary formReducer={formReducer} />
    <Results locations={locations} locationsResults={locationsResults} />
    {locationsResults.map((_, i) => (
      <DetailedReport
        key={locations[i].key}
        details={locationsResults[i]}
        location={locations[i]}
        formReducer={formReducer}
        i={i}
      />
    ))}
    {/* <Parameters formReducer={formReducer} /> */}
    <Disclaimer />
    <BackCover />
  </PagesContainer>
);

const Report = (props) => {
  const [data, setData] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);

  React.useEffect(() => {
    fetchData();
    props.dispatch(toggleModal("exportImage"));
    document.querySelector(
      "#kepler-gl__map > div.ReactModalPortal"
    ).style.display = "none";
  }, []);

  const locations = props.formReducer.zoneSelectionData.locations.filter(
    (loc) =>
      loc.lat &&
      isFinite(loc.lat) &&
      Math.abs(loc.lat) <= 90 &&
      loc.lng &&
      isFinite(loc.lng) &&
      Math.abs(loc.lng) <= 180
  );

  const fetchData = async () => {
    for (const location of locations)
      await getLocationDetails(
        props,
        [parseFloat(location.lng), parseFloat(location.lat)],
        (newData) => setData((oldData) => [...oldData, newData])
      );
  };

  const downloadFile = (fileName, urlData) => {
    const aLink = document.createElement("a");
    aLink.download = fileName;
    aLink.href = urlData;

    const event = new MouseEvent("click");
    aLink.dispatchEvent(event);
  };

  const fetchCSV = async () => {
    for (const location of locations) {
      await getLocationDetails(
        props,
        [parseFloat(location.lng), parseFloat(location.lat)],
        (r) =>
          downloadFile(
            `${location.lat}_${location.lng}.csv`,
            "data:text/csv;charset=UTF-8," + encodeURIComponent(r.csv)
          ),
        true
      );
    }
  };

  if (data.length !== locations.length) return null;

  return (
    <>
      {isLoading && <SimpleSpinner />}
      <ExportButtonContainer>
        <Button variant="contained" color="secondary" onClick={props.close}>
          Close preview
        </Button>
        <Button
          variant="contained"
          onClick={fetchCSV}
          style={{
            backgroundColor: "#552382",
            color: "white",
            margin: "0 12px",
          }}
        >
          Export time series
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            props.dispatch({
              type: SET_API_LOADING_LOCATION_DATA,
              payload: true,
            });

            generatePDF(
              locations,
              data,
              props.formReducer,
              props.keplerReducer?.map?.uiState?.exportImage,
              () =>
                props.dispatch({
                  type: SET_API_LOADING_LOCATION_DATA,
                  payload: false,
                })
            );
            // async () => {
            // setLoading(true);
            // let doc = new jsPDF();
            // let i = 0;
            // await addElToPdf("pdf-front-cover", doc, i, true);
            // i++;
            // await addElToPdf("pdf-table-of-contents", doc, i);
            // i++;
            // await addElToPdf("pdf-introduction", doc, i);
            // i++;
            // await addElToPdf("pdf-overview", doc, i);
            // i++;
            // await addElToPdf("pdf-summary", doc, i);
            // i++;
            // await addElToPdf("pdf-results", doc, i);
            // i++;
            // for (let j in data) {
            //   if (
            //     data[j].wind_monthly_capacity_factor &&
            //     data[j].solar_monthly_capacity_factor
            //   ) {
            //     await addElToPdf(
            //       `pdf-detailed-report-1-${j}`,
            //       doc,
            //       i,
            //       false,
            //       true
            //     );
            //     i++;
            //     await addElToPdf(
            //       `pdf-detailed-report-2-${j}`,
            //       doc,
            //       i,
            //       false,
            //       true
            //     );
            //   } else {
            //     await addElToPdf(`pdf-detailed-report-${j}`, doc, i);
            //   }
            //   i++;
            // }
            // await addElToPdf("pdf-parameters", doc, i);
            // i++;
            // await addElToPdf("pdf-disclaimer", doc, i);
            // i++;
            // await addElToPdf("pdf-back-cover", doc, i);
            // doc.save("Wisogis report.pdf");
            // setLoading(false);
          }}
        >
          Download PDF
        </Button>
      </ExportButtonContainer>
      <PDFReport locationsResults={data} locations={locations} {...props} />
    </>
  );
};

const addElToPdf = async (id, doc, i, isFirst = false, leftPadding = false) => {
  if (!isFirst) {
    doc.addPage();
    doc.addImage(wisogisblue, "PNG", 8, 8, 48, 16);
  }
  var el = document.getElementById(id);
  let elCanvas = await html2canvas(el, {
    height: 3508,
    width: 2480,
    scale: 2,
  });
  let elImage = elCanvas.toDataURL("image/jpeg");
  doc.addImage(
    elImage,
    "JPEG",
    leftPadding ? 16 : 0,
    isFirst ? 0 : 36,
    620,
    877
  );

  const date = new Date();
  const FOOTER_CONTENT = `Wisogis simulation report - ${date.getDate()}-${
    date.getMonth() + 1
  }-${date.getFullYear()}`;
  doc.setFontSize(12);
  doc.setTextColor("#848484");
  doc.text(15, 280, FOOTER_CONTENT);
  doc.setTextColor("#adadad");
  doc.text(195, 280, `${i}`);
};

const mapStateToProps = (state) => {
  return {
    formReducer: state.formReducer,
    uiStateReducer: state.uiStateReducer,
    appReducer: state.appReducer,
    keplerReducer: state.keplerGl,
  };
};

const dispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, dispatchToProps)(Report);
