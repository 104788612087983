import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Content, PageContainer, StyledTableRow } from "./Common";

const Page5 = ({ locations, locationsResults }) => {
  const sortedResults = locationsResults
    .map((lr, i) => ({ ...lr, id: i + 1 }))
    .sort((a, b) => a.LCOE - b.LCOE);

  return (
    <PageContainer id="pdf-results">
      <Typography variant="h3" style={{ color: "#40adf8" }}>
        3. Simulation results
      </Typography>
      <Content>
        <Typography variant="h4">3.a. Results overview</Typography>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Ranking</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>NAME</TableCell>
                <TableCell align="right">LCOE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedResults.map((result, i) => (
                <StyledTableRow key={result.id}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>{result.id}</TableCell>
                  <TableCell>
                    {locations[i].name
                      ? locations[i].name
                      : `User choice ${i + 1}`}
                  </TableCell>
                  <TableCell align="right">
                    {result.LCOE.toFixed(2)}$/MWh
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Content>
    </PageContainer>
  );
};
export default Page5;
