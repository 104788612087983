export const getRenewableTechnologiesFormatted = (renewableTechFormData) => {
  const { solar, wind, combined_slider } = renewableTechFormData;
  const parameters = [
    {
      name: "Technologies selected:",
      selection: `Tracking: ${solar ? "Solar Power" : " "}${
        wind ? (solar ? ", Wind Power" : "Wind Power") : ""
      }`,
    },
  ];

  if (solar && wind) {
    const name = "Renewable mix optimization:";
    const selection = `${combined_slider * 100}% LCOE - ${
      100 - combined_slider * 100
    }% Production Stability`;
    parameters.push({ name, selection });
  }

  return parameters;
};

export const getAdvancedLCOEFormatted = (advancedLCOEFormData) => {
  const {
    grid_conection_type,
    grid_cost_per_km,
    lifetime,
    solar_capex,
    solar_opex,
    wacc,
    wind_capex,
    wind_opex,
    slope_cost_per_percent,
  } = advancedLCOEFormData;

  const parameters = [];

  parameters.push({
    name: "Wind CAPEX:",
    selection: wind_capex ? `${wind_capex} $/kW` : "DEFAULT",
  });
  parameters.push({
    name: "Solar CAPEX:",
    selection: solar_capex ? `${solar_capex} $/kW` : "DEFAULT",
  });
  parameters.push({
    name: "Wind OPEX:",
    selection: wind_opex ? `${wind_opex} $/kW/yr` : "DEFAULT",
  });
  parameters.push({
    name: "Solar OPEX:",
    selection: solar_opex ? `${solar_opex} $/kW/yr` : "DEFAULT",
  });
  parameters.push({
    name: "Wacc:",
    selection: wacc ? `${wacc} %` : "DEFAULT",
  });

  // if (solar_capex || solar_opex || wind_capex || wind_opex) {
  //   const name = "Financial expenditures";
  //   const selection = `Solar - CAPEX: ${solar_capex || "?"}$/kW - OPEX: ${
  //     solar_opex || "?"
  //   }$/kW/yr; Wind - CAPEX: ${wind_capex || "?"}$/kW - OPEX: ${
  //     wind_opex || "?"
  //   }$/kW/yr`;
  //   parameters.push({ name, selection });
  // }

  if (wacc || lifetime) {
    const name = "Financial expenditures";
    let selection = "";
    if (wacc && lifetime)
      selection = `WACC: $${wacc}; Lifetime: ${lifetime}yrs`;
    if (!wacc && lifetime) selection = `Lifetime: ${lifetime}yrs`;
    else selection = `WACC: $${wacc}`;
    parameters.push({ name, selection });
  }

  if (grid_conection_type || grid_cost_per_km.checked) {
    const name = "Grid Connection";
    let selection = "";
    if (grid_conection_type && grid_cost_per_km.checked)
      selection = `${grid_conection_type}; Cost: ${grid_cost_per_km.value}$/kW/km`;
    else if (!grid_conection_type && grid_cost_per_km.checked)
      selection = grid_cost_per_km.value
        ? `Cost: ${grid_cost_per_km.value}$/kW/km`
        : "DEFAULT";
    else selection = `${grid_conection_type}`;
    parameters.push({ name, selection });
  }
  if (slope_cost_per_percent.checked) {
    const name = "Site Grading";
    const selection = slope_cost_per_percent.value
      ? `Cost: ${slope_cost_per_percent.value}$/kW/%`
      : "DEFAULT";
    parameters.push({ name, selection });
  }

  return parameters;
};

export const getExclusionAreasFormatted = (exclusionAreasFormData) => {
  const {
    water,
    wetland,
    is_protected_area,
    protected_area,
    is_urban,
    urban,
    is_forest,
    forest,
    ui_show_complex_terrain,
    complex_terrain,
    is_airports,
    airports,
  } = exclusionAreasFormData;

  const parameters = [];

  if (water) {
    parameters.push({ name: "Water", selection: "On" });
  }
  if (wetland) {
    parameters.push({ name: "Wetland", selection: "On" });
  }
  if (is_protected_area) {
    parameters.push({
      name: "Protected Areas:",
      selection: protected_area ? `${protected_area}m buffer` : "Default",
    });
  }
  if (is_urban) {
    parameters.push({
      name: "Residential Areas:",
      selection: urban ? `${urban}m buffer` : "Default",
    });
  }
  if (is_forest) {
    parameters.push({
      name: "Forests:",
      selection: forest ? `${forest}m buffer` : "Default",
    });
  }
  if (ui_show_complex_terrain) {
    parameters.push({
      name: "Local terrain slope:",
      selection: complex_terrain ? `${complex_terrain}° slope` : "Default",
    });
  }
  if (is_airports) {
    parameters.push({
      name: "Airports:",
      selection: airports ? `${airports}m buffer` : "Default",
    });
  }

  return parameters;
};

const getSimulationParameters = (
  renewableTechFormData,
  advancedLCOEFormData
) => {
  const { solar, wind, combined_slider } = renewableTechFormData;
  const {
    grid_conection_type,
    grid_cost_per_km,
    lifetime,
    solar_capex,
    solar_opex,
    wacc,
    wind_capex,
    wind_opex,
    slope_cost_per_percent,
  } = advancedLCOEFormData;
  const parameters = [
    { name: "Zone Selection", selection: "My Locations" },
    {
      name: "Renewable Technologies",
      selection: `Tracking: ${solar ? "Solar Power" : " "}${
        wind ? (solar ? ", Wind Power" : "Wind Power") : ""
      }`,
    },
  ];

  if (solar && wind) {
    const name = "Renewable Sizing";
    const selection = `Optimisation based on ${
      combined_slider * 100
    }% LCOE and ${100 - combined_slider * 100}% Production Stability`;
    parameters.push({ name, selection });
  }

  if (solar_capex || solar_opex || wind_capex || wind_opex) {
    const name = "Selected financial expenditures";
    let selection = `Solar - CAPEX: ${
      solar_capex ? solar_capex + "$/kW" : "DEFAULT"
    } - OPEX: ${
      solar_opex ? solar_opex + "$/kW/yr" : "DEFAULT"
    }; Wind - CAPEX: ${wind_capex ? wind_capex + "$/kW" : "DEFAULT"} - OPEX: ${
      wind_opex ? wind_capex + "$/kW/yr" : "DEFAULT"
    }`;

    parameters.push({ name, selection });
  }
  if (wacc || lifetime) {
    const name = "Financial expenditures";
    let selection = "";
    if (wacc && lifetime) selection = `WACC: ${wacc}; Lifetime: ${lifetime}yrs`;
    if (!wacc && lifetime) selection = `Lifetime: ${lifetime}yrs`;
    else selection = `WACC: ${wacc}`;
    parameters.push({ name, selection });
  }
  if (grid_conection_type || grid_cost_per_km.checked) {
    const name = "Grid Connection";
    let selection = "";
    if (grid_conection_type && grid_cost_per_km.checked)
      selection = `${grid_conection_type}; Cost: ${grid_cost_per_km.value}$/kW/km`;
    else if (!grid_conection_type && grid_cost_per_km.checked)
      selection = `Cost: ${grid_cost_per_km.value}$/kW/km`;
    else selection = `${grid_conection_type}`;
    parameters.push({ name, selection });
  }
  if (slope_cost_per_percent.checked) {
    const name = "Site Grading";
    const selection = `Cost : ${slope_cost_per_percent.value}$/kW/%`;
    parameters.push({ name, selection });
  }
  return parameters;
};

export default getSimulationParameters;
