import { Icons } from "kepler.gl/components";
import { toggleSidePanel } from "kepler.gl/actions";
import styled from "styled-components";
import store from "../store";

const StyledCollapseButton = styled.div`
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  justify-content: center;
  background-color: ${(props) => props.theme.sideBarCloseBtnBgd};
  border-radius: 1px;
  color: ${(props) => props.theme.sideBarCloseBtnColor};
  display: flex;
  height: 20px;
  position: absolute;
  right: -8px;
  top: ${(props) => props.theme.sidePanel.margin.top}px;
  width: 20px;
  :hover {
    cursor: pointer;
    box-shadow: none;
    background-color: ${(props) => props.theme.sideBarCloseBtnBgdHover};
  }
`;

export const CustomCollapseButton = () => {
  const CollapseButton = ({ onClick, isOpen }) => (
    <StyledCollapseButton
      className="side-bar__close"
      onClick={isOpen ? onClick : () => store.dispatch(toggleSidePanel("zone"))}
    >
      <Icons.ArrowRight
        height="12px"
        style={{ transform: `rotate(${isOpen ? 180 : 0}deg)` }}
      />
    </StyledCollapseButton>
  );
  return CollapseButton;
};

export default CustomCollapseButton;
