import { toggleSidePanel } from "kepler.gl/actions";
import { Icons } from "kepler.gl/components";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Typography } from "@material-ui/core";
import windIcon from "../../assets/wind-energy.svg";
import solarIcon from "../../assets/solar-panel.svg";
import HelpText from "../HelpText";
import "./styles.scss";
import tractebelGrey from "../../assets/tractebel-grey.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(1),
    color: "#000000",
  },
  radio: {
    marginLeft: "20px",
  },
  radioLabel: {
    fontSize: "16px",
  },
}));

const getHelpText = (field, min, max) => {
  if (field === "") return "";
  else if (isNaN(field)) return "Invalid input (must be a number)";
  else if (field < 0) return "Invalid input (must be positive)";
  else if (field < min || field > max)
    return `Recommended range: between ${min} and ${max}`;
  else return "";
};

const getHelperStyles = (field, min, max) =>
  field > -1 && (field < min || field > max) ? "#FFCC00" : "red";

export const AdvancedLCOEPanelComp = (props) => {
  const classes = useStyles();
  const handleChangeCheckBoxRedux = (event) => {
    props.dispatch({
      type: "SET_FORM_ADVANCED_LCOE",
      payload: {
        [event.target.name]: {
          checked: event.target.checked,
          value:
            props.formReducer.advancedLCOEFormData[event.target.name].value,
        },
      },
    });
  };
  const handleChangeTextInputRedux = (event) => {
    props.dispatch({
      type: "SET_FORM_ADVANCED_LCOE",
      payload: { [event.target.id]: event.target.value },
    });
  };
  const handleChangeTextInputUnderCheckboxRedux = (event) => {
    props.dispatch({
      type: "SET_FORM_ADVANCED_LCOE",
      payload: {
        [event.target.name]: {
          checked:
            props.formReducer.advancedLCOEFormData[event.target.name].checked,
          value: event.target.value,
        },
      },
    });
  };
  const handleChangeRadioRedux = (event) => {
    props.dispatch({
      type: "SET_FORM_ADVANCED_LCOE",
      payload: { [event.target.name]: event.target.value },
    });
  };

  const {
    grid_cost_per_km,
    slope_cost_per_percent,
    wind_capex,
    solar_capex,
    wind_opex,
    solar_opex,
    wacc,
    lifetime,
  } = props.formReducer.advancedLCOEFormData;

  return (
    <div
      className="advanced-lcoe-panel-container"
      style={{ display: "flex", flexDirection: "column", flex: 1 }}
    >
      <div
        className="advanced-lcoe-panel"
        style={{ display: "flex", flexDirection: "column", flex: 1 }}
      >
        <HelpText element="Advanced LCOE">
          <div className="criteria-label">
            Select the following criteria to take into account
          </div>
        </HelpText>
        <div id="criteria-div">
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              <HelpText element="Grid Connection">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={grid_cost_per_km.checked}
                      onChange={handleChangeCheckBoxRedux}
                      name="grid_cost_per_km"
                      disableRipple
                      style={{ color: "#009de9" }}
                    />
                  }
                  label="Grid connection"
                />
              </HelpText>
              <RadioGroup
                style={
                  grid_cost_per_km.checked === true
                    ? { display: "block" }
                    : { display: "none" }
                }
                aria-label="gridcost"
                name="grid_connection_type"
                value={
                  props.formReducer.advancedLCOEFormData.grid_connection_type
                }
                onChange={handleChangeRadioRedux}
              >
                <FormControlLabel
                  className={classes.radioLabel}
                  value="substation"
                  control={
                    <Radio
                      className={classes.radio}
                      style={{ color: "#009de9" }}
                    />
                  }
                  label="Substation"
                />
                <FormControlLabel
                  className={classes.radioLabel}
                  value="hv_line"
                  control={
                    <Radio
                      className={classes.radio}
                      style={{ color: "#009de9" }}
                    />
                  }
                  label="HV Line"
                />
              </RadioGroup>
              <div
                style={
                  grid_cost_per_km.checked === true
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <TextField
                  name="grid_cost_per_km"
                  value={grid_cost_per_km.value}
                  id="gridcost-text"
                  helperText={getHelpText(grid_cost_per_km.value, 1, 100)}
                  error={!!getHelpText(grid_cost_per_km.value, 1, 100)}
                  FormHelperTextProps={{
                    style: {
                      color: getHelperStyles(grid_cost_per_km.value, 1, 100),
                    },
                  }}
                  placeholder="default"
                  onChange={handleChangeTextInputUnderCheckboxRedux}
                />
                <Typography>[$/kW/km]</Typography>
              </div>

              <HelpText element="Site grading of the PV plant">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={slope_cost_per_percent.checked}
                      onChange={handleChangeCheckBoxRedux}
                      name="slope_cost_per_percent"
                      disableRipple
                      style={{ color: "#009de9" }}
                    />
                  }
                  label="Local terrain slope"
                />
              </HelpText>
              <div
                style={
                  slope_cost_per_percent.checked === true
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <TextField
                  name="slope_cost_per_percent"
                  value={slope_cost_per_percent.value}
                  helperText={getHelpText(slope_cost_per_percent.value, 1, 100)}
                  error={!!getHelpText(slope_cost_per_percent.value, 1, 100)}
                  FormHelperTextProps={{
                    style: {
                      color: getHelperStyles(
                        slope_cost_per_percent.value,
                        1,
                        100
                      ),
                    },
                  }}
                  id="siteaccess-text"
                  placeholder="default"
                  onChange={handleChangeTextInputUnderCheckboxRedux}
                />
                <Typography>[$/kW/%slope]</Typography>
              </div>
            </FormGroup>
          </FormControl>
        </div>

        <HelpText element="CAPEX">
          <div id="capex-label">
            <p>Enter your personalized inputs</p>
            <p>CAPEX [$/kW]</p>
          </div>
        </HelpText>

        <div id="capex-div">
          {props.formReducer.renewableTechFormData.wind === true ? (
            <>
              <img
                className="icon"
                src={windIcon}
                alt="wind turbine icon"
              ></img>
              <TextField
                name="wind_capex"
                value={wind_capex}
                id="wind_capex"
                placeholder="default"
                helperText={getHelpText(wind_capex, 500, 3000)}
                error={!!getHelpText(wind_capex, 500, 3000)}
                FormHelperTextProps={{
                  style: {
                    color: getHelperStyles(wind_capex, 500, 3000),
                  },
                }}
                onChange={handleChangeTextInputRedux}
              />
            </>
          ) : null}
          {props.formReducer.renewableTechFormData.solar === true ? (
            <>
              <img
                className="icon"
                src={solarIcon}
                alt="wind turbine icon"
              ></img>
              <TextField
                value={solar_capex}
                name="solar_capex"
                id="solar_capex"
                placeholder="default"
                helperText={getHelpText(solar_capex, 500, 3000)}
                error={!!getHelpText(solar_capex, 500, 3000)}
                FormHelperTextProps={{
                  style: {
                    color: getHelperStyles(solar_capex, 500, 3000),
                  },
                }}
                onChange={handleChangeTextInputRedux}
              />
            </>
          ) : null}
        </div>

        <HelpText element="OPEX">
          <div id="opex-label">OPEX [$/kW/year]</div>
        </HelpText>

        <div id="opex-div">
          {props.formReducer.renewableTechFormData.wind === true ? (
            <>
              <img
                className="icon"
                src={windIcon}
                alt="wind turbine icon"
              ></img>
              <TextField
                value={wind_opex}
                name="wind_opex"
                id="wind_opex"
                placeholder="default"
                helperText={getHelpText(wind_opex, 5, 100)}
                error={!!getHelpText(wind_opex, 5, 100)}
                FormHelperTextProps={{
                  style: {
                    color: getHelperStyles(wind_opex, 5, 100),
                  },
                }}
                onChange={handleChangeTextInputRedux}
              />
            </>
          ) : null}
          {props.formReducer.renewableTechFormData.solar === true ? (
            <>
              <img
                className="icon"
                src={solarIcon}
                alt="solar panel icon"
              ></img>
              <TextField
                value={props.formReducer.advancedLCOEFormData.solar_opex}
                name="solar_opex"
                id="solar_opex"
                placeholder="default"
                helperText={getHelpText(solar_opex, 5, 100)}
                error={!!getHelpText(solar_opex, 5, 100)}
                FormHelperTextProps={{
                  style: {
                    color: getHelperStyles(solar_opex, 5, 100),
                  },
                }}
                onChange={handleChangeTextInputRedux}
              />
            </>
          ) : null}
        </div>

        <div id="capex-label">
          <p>Others</p>
          <div style={{ display: "flex" }}>
            <HelpText element="WACC">
              <span style={{ minWidth: "50%" }}>Wacc</span>
            </HelpText>
            <HelpText element="Lifetime">
              <span>Lifetime [year]</span>
            </HelpText>
          </div>
        </div>
        <div id="capex-div">
          <Typography>%</Typography>
          <TextField
            value={props.formReducer.advancedLCOEFormData.wacc}
            name="wacc"
            id="wacc"
            placeholder="default"
            helperText={getHelpText(wacc, 1, 20)}
            error={!!getHelpText(wacc, 1, 20)}
            FormHelperTextProps={{
              style: {
                color: getHelperStyles(wacc, 1, 20),
              },
            }}
            onChange={handleChangeTextInputRedux}
          />
          <Icons.Clock />
          <TextField
            value={props.formReducer.advancedLCOEFormData.lifetime}
            name="lifetime"
            id="lifetime"
            placeholder="default"
            helperText={getHelpText(lifetime, 5, 50)}
            error={!!getHelpText(lifetime, 5, 50)}
            FormHelperTextProps={{
              style: {
                color: getHelperStyles(lifetime, 5, 50),
              },
            }}
            onChange={handleChangeTextInputRedux}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          onClick={() => props.dispatch(toggleSidePanel("renewable-sizing"))}
          className="previous-button"
        >
          Previous
        </div>
        <div
          onClick={() => props.dispatch(toggleSidePanel("exclusion"))}
          className="run-button"
        >
          Next
        </div>
      </div>
      <div style={{ padding: "14px 30px", textAlign: "center" }}>
        <img src={tractebelGrey} style={{ maxWidth: "50%" }} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    formReducer: state.formReducer,
    uiStateReducer: state.uiStateReducer,
    appReducer: state.appReducer,
    keplerReducer: state.keplerGl,
  };
};
const dispatchToProps = (dispatch) => ({ dispatch });

export const AdvancedLCOEPanel = connect(
  mapStateToProps,
  dispatchToProps
)(AdvancedLCOEPanelComp);
