import { useState } from "react";
import { connect } from "react-redux";
import { mapConfigChange, toggleMapControl } from "kepler.gl/actions";
import visibleIcon from "../../../assets/visibility.svg";
import notVisibleIcon from "../../../assets/visibility_off.svg";
import ReactTooltip from "react-tooltip";
import "./style.scss";

const toggleLayer = (layerType, currentLayers, dispatch) => {
  const currentConfig = { ...currentLayers };
  currentConfig[layerType] = !currentLayers[layerType];
  dispatch(mapConfigChange({ visibleLayerGroups: currentConfig }));
};

function LayerSelector(props) {
  // State used to hide tooltip when submenu is open
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleLayerSelection = () => {
    const layerOptions = document.querySelector(".layer-options");
    const maplayerOptions = document.querySelector(".maplayer-options");
    const styleOptions = document.querySelector(".style-options");

    maplayerOptions.style.visibility = "hidden";
    styleOptions.style.visibility = "hidden";

    const legendOptions = document.querySelector(".close-map-control-item");
    if (legendOptions) props.dispatch(toggleMapControl("mapLegend"));

    if (layerOptions.style.visibility === "hidden") {
      layerOptions.style.visibility = "visible";
      setIsMenuVisible(true);
    } else {
      layerOptions.style.visibility = "hidden";
      setIsMenuVisible(false);
    }
  };

  let label, road, border, water, building3d;

  if (props.map) {
    label = props.map.mapStyle.visibleLayerGroups.label;
    road = props.map.mapStyle.visibleLayerGroups.road;
    border = props.map.mapStyle.visibleLayerGroups.border;
    water = props.map.mapStyle.visibleLayerGroups.water;
    building3d = props.map.mapStyle.visibleLayerGroups["3d building"];
  }
  return (
    <div
      data-tip
      data-for="layer-selector-tip"
      className="custom-layer-selector"
    >
      <div onClick={() => toggleLayerSelection()} className="svg-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="22px"
          viewBox="0 0 24 24"
          width="22px"
        >
          <g>
            <rect fill="none" height="24" width="24" />
          </g>
          <g>
            <g />
            <g>
              <path
                fill="#6A7485"
                d="M20.57,10.66C20.43,10.26,20.05,10,19.6,10h-7.19c-0.46,0-0.83,0.26-0.98,0.66L10,14.77l0.01,5.51 c0,0.38,0.31,0.72,0.69,0.72h0.62C11.7,21,12,20.62,12,20.24V19h8v1.24c0,0.38,0.31,0.76,0.69,0.76h0.61 c0.38,0,0.69-0.34,0.69-0.72L22,18.91v-4.14L20.57,10.66z M12.41,11h7.19l1.03,3h-9.25L12.41,11z M12,17c-0.55,0-1-0.45-1-1 s0.45-1,1-1s1,0.45,1,1S12.55,17,12,17z M20,17c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S20.55,17,20,17z"
              />
              <polygon
                fill="#6A7485"
                points="14,9 15,9 15,3 7,3 7,8 2,8 2,21 3,21 3,9 8,9 8,4 14,4"
              />
              <rect fill="#6A7485" height="2" width="2" x="5" y="11" />
              <rect fill="#6A7485" height="2" width="2" x="10" y="5" />
              <rect fill="#6A7485" height="2" width="2" x="5" y="15" />
              <rect fill="#6A7485" height="2" width="2" x="5" y="19" />
            </g>
          </g>
        </svg>
      </div>
      <ReactTooltip
        disable={isMenuVisible}
        id="layer-selector-tip"
        type="info"
        effect="solid"
        className="custom-tooltip-class"
        arrowColor="#1869b5"
      >
        <span>Map visuals</span>
      </ReactTooltip>
      <div style={{ visibility: "hidden" }} className="layer-options">
        <div
          onClick={() =>
            toggleLayer(
              "label",
              props.map.mapStyle.visibleLayerGroups,
              props.dispatch
            )
          }
          className="layer-option"
        >
          <img
            src={label === true ? visibleIcon : notVisibleIcon}
            alt="visible"
          ></img>
          <div>Label</div>
        </div>
        <div
          onClick={() =>
            toggleLayer(
              "road",
              props.map.mapStyle.visibleLayerGroups,
              props.dispatch
            )
          }
          className="layer-option"
        >
          <img
            src={road === true ? visibleIcon : notVisibleIcon}
            alt="visible"
          ></img>
          <div>Road</div>
        </div>
        <div
          onClick={() =>
            toggleLayer(
              "border",
              props.map.mapStyle.visibleLayerGroups,
              props.dispatch
            )
          }
          className="layer-option"
        >
          <img
            src={border === true ? visibleIcon : notVisibleIcon}
            alt="visible"
          ></img>
          <div>Border</div>
        </div>
        <div
          onClick={() =>
            toggleLayer(
              "water",
              props.map.mapStyle.visibleLayerGroups,
              props.dispatch
            )
          }
          className="layer-option"
        >
          <img
            src={water === true ? visibleIcon : notVisibleIcon}
            alt="visible"
          ></img>
          <div>Water</div>
        </div>
        <div
          onClick={() =>
            toggleLayer(
              "3d building",
              props.map.mapStyle.visibleLayerGroups,
              props.dispatch
            )
          }
          className="layer-option"
        >
          <img
            src={building3d === true ? visibleIcon : notVisibleIcon}
            alt="visible"
          ></img>
          <div>3D Building</div>
        </div>
      </div>
    </div>
  );
}

// label, road, border, 3d building, water, land

const dispatchToProps = (dispatch) => ({ dispatch });
const mapStateToProps = (state) => state.keplerGl;

export default connect(mapStateToProps, dispatchToProps)(LayerSelector);
