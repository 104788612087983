import { SimulationServiceServiceException as __BaseException } from "../models/SimulationServiceServiceException";
import { BadRequest, InvalidCoordinates, InvalidState, NoSuchResource, ValidationException, } from "../models/models_0";
import { HttpRequest as __HttpRequest, } from "@aws-sdk/protocol-http";
import { decorateServiceException as __decorateServiceException, expectBoolean as __expectBoolean, expectInt32 as __expectInt32, expectNonNull as __expectNonNull, expectObject as __expectObject, expectString as __expectString, limitedParseDouble as __limitedParseDouble, map as __map, parseRfc3339DateTimeWithOffset as __parseRfc3339DateTimeWithOffset, resolvedPath as __resolvedPath, serializeFloat as __serializeFloat, throwDefaultError, } from "@aws-sdk/smithy-client";
export const serializeAws_restJson1CreateSimulationCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {
        'content-type': 'application/json',
    };
    let resolvedPath = `${basePath?.endsWith('/') ? basePath.slice(0, -1) : (basePath || '')}` + "/simulation/{uid}";
    resolvedPath = __resolvedPath(resolvedPath, input, 'uid', () => input.uid, '{uid}', false);
    let body;
    body = JSON.stringify({
        ...(input.name != null && { "name": input.name }),
        ...(input.parameters != null && { "parameters": serializeAws_restJson1SimulationParameters(input.parameters, context) }),
        ...(input.zone != null && { "zone": serializeAws_restJson1GeoJSON(input.zone, context) }),
    });
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        body,
    });
};
export const serializeAws_restJson1DeleteSimulationCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    let resolvedPath = `${basePath?.endsWith('/') ? basePath.slice(0, -1) : (basePath || '')}` + "/simulation/{uid}/{id}";
    resolvedPath = __resolvedPath(resolvedPath, input, 'uid', () => input.uid, '{uid}', false);
    resolvedPath = __resolvedPath(resolvedPath, input, 'id', () => input.id, '{id}', false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "DELETE",
        headers,
        path: resolvedPath,
        body,
    });
};
export const serializeAws_restJson1GetLocationDetailsCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {
        'content-type': 'application/json',
    };
    let resolvedPath = `${basePath?.endsWith('/') ? basePath.slice(0, -1) : (basePath || '')}` + "/location/{uid}";
    resolvedPath = __resolvedPath(resolvedPath, input, 'uid', () => input.uid, '{uid}', false);
    const query = map({
        "lat": [__expectNonNull(input.lat, `lat`) != null, () => ((input.lat % 1 == 0) ? input.lat + ".0" : input.lat.toString())],
        "lon": [__expectNonNull(input.lon, `lon`) != null, () => ((input.lon % 1 == 0) ? input.lon + ".0" : input.lon.toString())],
    });
    let body;
    body = JSON.stringify({
        ...(input.parameters != null && { "parameters": serializeAws_restJson1SimulationParameters(input.parameters, context) }),
    });
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const serializeAws_restJson1GetLocationDetailsFromSimulationCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    let resolvedPath = `${basePath?.endsWith('/') ? basePath.slice(0, -1) : (basePath || '')}` + "/simulation/{uid}/{id}/location";
    resolvedPath = __resolvedPath(resolvedPath, input, 'uid', () => input.uid, '{uid}', false);
    resolvedPath = __resolvedPath(resolvedPath, input, 'id', () => input.id, '{id}', false);
    const query = map({
        "lat": [__expectNonNull(input.lat, `lat`) != null, () => ((input.lat % 1 == 0) ? input.lat + ".0" : input.lat.toString())],
        "lon": [__expectNonNull(input.lon, `lon`) != null, () => ((input.lon % 1 == 0) ? input.lon + ".0" : input.lon.toString())],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const serializeAws_restJson1GetSimulationCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    let resolvedPath = `${basePath?.endsWith('/') ? basePath.slice(0, -1) : (basePath || '')}` + "/simulation/{uid}/{id}";
    resolvedPath = __resolvedPath(resolvedPath, input, 'uid', () => input.uid, '{uid}', false);
    resolvedPath = __resolvedPath(resolvedPath, input, 'id', () => input.id, '{id}', false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        body,
    });
};
export const serializeAws_restJson1GetSimulationResultsCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    let resolvedPath = `${basePath?.endsWith('/') ? basePath.slice(0, -1) : (basePath || '')}` + "/simulation/{uid}/{id}/results";
    resolvedPath = __resolvedPath(resolvedPath, input, 'uid', () => input.uid, '{uid}', false);
    resolvedPath = __resolvedPath(resolvedPath, input, 'id', () => input.id, '{id}', false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        body,
    });
};
export const serializeAws_restJson1ListSimulationsCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    let resolvedPath = `${basePath?.endsWith('/') ? basePath.slice(0, -1) : (basePath || '')}` + "/simulations/{uid}";
    resolvedPath = __resolvedPath(resolvedPath, input, 'uid', () => input.uid, '{uid}', false);
    const query = map({
        "nextToken": [, input.nextToken],
        "pageSize": [__expectNonNull(input.pageSize, `pageSize`) != null, () => input.pageSize.toString()],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const serializeAws_restJson1RenameSimulationCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {
        'content-type': 'application/json',
    };
    let resolvedPath = `${basePath?.endsWith('/') ? basePath.slice(0, -1) : (basePath || '')}` + "/simulation/{uid}/{id}/rename";
    resolvedPath = __resolvedPath(resolvedPath, input, 'uid', () => input.uid, '{uid}', false);
    resolvedPath = __resolvedPath(resolvedPath, input, 'id', () => input.id, '{id}', false);
    let body;
    body = JSON.stringify({
        ...(input.name != null && { "name": input.name }),
    });
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        body,
    });
};
export const serializeAws_restJson1StartSimulationCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    let resolvedPath = `${basePath?.endsWith('/') ? basePath.slice(0, -1) : (basePath || '')}` + "/simulation/{uid}/{id}/start";
    resolvedPath = __resolvedPath(resolvedPath, input, 'uid', () => input.uid, '{uid}', false);
    resolvedPath = __resolvedPath(resolvedPath, input, 'id', () => input.id, '{id}', false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        body,
    });
};
export const deserializeAws_restJson1CreateSimulationCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1CreateSimulationCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull((__expectObject(await parseBody(output.body, context))), "body");
    if (data.dates != null) {
        contents.dates = deserializeAws_restJson1SimulationDate(data.dates, context);
    }
    if (data.id != null) {
        contents.id = __expectString(data.id);
    }
    if (data.name != null) {
        contents.name = __expectString(data.name);
    }
    if (data.parameters != null) {
        contents.parameters = deserializeAws_restJson1SimulationParameters(data.parameters, context);
    }
    if (data.state != null) {
        contents.state = __expectString(data.state);
    }
    if (data.uid != null) {
        contents.uid = __expectString(data.uid);
    }
    return contents;
};
const deserializeAws_restJson1CreateSimulationCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context)
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequest":
        case "wisogis.common#BadRequest":
            throw await deserializeAws_restJson1BadRequestResponse(parsedOutput, context);
        case "InvalidCoordinates":
        case "wisogis.common#InvalidCoordinates":
            throw await deserializeAws_restJson1InvalidCoordinatesResponse(parsedOutput, context);
        case "NoSuchResource":
        case "wisogis.common#NoSuchResource":
            throw await deserializeAws_restJson1NoSuchResourceResponse(parsedOutput, context);
        case "ValidationException":
        case "smithy.framework#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode
            });
    }
};
export const deserializeAws_restJson1DeleteSimulationCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1DeleteSimulationCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull((__expectObject(await parseBody(output.body, context))), "body");
    if (data.dates != null) {
        contents.dates = deserializeAws_restJson1SimulationDate(data.dates, context);
    }
    if (data.id != null) {
        contents.id = __expectString(data.id);
    }
    if (data.name != null) {
        contents.name = __expectString(data.name);
    }
    if (data.parameters != null) {
        contents.parameters = deserializeAws_restJson1SimulationParameters(data.parameters, context);
    }
    if (data.state != null) {
        contents.state = __expectString(data.state);
    }
    if (data.uid != null) {
        contents.uid = __expectString(data.uid);
    }
    return contents;
};
const deserializeAws_restJson1DeleteSimulationCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context)
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequest":
        case "wisogis.common#BadRequest":
            throw await deserializeAws_restJson1BadRequestResponse(parsedOutput, context);
        case "InvalidCoordinates":
        case "wisogis.common#InvalidCoordinates":
            throw await deserializeAws_restJson1InvalidCoordinatesResponse(parsedOutput, context);
        case "InvalidState":
        case "wisogis.common#InvalidState":
            throw await deserializeAws_restJson1InvalidStateResponse(parsedOutput, context);
        case "NoSuchResource":
        case "wisogis.common#NoSuchResource":
            throw await deserializeAws_restJson1NoSuchResourceResponse(parsedOutput, context);
        case "ValidationException":
        case "smithy.framework#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode
            });
    }
};
export const deserializeAws_restJson1GetLocationDetailsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1GetLocationDetailsCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull((__expectObject(await parseBody(output.body, context))), "body");
    if (data.average_capacity_factor != null) {
        contents.average_capacity_factor = __limitedParseDouble(data.average_capacity_factor);
    }
    if (data.complex_terrain != null) {
        contents.complex_terrain = __expectBoolean(data.complex_terrain);
    }
    if (data.country != null) {
        contents.country = __expectString(data.country);
    }
    if (data.csv != null) {
        contents.csv = __expectString(data.csv);
    }
    if (data.electricity_price != null) {
        contents.electricity_price = __limitedParseDouble(data.electricity_price);
    }
    if (data.electricity_ranking != null) {
        contents.electricity_ranking = __expectInt32(data.electricity_ranking);
    }
    if (data.ghi != null) {
        contents.ghi = __limitedParseDouble(data.ghi);
    }
    if (data.grid_connection_time != null) {
        contents.grid_connection_time = __expectInt32(data.grid_connection_time);
    }
    if (data.grid_distance != null) {
        contents.grid_distance = __limitedParseDouble(data.grid_distance);
    }
    if (data.lcoe != null) {
        contents.lcoe = __limitedParseDouble(data.lcoe);
    }
    if (data.mix != null) {
        contents.mix = __limitedParseDouble(data.mix);
    }
    if (data.slope != null) {
        contents.slope = __limitedParseDouble(data.slope);
    }
    if (data.solar_capacity_factor != null) {
        contents.solar_capacity_factor = __limitedParseDouble(data.solar_capacity_factor);
    }
    if (data.solar_capex != null) {
        contents.solar_capex = __limitedParseDouble(data.solar_capex);
    }
    if (data.solar_hourly_capacity_factor != null) {
        contents.solar_hourly_capacity_factor = deserializeAws_restJson1ValueList(data.solar_hourly_capacity_factor, context);
    }
    if (data.solar_monthly_capacity_factor != null) {
        contents.solar_monthly_capacity_factor = deserializeAws_restJson1ValueList(data.solar_monthly_capacity_factor, context);
    }
    if (data.solar_opex != null) {
        contents.solar_opex = __limitedParseDouble(data.solar_opex);
    }
    if (data.temperature != null) {
        contents.temperature = __limitedParseDouble(data.temperature);
    }
    if (data.wind_capacity_factor != null) {
        contents.wind_capacity_factor = __limitedParseDouble(data.wind_capacity_factor);
    }
    if (data.wind_capex != null) {
        contents.wind_capex = __limitedParseDouble(data.wind_capex);
    }
    if (data.wind_hourly_capacity_factor != null) {
        contents.wind_hourly_capacity_factor = deserializeAws_restJson1ValueList(data.wind_hourly_capacity_factor, context);
    }
    if (data.wind_monthly_capacity_factor != null) {
        contents.wind_monthly_capacity_factor = deserializeAws_restJson1ValueList(data.wind_monthly_capacity_factor, context);
    }
    if (data.wind_opex != null) {
        contents.wind_opex = __limitedParseDouble(data.wind_opex);
    }
    if (data.wind_speed != null) {
        contents.wind_speed = __limitedParseDouble(data.wind_speed);
    }
    return contents;
};
const deserializeAws_restJson1GetLocationDetailsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context)
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequest":
        case "wisogis.common#BadRequest":
            throw await deserializeAws_restJson1BadRequestResponse(parsedOutput, context);
        case "InvalidCoordinates":
        case "wisogis.common#InvalidCoordinates":
            throw await deserializeAws_restJson1InvalidCoordinatesResponse(parsedOutput, context);
        case "ValidationException":
        case "smithy.framework#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode
            });
    }
};
export const deserializeAws_restJson1GetLocationDetailsFromSimulationCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1GetLocationDetailsFromSimulationCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull((__expectObject(await parseBody(output.body, context))), "body");
    if (data.average_capacity_factor != null) {
        contents.average_capacity_factor = __limitedParseDouble(data.average_capacity_factor);
    }
    if (data.complex_terrain != null) {
        contents.complex_terrain = __expectBoolean(data.complex_terrain);
    }
    if (data.country != null) {
        contents.country = __expectString(data.country);
    }
    if (data.csv != null) {
        contents.csv = __expectString(data.csv);
    }
    if (data.electricity_price != null) {
        contents.electricity_price = __limitedParseDouble(data.electricity_price);
    }
    if (data.electricity_ranking != null) {
        contents.electricity_ranking = __expectInt32(data.electricity_ranking);
    }
    if (data.ghi != null) {
        contents.ghi = __limitedParseDouble(data.ghi);
    }
    if (data.grid_connection_time != null) {
        contents.grid_connection_time = __expectInt32(data.grid_connection_time);
    }
    if (data.grid_distance != null) {
        contents.grid_distance = __limitedParseDouble(data.grid_distance);
    }
    if (data.lcoe != null) {
        contents.lcoe = __limitedParseDouble(data.lcoe);
    }
    if (data.mix != null) {
        contents.mix = __limitedParseDouble(data.mix);
    }
    if (data.slope != null) {
        contents.slope = __limitedParseDouble(data.slope);
    }
    if (data.solar_capacity_factor != null) {
        contents.solar_capacity_factor = __limitedParseDouble(data.solar_capacity_factor);
    }
    if (data.solar_capex != null) {
        contents.solar_capex = __limitedParseDouble(data.solar_capex);
    }
    if (data.solar_hourly_capacity_factor != null) {
        contents.solar_hourly_capacity_factor = deserializeAws_restJson1ValueList(data.solar_hourly_capacity_factor, context);
    }
    if (data.solar_monthly_capacity_factor != null) {
        contents.solar_monthly_capacity_factor = deserializeAws_restJson1ValueList(data.solar_monthly_capacity_factor, context);
    }
    if (data.solar_opex != null) {
        contents.solar_opex = __limitedParseDouble(data.solar_opex);
    }
    if (data.temperature != null) {
        contents.temperature = __limitedParseDouble(data.temperature);
    }
    if (data.wind_capacity_factor != null) {
        contents.wind_capacity_factor = __limitedParseDouble(data.wind_capacity_factor);
    }
    if (data.wind_capex != null) {
        contents.wind_capex = __limitedParseDouble(data.wind_capex);
    }
    if (data.wind_hourly_capacity_factor != null) {
        contents.wind_hourly_capacity_factor = deserializeAws_restJson1ValueList(data.wind_hourly_capacity_factor, context);
    }
    if (data.wind_monthly_capacity_factor != null) {
        contents.wind_monthly_capacity_factor = deserializeAws_restJson1ValueList(data.wind_monthly_capacity_factor, context);
    }
    if (data.wind_opex != null) {
        contents.wind_opex = __limitedParseDouble(data.wind_opex);
    }
    if (data.wind_speed != null) {
        contents.wind_speed = __limitedParseDouble(data.wind_speed);
    }
    return contents;
};
const deserializeAws_restJson1GetLocationDetailsFromSimulationCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context)
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequest":
        case "wisogis.common#BadRequest":
            throw await deserializeAws_restJson1BadRequestResponse(parsedOutput, context);
        case "InvalidCoordinates":
        case "wisogis.common#InvalidCoordinates":
            throw await deserializeAws_restJson1InvalidCoordinatesResponse(parsedOutput, context);
        case "NoSuchResource":
        case "wisogis.common#NoSuchResource":
            throw await deserializeAws_restJson1NoSuchResourceResponse(parsedOutput, context);
        case "ValidationException":
        case "smithy.framework#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode
            });
    }
};
export const deserializeAws_restJson1GetSimulationCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1GetSimulationCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull((__expectObject(await parseBody(output.body, context))), "body");
    if (data.dates != null) {
        contents.dates = deserializeAws_restJson1SimulationDate(data.dates, context);
    }
    if (data.id != null) {
        contents.id = __expectString(data.id);
    }
    if (data.name != null) {
        contents.name = __expectString(data.name);
    }
    if (data.parameters != null) {
        contents.parameters = deserializeAws_restJson1SimulationParameters(data.parameters, context);
    }
    if (data.state != null) {
        contents.state = __expectString(data.state);
    }
    if (data.uid != null) {
        contents.uid = __expectString(data.uid);
    }
    return contents;
};
const deserializeAws_restJson1GetSimulationCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context)
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequest":
        case "wisogis.common#BadRequest":
            throw await deserializeAws_restJson1BadRequestResponse(parsedOutput, context);
        case "InvalidCoordinates":
        case "wisogis.common#InvalidCoordinates":
            throw await deserializeAws_restJson1InvalidCoordinatesResponse(parsedOutput, context);
        case "NoSuchResource":
        case "wisogis.common#NoSuchResource":
            throw await deserializeAws_restJson1NoSuchResourceResponse(parsedOutput, context);
        case "ValidationException":
        case "smithy.framework#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode
            });
    }
};
export const deserializeAws_restJson1GetSimulationResultsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1GetSimulationResultsCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull((__expectObject(await parseBody(output.body, context))), "body");
    if (data.airports_layer != null) {
        contents.airports_layer = __expectString(data.airports_layer);
    }
    if (data.allkpis_heatmap != null) {
        contents.allkpis_heatmap = __expectString(data.allkpis_heatmap);
    }
    if (data.complex_heatmap != null) {
        contents.complex_heatmap = __expectString(data.complex_heatmap);
    }
    if (data.forest_layer != null) {
        contents.forest_layer = __expectString(data.forest_layer);
    }
    if (data.lcoe_heatmap != null) {
        contents.lcoe_heatmap = __expectString(data.lcoe_heatmap);
    }
    if (data.powerlines_layer != null) {
        contents.powerlines_layer = __expectString(data.powerlines_layer);
    }
    if (data.protected_areas_layer != null) {
        contents.protected_areas_layer = __expectString(data.protected_areas_layer);
    }
    if (data.urban_layer != null) {
        contents.urban_layer = __expectString(data.urban_layer);
    }
    if (data.water_layer != null) {
        contents.water_layer = __expectString(data.water_layer);
    }
    if (data.wetland_layer != null) {
        contents.wetland_layer = __expectString(data.wetland_layer);
    }
    return contents;
};
const deserializeAws_restJson1GetSimulationResultsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context)
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequest":
        case "wisogis.common#BadRequest":
            throw await deserializeAws_restJson1BadRequestResponse(parsedOutput, context);
        case "InvalidCoordinates":
        case "wisogis.common#InvalidCoordinates":
            throw await deserializeAws_restJson1InvalidCoordinatesResponse(parsedOutput, context);
        case "InvalidState":
        case "wisogis.common#InvalidState":
            throw await deserializeAws_restJson1InvalidStateResponse(parsedOutput, context);
        case "NoSuchResource":
        case "wisogis.common#NoSuchResource":
            throw await deserializeAws_restJson1NoSuchResourceResponse(parsedOutput, context);
        case "ValidationException":
        case "smithy.framework#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode
            });
    }
};
export const deserializeAws_restJson1ListSimulationsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1ListSimulationsCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull((__expectObject(await parseBody(output.body, context))), "body");
    if (data.items != null) {
        contents.items = deserializeAws_restJson1SimulationsList(data.items, context);
    }
    if (data.nextToken != null) {
        contents.nextToken = __expectString(data.nextToken);
    }
    return contents;
};
const deserializeAws_restJson1ListSimulationsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context)
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequest":
        case "wisogis.common#BadRequest":
            throw await deserializeAws_restJson1BadRequestResponse(parsedOutput, context);
        case "InvalidCoordinates":
        case "wisogis.common#InvalidCoordinates":
            throw await deserializeAws_restJson1InvalidCoordinatesResponse(parsedOutput, context);
        case "NoSuchResource":
        case "wisogis.common#NoSuchResource":
            throw await deserializeAws_restJson1NoSuchResourceResponse(parsedOutput, context);
        case "ValidationException":
        case "smithy.framework#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode
            });
    }
};
export const deserializeAws_restJson1RenameSimulationCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1RenameSimulationCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull((__expectObject(await parseBody(output.body, context))), "body");
    if (data.dates != null) {
        contents.dates = deserializeAws_restJson1SimulationDate(data.dates, context);
    }
    if (data.id != null) {
        contents.id = __expectString(data.id);
    }
    if (data.name != null) {
        contents.name = __expectString(data.name);
    }
    if (data.parameters != null) {
        contents.parameters = deserializeAws_restJson1SimulationParameters(data.parameters, context);
    }
    if (data.state != null) {
        contents.state = __expectString(data.state);
    }
    if (data.uid != null) {
        contents.uid = __expectString(data.uid);
    }
    return contents;
};
const deserializeAws_restJson1RenameSimulationCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context)
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequest":
        case "wisogis.common#BadRequest":
            throw await deserializeAws_restJson1BadRequestResponse(parsedOutput, context);
        case "InvalidCoordinates":
        case "wisogis.common#InvalidCoordinates":
            throw await deserializeAws_restJson1InvalidCoordinatesResponse(parsedOutput, context);
        case "NoSuchResource":
        case "wisogis.common#NoSuchResource":
            throw await deserializeAws_restJson1NoSuchResourceResponse(parsedOutput, context);
        case "ValidationException":
        case "smithy.framework#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode
            });
    }
};
export const deserializeAws_restJson1StartSimulationCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1StartSimulationCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull((__expectObject(await parseBody(output.body, context))), "body");
    if (data.dates != null) {
        contents.dates = deserializeAws_restJson1SimulationDate(data.dates, context);
    }
    if (data.id != null) {
        contents.id = __expectString(data.id);
    }
    if (data.name != null) {
        contents.name = __expectString(data.name);
    }
    if (data.parameters != null) {
        contents.parameters = deserializeAws_restJson1SimulationParameters(data.parameters, context);
    }
    if (data.state != null) {
        contents.state = __expectString(data.state);
    }
    if (data.uid != null) {
        contents.uid = __expectString(data.uid);
    }
    return contents;
};
const deserializeAws_restJson1StartSimulationCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context)
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequest":
        case "wisogis.common#BadRequest":
            throw await deserializeAws_restJson1BadRequestResponse(parsedOutput, context);
        case "InvalidCoordinates":
        case "wisogis.common#InvalidCoordinates":
            throw await deserializeAws_restJson1InvalidCoordinatesResponse(parsedOutput, context);
        case "InvalidState":
        case "wisogis.common#InvalidState":
            throw await deserializeAws_restJson1InvalidStateResponse(parsedOutput, context);
        case "NoSuchResource":
        case "wisogis.common#NoSuchResource":
            throw await deserializeAws_restJson1NoSuchResourceResponse(parsedOutput, context);
        case "ValidationException":
        case "smithy.framework#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode
            });
    }
};
const map = __map;
const deserializeAws_restJson1ValidationExceptionResponse = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    if (data.fieldList != null) {
        contents.fieldList = deserializeAws_restJson1ValidationExceptionFieldList(data.fieldList, context);
    }
    if (data.message != null) {
        contents.message = __expectString(data.message);
    }
    const exception = new ValidationException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const deserializeAws_restJson1BadRequestResponse = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    if (data.message != null) {
        contents.message = __expectString(data.message);
    }
    const exception = new BadRequest({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const deserializeAws_restJson1InvalidCoordinatesResponse = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    if (data.coord != null) {
        contents.coord = deserializeAws_restJson1Coord(data.coord, context);
    }
    const exception = new InvalidCoordinates({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const deserializeAws_restJson1InvalidStateResponse = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    if (data.stateExpected != null) {
        contents.stateExpected = deserializeAws_restJson1StateList(data.stateExpected, context);
    }
    if (data.stateFound != null) {
        contents.stateFound = __expectString(data.stateFound);
    }
    const exception = new InvalidState({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const deserializeAws_restJson1NoSuchResourceResponse = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    if (data.resourceId != null) {
        contents.resourceId = deserializeAws_restJson1ResourceId(data.resourceId, context);
    }
    if (data.resourceType != null) {
        contents.resourceType = __expectString(data.resourceType);
    }
    const exception = new NoSuchResource({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const serializeAws_restJson1GeoJSON = (input, context) => {
    return input;
};
const serializeAws_restJson1LayerParameters = (input, context) => {
    return {
        ...(input.airports != null && { "airports": input.airports }),
        ...(input.complex_terrain != null && { "complex_terrain": __serializeFloat(input.complex_terrain) }),
        ...(input.forest != null && { "forest": input.forest }),
        ...(input.power_lines != null && { "power_lines": input.power_lines }),
        ...(input.protected_areas != null && { "protected_areas": input.protected_areas }),
        ...(input.urban != null && { "urban": input.urban }),
        ...(input.water != null && { "water": input.water }),
        ...(input.wetland != null && { "wetland": input.wetland }),
    };
};
const serializeAws_restJson1LcoeParameters = (input, context) => {
    return {
        ...(input.lifetime != null && { "lifetime": __serializeFloat(input.lifetime) }),
        ...(input.solar_capex != null && { "solar_capex": __serializeFloat(input.solar_capex) }),
        ...(input.solar_opex != null && { "solar_opex": __serializeFloat(input.solar_opex) }),
        ...(input.wacc != null && { "wacc": __serializeFloat(input.wacc) }),
        ...(input.wind_capex != null && { "wind_capex": __serializeFloat(input.wind_capex) }),
        ...(input.wind_opex != null && { "wind_opex": __serializeFloat(input.wind_opex) }),
    };
};
const serializeAws_restJson1SimulationParameters = (input, context) => {
    return {
        ...(input.combined != null && { "combined": __serializeFloat(input.combined) }),
        ...(input.grid_connection_type != null && { "grid_connection_type": input.grid_connection_type }),
        ...(input.grid_cost_per_km != null && { "grid_cost_per_km": __serializeFloat(input.grid_cost_per_km) }),
        ...(input.layer_params != null && { "layer_params": serializeAws_restJson1LayerParameters(input.layer_params, context) }),
        ...(input.lcoe_params != null && { "lcoe_params": serializeAws_restJson1LcoeParameters(input.lcoe_params, context) }),
        ...(input.max_slope != null && { "max_slope": __serializeFloat(input.max_slope) }),
        ...(input.model_wt != null && { "model_wt": input.model_wt }),
        ...(input.optimization != null && { "optimization": input.optimization }),
        ...(input.pv_tracking != null && { "pv_tracking": input.pv_tracking }),
        ...(input.slope_cost_per_percent != null && { "slope_cost_per_percent": __serializeFloat(input.slope_cost_per_percent) }),
    };
};
const deserializeAws_restJson1ValidationExceptionField = (output, context) => {
    return {
        message: __expectString(output.message),
        path: __expectString(output.path),
    };
};
const deserializeAws_restJson1ValidationExceptionFieldList = (output, context) => {
    const retVal = (output || []).filter((e) => e != null).map((entry) => {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ValidationExceptionField(entry, context);
    });
    return retVal;
};
const deserializeAws_restJson1Coord = (output, context) => {
    return {
        lat: __limitedParseDouble(output.lat),
        lon: __limitedParseDouble(output.lon),
    };
};
const deserializeAws_restJson1ResourceId = (output, context) => {
    return output;
};
const deserializeAws_restJson1StateList = (output, context) => {
    const retVal = (output || []).filter((e) => e != null).map((entry) => {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
    return retVal;
};
const deserializeAws_restJson1ValueList = (output, context) => {
    const retVal = (output || []).filter((e) => e != null).map((entry) => {
        if (entry === null) {
            return null;
        }
        return __limitedParseDouble(entry);
    });
    return retVal;
};
const deserializeAws_restJson1LayerParameters = (output, context) => {
    return {
        airports: __expectInt32(output.airports),
        complex_terrain: __limitedParseDouble(output.complex_terrain),
        forest: __expectInt32(output.forest),
        power_lines: __expectInt32(output.power_lines),
        protected_areas: __expectInt32(output.protected_areas),
        urban: __expectInt32(output.urban),
        water: __expectInt32(output.water),
        wetland: __expectInt32(output.wetland),
    };
};
const deserializeAws_restJson1LcoeParameters = (output, context) => {
    return {
        lifetime: __limitedParseDouble(output.lifetime),
        solar_capex: __limitedParseDouble(output.solar_capex),
        solar_opex: __limitedParseDouble(output.solar_opex),
        wacc: __limitedParseDouble(output.wacc),
        wind_capex: __limitedParseDouble(output.wind_capex),
        wind_opex: __limitedParseDouble(output.wind_opex),
    };
};
const deserializeAws_restJson1SimulationDate = (output, context) => {
    return {
        computing: (output.computing != null) ? __expectNonNull(__parseRfc3339DateTimeWithOffset(output.computing)) : undefined,
        created: (output.created != null) ? __expectNonNull(__parseRfc3339DateTimeWithOffset(output.created)) : undefined,
        error: (output.error != null) ? __expectNonNull(__parseRfc3339DateTimeWithOffset(output.error)) : undefined,
        ready: (output.ready != null) ? __expectNonNull(__parseRfc3339DateTimeWithOffset(output.ready)) : undefined,
    };
};
const deserializeAws_restJson1SimulationItem = (output, context) => {
    return {
        dates: (output.dates != null) ? deserializeAws_restJson1SimulationDate(output.dates, context) : undefined,
        id: __expectString(output.id),
        name: __expectString(output.name),
        parameters: (output.parameters != null) ? deserializeAws_restJson1SimulationParameters(output.parameters, context) : undefined,
        state: __expectString(output.state),
        uid: __expectString(output.uid),
    };
};
const deserializeAws_restJson1SimulationParameters = (output, context) => {
    return {
        combined: __limitedParseDouble(output.combined),
        grid_connection_type: __expectString(output.grid_connection_type),
        grid_cost_per_km: __limitedParseDouble(output.grid_cost_per_km),
        layer_params: (output.layer_params != null) ? deserializeAws_restJson1LayerParameters(output.layer_params, context) : undefined,
        lcoe_params: (output.lcoe_params != null) ? deserializeAws_restJson1LcoeParameters(output.lcoe_params, context) : undefined,
        max_slope: __limitedParseDouble(output.max_slope),
        model_wt: __expectString(output.model_wt),
        optimization: __expectString(output.optimization),
        pv_tracking: __expectBoolean(output.pv_tracking),
        slope_cost_per_percent: __limitedParseDouble(output.slope_cost_per_percent),
    };
};
const deserializeAws_restJson1SimulationsList = (output, context) => {
    const retVal = (output || []).filter((e) => e != null).map((entry) => {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1SimulationItem(entry, context);
    });
    return retVal;
};
const deserializeMetadata = (output) => ({
    httpStatusCode: output.statusCode,
    requestId: output.headers["x-amzn-requestid"] ?? output.headers["x-amzn-request-id"] ?? output.headers["x-amz-request-id"],
    extendedRequestId: output.headers["x-amz-id-2"],
    cfId: output.headers["x-amz-cf-id"],
});
const collectBody = (streamBody = new Uint8Array(), context) => {
    if (streamBody instanceof Uint8Array) {
        return Promise.resolve(streamBody);
    }
    return context.streamCollector(streamBody) || Promise.resolve(new Uint8Array());
};
const collectBodyString = (streamBody, context) => collectBody(streamBody, context).then(body => context.utf8Encoder(body));
const isSerializableHeaderValue = (value) => value !== undefined &&
    value !== null &&
    value !== "" &&
    (!Object.getOwnPropertyNames(value).includes("length") ||
        value.length != 0) &&
    (!Object.getOwnPropertyNames(value).includes("size") || value.size != 0);
const parseBody = (streamBody, context) => collectBodyString(streamBody, context).then(encoded => {
    if (encoded.length) {
        return JSON.parse(encoded);
    }
    return {};
});
const parseErrorBody = async (errorBody, context) => {
    const value = await parseBody(errorBody, context);
    value.message = value.message ?? value.Message;
    return value;
};
const loadRestJsonErrorCode = (output, data) => {
    const findKey = (object, key) => Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase());
    const sanitizeErrorCode = (rawValue) => {
        let cleanValue = rawValue;
        if (typeof cleanValue === "number") {
            cleanValue = cleanValue.toString();
        }
        if (cleanValue.indexOf(",") >= 0) {
            cleanValue = cleanValue.split(",")[0];
        }
        if (cleanValue.indexOf(":") >= 0) {
            cleanValue = cleanValue.split(":")[0];
        }
        if (cleanValue.indexOf("#") >= 0) {
            cleanValue = cleanValue.split("#")[1];
        }
        return cleanValue;
    };
    const headerKey = findKey(output.headers, "x-amzn-errortype");
    if (headerKey !== undefined) {
        return sanitizeErrorCode(output.headers[headerKey]);
    }
    if (data.code !== undefined) {
        return sanitizeErrorCode(data.code);
    }
    if (data["__type"] !== undefined) {
        return sanitizeErrorCode(data["__type"]);
    }
};
