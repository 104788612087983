import { useState, useEffect } from "react";
import { connect } from "react-redux";
import Amplify, { Auth } from "aws-amplify";
import { addDataToMap, setUserMapboxAccessToken } from "kepler.gl/actions";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { bordersConfig } from "./map-configs";
import { MapContainer } from "./components/MapContainer";
import AuthComp from "./components/AuthComp";
import LocationDetails from "./components/LocationDetails";
import BottomRightMenu from "./components/bottom_right_menu/BottomRightMenu";
import CustomMapControl from "./components/custommapcontrol/CustomMapControl";
import Snackbar from "./components/Snackbar";
import Spinner from "./components/Spinner";
import SimulationLaunched from "./components/SimulationLaunched";
import awsconfig from "./aws-exports";
import { FixedHeader } from "./components/CustomHeader";
import GlobalStyles from "./GlobalStyles";
import { getUserLicense } from "./service/get_user_license";
import "./App.scss";

Amplify.configure(awsconfig);

const theme = createTheme({
  palette: {
    primary: { main: "#009de9" },
  },
  typography: {
    fontFamily: ["Lato", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
    fontWeightRegular: "300",
  },
});

const App = (props) => {
  const [isAuthenticated, setAuthenticated] = useState();

  useEffect(() => {
    checkAuthentication();
  }, []);

  const checkAuthentication = () => {  // Todo: setup it in a better way
    setAuthenticated(true);
    setUpMap();
    getUserLicense(props);
  };

  const setUpMap = () => {
    props.dispatch(
      setUserMapboxAccessToken(
        "pk.eyJ1IjoiaHViZXJ0byIsImEiOiJja2tjamNnY2IwZmh3MnBxcmhqcW43dnE5In0.fIEXMsXpZRcKbQ1A_g7ZgQ"
      )
    );
    setTimeout(() => {
      props.dispatch(
        addDataToMap({
          config: bordersConfig,
        })
      );
    }, 10);
  };

  if (isAuthenticated === false)
    return (
      <ThemeProvider theme={theme}>
        <Snackbar />
        <AuthComp checkAuthentication={checkAuthentication} />
      </ThemeProvider>
    );

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BottomRightMenu />
        <CustomMapControl />
        <MapContainer />
        <LocationDetails />
        <FixedHeader />
        <Spinner
          isAuthenticated={isAuthenticated}
          isApiLoadingHeatmap={props.loadingReducer.isApiLoadingHeatmap}
          isApiLoadingLocationData={
            props.loadingReducer.isApiLoadingLocationData
          }
        />
        <Snackbar />
        <SimulationLaunched />
        <GlobalStyles />
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => state;
const dispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, dispatchToProps)(App);
