import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Content, PageContainer, StyledTableRow } from "./Common";

const abbreviations = [
  {
    key: "GHI",
    name: "Global Horizontal Irradiance",
    explanation:
      "Average annual, monthly or daily sum of global horizontal irradiationin kWh/m²",
  },
  {
    key: "CAPEX",
    name: "Capital Expenditure",
    explanation:
      "Initial investment required to build the generating asset in $/kW",
  },
  {
    key: "LCOE",
    name: "Levelized Cost of Electricity",
    explanation:
      "Full price of electricity over the lifetime of the renewable plant in $/MWh",
  },
  {
    key: "OPEX",
    name: "Operationnal Expenditure",
    explanation:
      "Operationnal & Maintenance costs during the lifetime of the generating asset in $/kW/yr",
  },
  {
    key: "WACC",
    name: "Weighted Average Cost of Capital",
    explanation:
      "Discount rate used to perform a discounted cash flow analysis of the energy project.",
  },
];

const Page3 = () => (
  <PageContainer id="pdf-introduction">
    <Typography variant="h3" style={{ color: "#40adf8" }}>
      1. Introduction
    </Typography>
    <Content>
      <div style={{ margin: "24px 0" }}>
        <Typography>
          This report presents all the results of the simulations performed with
          the Wisogis tool, developed by the company Tractebel. The tool s
          renewable energy production models are based on high resolution, state
          of the art climate models combined with operational data from
          renewable assets around the world. In addition, Tractebel's extensive
          expertise in site selection and financial optimization of renewable
          projects has enabled the tool to develop an accurate and up to date
          financial model. This approach provides reliable preliminary results
          to secure project decision making, helping you to choose the most
          suitable site. More information on the methodology available:
          <a href="https://wisogis.tractebel.com/documentation">
            {" "}
            wisogis.tractebel.com/documentation
          </a>
        </Typography>
      </div>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Abb.</TableCell>
              <TableCell align="right">Full name</TableCell>
              <TableCell align="right">Explanation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {abbreviations.map((abbreviation) => (
              <StyledTableRow key={abbreviation.key}>
                <TableCell component="th" scope="row">
                  {abbreviation.key}
                </TableCell>
                <TableCell align="right">{abbreviation.name}</TableCell>
                <TableCell align="right">{abbreviation.explanation}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Content>
  </PageContainer>
);

export default Page3;
