

export function pointToGeojson(point) {
    return {
        type: "FeatureCollection",
        features: [
            {
                type: "Feature",
                properties: {},
                geometry: {
                    coordinates: point,
                    type: "Point",
                }
            }
        ]
    }
}

export function polygonToGeojson(polygon) {
    return {
        type: "FeatureCollection",
        features: [
            {
                type: "Feature",
                properties: {},
                geometry: {
                    coordinates: [polygon],
                    type: "Polygon",
                }
            }
        ]
    }
}

export function multipolygonToGeojson(polygon) {
	return {
		type: "FeatureCollection",
		features: [
			{
				type: "Feature",
				properties: {},
				geometry: {
					coordinates: polygon,
					type: "MultiPolygon",
				}
			}
		]
	}
}
