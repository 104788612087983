// {"input_id": "8b1015f3-e819-4726-b474-311130b09c71"}
import {simulationApiClient} from "../apiClient";
import {DeleteSimulationCommand} from "@wisogis/simulation-sdk";
import {showErrorSnackbar} from "../actions/snackbarActions";

export const deleteUserInputs = async (userId, input_id, successCb) => {

  try {
    await simulationApiClient.send(new DeleteSimulationCommand({
      uid: userId,
      id: input_id
    }))
    return successCb({})  // Previous api returned an empty dict
  } catch (err) {
    props.dispatch(showErrorSnackbar("An error occurred"))
    console.error(err)
  }
};
