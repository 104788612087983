/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:272b6296-1611-40dc-a958-b322fe793619",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_W5efsn93U",
    "aws_user_pools_web_client_id": "1h85nu8p7l34jk73kfhf3ugbdb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "wisogisprod-20220201173548-hostingbucket-staging",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d1770yrtd9orkd.cloudfront.net",
    "aws_user_files_s3_bucket": "wisogis-prod-results",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
