import { withStyles } from "@material-ui/core/styles";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Title,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { Stack, Animation } from "@devexpress/dx-react-chart";
import Typography from "@material-ui/core/Typography";
import { Content, PageContainer } from "./Common";

const legendStyles = () => ({ root: { paddingBottom: 0 } });
const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);
const Root = withStyles(legendStyles, { name: "LegendRoot" })(legendRootBase);
const legendLabelStyles = () => ({
  label: { margin: 0, padding: 0 },
});
const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const Label = withStyles(legendLabelStyles, { name: "LegendLabel" })(
  legendLabelBase
);

const WIDTH = 360;
const WIND = "wind";
const SOLAR = "solar";
const HYBRID = "hybrid";

const MonthlyGraph = ({ graphData, setHourly, type, i }) => {
  return (
    <Chart
      data={graphData}
      height={300}
      width={WIDTH}
      id={`${type}-capacity-factor-${i}`}
    >
      <ArgumentAxis />
      <ValueAxis />

      {type === WIND || type === HYBRID ? (
        <BarSeries
          name="Wind monthly capacity factor"
          valueField="wind_monthly_capacity_factor"
          argumentField="month"
          color="#00A3FF"
        />
      ) : null}
      {type === SOLAR || type === HYBRID ? (
        <BarSeries
          name="Solar monthly capacity factor"
          valueField="solar_monthly_capacity_factor"
          argumentField="month"
          color="#FF8B00"
        />
      ) : null}
      <Animation />
      <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
      <Title
        textComponent={({ text }) => (
          <Typography
            onClick={() => setHourly(true)}
            variant="h5"
            style={{
              fontSize: "1em",
              margin: "0px auto 5px auto",
              fontWeight: "500",
            }}
          >
            {text}
          </Typography>
        )}
        text="< Monthly Capacity Factor >"
      />
      <Stack
        stacks={[
          {
            series: [
              "Wind monthly capacity factor",
              "Solar monthly capacity factor",
            ],
          },
        ]}
      />
    </Chart>
  );
};

const HourlyGraph = ({ graphData, setHourly, type, i }) => {
  return (
    <Chart
      data={graphData}
      height={300}
      width={WIDTH}
      id={`${type}-hourly-capacity-factor-${i}`}
    >
      <ArgumentAxis />
      <ValueAxis />

      {type === WIND || type === HYBRID ? (
        <BarSeries
          name="Wind hourly capacity factor"
          valueField="wind_hourly_capacity_factor"
          argumentField="hour"
          color="#00A3FF"
        />
      ) : null}
      {type === SOLAR || type === HYBRID ? (
        <BarSeries
          name="Solar hourly capacity factor"
          valueField="solar_hourly_capacity_factor"
          argumentField="hour"
          color="#FF8B00"
        />
      ) : null}
      <Animation />
      <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
      <Title
        textComponent={({ text }) => (
          <Typography
            onClick={() => setHourly(false)}
            variant="h5"
            style={{
              fontSize: "1em",
              margin: "0px auto 5px auto",
              fontWeight: "500",
            }}
          >
            {text}
          </Typography>
        )}
        text="< Hourly Capacity Factor >"
      />
      <Stack
        stacks={[
          {
            series: [
              "Wind hourly capacity factor",
              "Solar hourly capacity factor",
            ],
          },
        ]}
      />
    </Chart>
  );
};

const SolarEnergyMonthly = ({ solar_capacity_factor, ghi }) => {
  return (
    <>
      <Typography variant="h6">Solar energy</Typography>
      <Typography>
        Average capacity factor: {(solar_capacity_factor * 100).toFixed(2)}%
      </Typography>

      <Typography>Average GHI: {ghi && ghi.toFixed(2)}W/m²</Typography>
    </>
  );
};

const WindEnergyMonthly = ({ wind_capacity_factor, wind_speed }) => {
  return (
    <>
      <Typography variant="h6">Wind energy</Typography>
      <Typography variant="body1">
        Average capacity factor: {(wind_capacity_factor * 100).toFixed(2)}%
      </Typography>
      <Typography variant="body1">
        Average wind speed at 100m: {wind_speed.toFixed(2)}m/s
      </Typography>
    </>
  );
};

const Page6 = ({ details, location, formReducer, i }) => {
  const {
    LCOE,
    average_capacity_factor,
    ghi,
    grid_distance,
    mix,
    slope,
    solar_capacity_factor,
    solar_capex,
    solar_hourly_capacity_factor,
    solar_monthly_capacity_factor,
    solar_opex,
    temperature,
    wind_capacity_factor,
    wind_capex,
    wind_hourly_capacity_factor,
    wind_monthly_capacity_factor,
    wind_opex,
    wind_speed,
  } = details;

  let monthlyGraphData = [
    { month: "Ja" },
    { month: "Fe" },
    { month: "Mr" },
    { month: "Ap" },
    { month: "My" },
    { month: "Jn" },
    { month: "Jl" },
    { month: "Au" },
    { month: "Se" },
    { month: "Oc" },
    { month: "Nv" },
    { month: "De" },
  ];
  if (wind_monthly_capacity_factor) {
    monthlyGraphData = monthlyGraphData.map((g, i) => ({
      wind_monthly_capacity_factor: wind_monthly_capacity_factor[i],
      ...g,
    }));
  }
  if (solar_monthly_capacity_factor) {
    monthlyGraphData = monthlyGraphData.map((g, i) => ({
      solar_monthly_capacity_factor: solar_monthly_capacity_factor[i],
      ...g,
    }));
  }

  let hourlyGraphData = [
    { hour: "00" },
    { hour: "01" },
    { hour: "02" },
    { hour: "03" },
    { hour: "04" },
    { hour: "05" },
    { hour: "06" },
    { hour: "07" },
    { hour: "08" },
    { hour: "09" },
    { hour: "10" },
    { hour: "11" },
    { hour: "12" },
    { hour: "13" },
    { hour: "14" },
    { hour: "15" },
    { hour: "16" },
    { hour: "17" },
    { hour: "18" },
    { hour: "19" },
    { hour: "20" },
    { hour: "21" },
    { hour: "22" },
    { hour: "23" },
  ];

  if (wind_hourly_capacity_factor) {
    hourlyGraphData = hourlyGraphData.map((g, i) => ({
      wind_hourly_capacity_factor: wind_hourly_capacity_factor[i],
      ...g,
    }));
  }
  if (solar_hourly_capacity_factor) {
    hourlyGraphData = hourlyGraphData.map((g, i) => ({
      solar_hourly_capacity_factor: solar_hourly_capacity_factor[i],
      ...g,
    }));
  }

  return (
    <PageContainer id={`pdf-detailed-report-${i}`}>
      <Content>
        <div id={`pdf-detailed-report-1-${i}`}>
          <Typography variant="h4">
            3.{String.fromCharCode(98 + i)}. Detailed report{" "}
            {location.name ? location.name : "site n°" + (i + 1)}
          </Typography>
          <div style={{ margin: "18px 0" }}>
            <Typography variant="h5">Site description</Typography>
            <Typography>Site ID: {i + 1}</Typography>
            <Typography>Longitude: {location.lng}°</Typography>
            <Typography>Latitude: {location.lat}°</Typography>
            <Typography>
              Average Temperature: {temperature.toFixed(2)}°C
            </Typography>
          </div>
          <Typography variant="h5">Energy yield assessment</Typography>

          {wind_monthly_capacity_factor && (
            <WindEnergyMonthly
              wind_monthly_capacity_factor={wind_monthly_capacity_factor}
              wind_capacity_factor={wind_capacity_factor}
              wind_speed={wind_speed}
              i={i}
            />
          )}

          <div style={{ display: "flex" }}>
            {wind_monthly_capacity_factor && (
              <MonthlyGraph graphData={monthlyGraphData} type={WIND} i={i} />
            )}

            {wind_hourly_capacity_factor && (
              <HourlyGraph graphData={hourlyGraphData} type={WIND} i={i} />
            )}
          </div>

          {solar_monthly_capacity_factor && (
            <SolarEnergyMonthly
              solar_monthly_capacity_factor={solar_monthly_capacity_factor}
              solar_capacity_factor={solar_capacity_factor}
              ghi={ghi}
              i={i}
            />
          )}
          <div style={{ display: "flex" }}>
            {solar_monthly_capacity_factor && (
              <MonthlyGraph graphData={monthlyGraphData} type={SOLAR} i={i} />
            )}

            {solar_hourly_capacity_factor && (
              <HourlyGraph graphData={hourlyGraphData} type={SOLAR} i={i} />
            )}
          </div>
        </div>

        <div id={`pdf-detailed-report-2-${i}`}>
          {wind_monthly_capacity_factor && solar_monthly_capacity_factor && (
            <div>
              <Typography variant="h6">Optimized Mix</Typography>

              <Typography>
                Mix: {(mix * 100).toFixed(2)}% Wind and{" "}
                {(100 - mix * 100).toFixed(2)}% Solar
              </Typography>
              <Typography>
                Average mix capacity factor:{" "}
                {(average_capacity_factor * 100).toFixed(2)}%
              </Typography>

              <div style={{ display: "flex" }}>
                {wind_monthly_capacity_factor && (
                  <MonthlyGraph
                    graphData={monthlyGraphData}
                    type={HYBRID}
                    i={i}
                  />
                )}

                {wind_hourly_capacity_factor && (
                  <HourlyGraph
                    graphData={hourlyGraphData}
                    type={HYBRID}
                    i={i}
                  />
                )}
              </div>
            </div>
          )}
          <Typography variant="h6">
            Levelized cost of electricity (LCOE) analysis
          </Typography>
          <div style={{ margin: "18px 0" }}>
            <Typography>LCOE: {LCOE.toFixed(2)}$/MWh</Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Typography>Wind CAPEX: {wind_capex.toFixed(2)}$/kW</Typography>
              <Typography>
                Wind OPEX: {wind_opex.toFixed(2)}$/kW/year
              </Typography>
            </div>
            <div>
              <Typography>Solar CAPEX: {solar_capex.toFixed(2)}$/kW</Typography>
              <Typography>
                Solar OPEX: {solar_opex.toFixed(2)}$/kW/year
              </Typography>
            </div>
          </div>
          <div style={{ margin: "18px 0" }}>
            <div>
              {formReducer &&
              formReducer.advancedLCOEFormData.grid_cost_per_km.checked ? (
                <Typography>
                  Distance to the nearest HV line: {grid_distance.toFixed(3)}km
                </Typography>
              ) : null}
            </div>
            <div>
              {formReducer &&
              formReducer.advancedLCOEFormData.slope_cost_per_percent
                .checked ? (
                <Typography>Slope: {slope.toFixed(2)}%</Typography>
              ) : null}
            </div>
          </div>
        </div>
      </Content>
    </PageContainer>
  );
};

export default Page6;
