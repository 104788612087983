import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { Content, PageContainer, StyledTableRow } from "./Common";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

const Title = styled(Typography)``;

const Value = styled(Typography)``;

const Summary = ({ formReducer }) => {
  const { renewableTechFormData } = formReducer;
  const { solar, wind } = renewableTechFormData;

  const selectedTechnologies = []
  if (solar) {
    const pvTracking = renewableTechFormData.pv_tracking === false ? "Fixed tilt" : "Single axis tracking"
    selectedTechnologies.push(`Solar Power (${pvTracking})`)
  }
  if (wind)
    selectedTechnologies.push(`Wind Power`)

  return (
    <PageContainer id="pdf-summary">
      <Typography variant="h4">Simulation parameters summary</Typography>
      <Content>
        <Typography variant="h6">Renewable technologies</Typography>
        <Row>
          <Title>Technologies selected:</Title>
          <Value>{selectedTechnologies.join(' & ')}</Value>
        </Row>
        <Row>
          <Title>Renewable mix optimization:</Title>
          <Value>
            {(
              100 -
              100 * formReducer.renewableTechFormData.combined_slider
            ).toFixed()}
            % Stability -{" "}
            {(
              100 * formReducer.renewableTechFormData.combined_slider
            ).toFixed()}
            % LCOE
          </Value>
        </Row>

        <Typography variant="h6">Advanced LCOE</Typography>

        {formReducer.advancedLCOEFormData.grid_cost_per_km.checked ? (
          <Row>
            <Title>Grid connection:</Title>
            <Value>
              {formReducer.advancedLCOEFormData.grid_cost_per_km.value
                ? `${formReducer.advancedLCOEFormData.grid_cost_per_km.value}
              $/kW/km`
                : `DEFAULT`}
            </Value>
          </Row>
        ) : null}

        {formReducer.advancedLCOEFormData.slope_cost_per_percent.checked ? (
          <Row>
            <Title>Local terrain slope</Title>
            <Value>
              {formReducer.advancedLCOEFormData.slope_cost_per_percent.value
                ? `${formReducer.advancedLCOEFormData.slope_cost_per_percent.value}$/kW/%slope`
                : "DEFAULT"}
            </Value>
          </Row>
        ) : null}

        <Row>
          <Title>Wind CAPEX:</Title>
          <Value>
            {formReducer.advancedLCOEFormData.wind_capex
              ? `${formReducer.advancedLCOEFormData.wind_capex} $/kW`
              : "DEFAULT"}
          </Value>
        </Row>

        <Row>
          <Title>Solar CAPEX:</Title>
          <Value>
            {formReducer.advancedLCOEFormData.solar_capex
              ? `${formReducer.advancedLCOEFormData.solar_capex} $/kW`
              : "DEFAULT"}
          </Value>
        </Row>

        <Row>
          <Title>Wind OPEX:</Title>
          <Value>
            {formReducer.advancedLCOEFormData.wind_opex
              ? `${formReducer.advancedLCOEFormData.wind_opex} $/kW/yr`
              : "DEFAULT"}
          </Value>
        </Row>

        <Row>
          <Title>Solar OPEX:</Title>
          <Value>
            {formReducer.advancedLCOEFormData.solar_opex
              ? `${formReducer.advancedLCOEFormData.solar_opex} $/kW/yr`
              : "DEFAULT"}
          </Value>
        </Row>

        {formReducer.advancedLCOEFormData.lifetime ? (
          <Row>
            <Title>Lifetime:</Title>
            <Value>{formReducer.advancedLCOEFormData.lifetime}yrs</Value>
          </Row>
        ) : null}

        <Row>
          <Title>Wacc:</Title>
          <Value>
            {formReducer.advancedLCOEFormData.wacc
              ? `${formReducer.advancedLCOEFormData.wacc} %`
              : "DEFAULT"}
          </Value>
        </Row>

        <Typography variant="h6">Exclusion zones</Typography>

        {formReducer.exclusionAreasFormData.water ? (
          <Row>
            <Title>Permanent water</Title>
            <Value>On</Value>
          </Row>
        ) : null}
        {formReducer.exclusionAreasFormData.wetland ? (
          <Row>
            <Title>Herbaceous wetland</Title>
            <Value>On</Value>
          </Row>
        ) : null}
        {formReducer.exclusionAreasFormData.is_protected_area ? (
          <Row>
            <Title>Protected areas</Title>
            <Value>
              {formReducer.exclusionAreasFormData.protected_area}m buffer
            </Value>
          </Row>
        ) : null}
        {formReducer.exclusionAreasFormData.is_urban ? (
          <Row>
            <Title>Urban or built up</Title>
            <Value>{formReducer.exclusionAreasFormData.urban}m buffer</Value>
          </Row>
        ) : null}
        {formReducer.exclusionAreasFormData.is_forest ? (
          <Row>
            <Title>Forest</Title>
            <Value>{formReducer.exclusionAreasFormData.forest}m buffer</Value>
          </Row>
        ) : null}
        {formReducer.exclusionAreasFormData.ui_show_complex_terrain ? (
          <Row>
            <Title>Maximum local slope</Title>
            <Value>
              {formReducer.exclusionAreasFormData.complex_terrain}m buffer
            </Value>
          </Row>
        ) : null}
        {formReducer.exclusionAreasFormData.is_airports ? (
          <Row>
            <Title>Airports</Title>
            <Value>{formReducer.exclusionAreasFormData.airports}m buffer</Value>
          </Row>
        ) : null}
      </Content>
    </PageContainer>
  );
};

export default Summary;
