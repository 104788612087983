import React from "react";
import styled from "styled-components";
import Processors from "kepler.gl/processors";
import { Icons } from "kepler.gl/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { addDataToMap, toggleMapControl, toggleModal } from "kepler.gl/actions";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";

import { SimpleSpinner } from "../Spinner";
import { heatmapConfig } from "../../map-configs";
import { getUserInputs } from "../../service/get_user_inputs";
import { updateInputName } from "../../service/update_input_name";
import { deleteUserInputs } from "../../service/delete_user_inputs";

import {
  showSuccessSnackbar,
  showInfoSnackbar, showErrorSnackbar,
} from "../../actions/snackbarActions";
import {simulationApiClient} from "../../apiClient";
import {GetSimulationCommand, GetSimulationResultsCommand} from "@wisogis/simulation-sdk";

const setMenuValues = (userInput, dispatch) => {
  dispatch({
    type: "SET_FORM_RENEWABLE_TECH",
    payload: {
      solar: userInput.opti !== "wind",
      wind: userInput.opti !== "solar",
      pv_tracking: userInput.pv_tracking, // can be fixed or single axis if solar is true
      opti: userInput.opti,
      combined_slider: userInput.combined_slider,
    },
  });

  dispatch({
    type: "SET_FORM_EXCLUSION_AREAS",
    payload: {
      airports: userInput.layers.airports,
      complex_terrain: userInput.layers.complex_terrain,
      forest: userInput.layers.forest,
      hv_line_distance: userInput.layers.hv_line_distance,
      military: userInput.layers.military,
      power_lines: userInput.layers.power_lines,
      protected_area: userInput.layers.protected_area,
      urban: userInput.layers.urban,
      substation_distance: userInput.layers.substation_distance,
      water: userInput.layers.water === 0,
      wetland: userInput.layers.wetland === 0,
      ui_show_complex_terrain: userInput.layers.complex_terrain,
      ui_show_hv_line_distance: userInput.layers.hv_line_distance,
      ui_show_substation_distance: userInput.layers.substation_distance,
      is_forest: !isNaN(userInput.layers.forest),
      is_airports: !isNaN(userInput.layers.airports),
      is_protected_area: !isNaN(userInput.layers.protected_area),
      is_urban: !isNaN(userInput.layers.urban),
    },
  });

  dispatch({
    type: "SET_FORM_ADVANCED_LCOE",
    payload: {
      grid_connection_type: userInput.grid_connection_type,
      grid_cost_per_km: {
        checked: !!userInput.grid_cost_per_km,
        value: userInput.grid_cost_per_km || "",
      },
      slope_cost_per_percent: {
        checked: !!userInput.slope_cost_per_percent,
        value: userInput.slope_cost_per_percent || "",
      },
      wind_capex: userInput.LCOE_params.wind_capex || "",
      solar_capex: userInput.LCOE_params.solar_capex || "",
      wind_opex: userInput.LCOE_params.wind_opex || "",
      solar_opex: userInput.LCOE_params.solar_opex || "",
      lifetime: userInput.LCOE_params.lifetime || "",
      wacc: userInput.LCOE_params.wacc || "",
    },
  });

  dispatch({
    type: "SET_HISTORY_INPUTS",
    payload: {
      renewableTechFormData: {
        solar: userInput.opti !== "wind",
        wind: userInput.opti !== "solar",
        pv_tracking: userInput.pv_tracking, // can be fixed or single axis if solar is true
        opti: userInput.opti,
        combined_slider: userInput.combined_slider,
      },
      exclusionAreasFormData: {
        airports: userInput.layers.airports,
        complex_terrain: userInput.layers.complex_terrain,
        forest: userInput.layers.forest,
        hv_line_distance: userInput.layers.hv_line_distance,
        military: userInput.layers.military,
        power_lines: userInput.layers.power_lines,
        protected_area: userInput.layers.protected_area,
        urban: userInput.layers.urban,
        substation_distance: userInput.layers.substation_distance,
        water: userInput.layers.water === 0,
        wetland: userInput.layers.wetland === 0,
        ui_show_complex_terrain: userInput.layers.complex_terrain,
        ui_show_hv_line_distance: userInput.layers.hv_line_distance,
        ui_show_substation_distance: userInput.layers.substation_distance,
        is_forest: !isNaN(userInput.layers.forest),
        is_airports: !isNaN(userInput.layers.airports),
        is_protected_area: !isNaN(userInput.layers.protected_area),
        is_urban: !isNaN(userInput.layers.urban),
      },
      advancedLCOEFormData: {
        grid_connection_type: userInput.grid_connection_type,
        grid_cost_per_km: {
          checked: !!userInput.grid_cost_per_km,
          value: userInput.grid_cost_per_km || "",
        },
        slope_cost_per_percent: {
          checked: !!userInput.slope_cost_per_percent,
          value: userInput.slope_cost_per_percent || "",
        },
        wind_capex: userInput.LCOE_params.wind_capex || "",
        solar_capex: userInput.LCOE_params.solar_capex || "",
        wind_opex: userInput.LCOE_params.wind_opex || "",
        solar_opex: userInput.LCOE_params.solar_opex || "",
        lifetime: userInput.LCOE_params.lifetime || "",
        wacc: userInput.LCOE_params.wacc || "",
      },
    },
  });
};

const HEATMAP = "heatmap";
const POWER_LINES = "power_lines";
const URBAN = "urban";
const AIRPORTS = "airports";
const PROTECTED_AREA = "protected_area";
const MILITARY = "military";
const HV_LINE_DISTANCE = "hv_line_distance";
const SUBSTATION_DISTANCE = "substation_distance";
const COMPLEX_TERRAIN = "complex_terrain";
const FOREST = "forest";
const WATER = "water";
const WETLAND = "wetland";

export const getLayerLabelFromId = (id) => {
  switch (id) {
    case HEATMAP:
      return "Heatmap";
    case POWER_LINES:
      return "Power lines";
    case URBAN:
      return "Urban";
    case AIRPORTS:
      return "Airports";
    case PROTECTED_AREA:
      return "Protected area";
    case MILITARY:
      return "Military";
    case HV_LINE_DISTANCE:
      return "HV line distance";
    case SUBSTATION_DISTANCE:
      return "Substation distance";
    case COMPLEX_TERRAIN:
      return "Complex terrain";
    case FOREST:
      return "Forest";
    case WATER:
      return "Water";
    case WETLAND:
      return "Herbaceous Wetlands";
    default:
      return "";
  }
};

export const layersIdToApiLayersKeys = (id) => {
  switch (id) {
    case HEATMAP: return "lcoe_heatmap";
    case POWER_LINES: return "powerlines_layer";
    case URBAN: return "urban_layer";
    case AIRPORTS: return "airports_layer";
    case PROTECTED_AREA: return "protected_areas_layer";
    case COMPLEX_TERRAIN: return "complex_heatmap";
    case FOREST: return "forest_layer";
    case WATER: return "water_layer";
    case WETLAND: return "wetland_layer";
    default: return ""
  }
}

const getDataSets = async (identityId, heatmap_id, dispatch) => {
  try {
    const layersIds = heatmap_id.replace(identityId, "");

    // No error code handling in previous code => no error handling added to keep the same logic as done before
    // Legacy code downloaded only the heatmap and the powerline layers => keep the same logic

    const simulationResults = await simulationApiClient.send(new GetSimulationResultsCommand({
      uid: identityId,
      id: heatmap_id,
    }))

    const datasets = []

    const heatmap = await (await fetch(simulationResults.lcoe_heatmap)).json()
    const processedHeatmap = Processors.processGeojson(heatmap)
    datasets.push({info: { label: "Heatmap", id: "heatmap" }, data: processedHeatmap })

    const LAYERS_ID_TO_LOAD_IF_POSSIBLE = [
      POWER_LINES, URBAN, AIRPORTS, PROTECTED_AREA, COMPLEX_TERRAIN, FOREST, WATER, WETLAND
    ]

    for (const layerId of Object.values(LAYERS_ID_TO_LOAD_IF_POSSIBLE)) {
      try {
        const apiLayerField = layersIdToApiLayersKeys(layerId)
        const layerMap = await (await fetch(simulationResults[apiLayerField])).json()
        const processedLayerMap = Processors.processGeojson(layerMap)
        datasets.push({info: { label: getLayerLabelFromId(layerId), id: layerId }, data: processedLayerMap})
      } catch (e) {
        // we don't care of errors
        // This code is not smart at all as we just try to load all layers and skip the layers not asked by the user
        // landing here means that this layer does not exist for this simulation
        // Fixme: load only the computed layers. It could be possible to know them using the GetSimulationDetail command
      }
    }

    dispatch({ type: "SAVE_S3_FILE_ID", payload: layersIds });

    let heatmapIndex;
    datasets.forEach((dataSet, index) => {
      if (dataSet.info && dataSet.info.id === "heatmap") {
        heatmapIndex = index;
      }
    });
    datasets[heatmapIndex].data.fields.forEach((field) => {
      if (field.name === "lcoe") {
        field.name = "LCOE [$/MWh]";
      }
    });
    datasets[heatmapIndex].data.rows.forEach((row) => {
      row[0].properties["LCOE [$/MWh]"] = row[0].properties["lcoe"]
          .toFixed(4)
          .slice(0, -1);
      delete row[0].properties["lcoe"];
    });

    dispatch(
        addDataToMap({
          datasets,
          options: {
            readOnly: false,
            keepExistingConfig: false,
            centerMap: true,
            autoCreateLayers: true,
          },
          config: heatmapConfig,
        })
    );
    dispatch(showSuccessSnackbar("Loaded all layers successfully"));
    dispatch({ type: "REORDER_LAYERS" });
    dispatch(toggleMapControl("mapLegend"));
    dispatch(toggleModal("exportImage"));

    document.querySelector(
        "#kepler-gl__map > div.ReactModalPortal"
    ).style.display = "none";
  } catch (e) {
    dispatch(showErrorSnackbar("An error occurred"))
  }
};

const ModalContainer = styled.div`
  background-color: white;
  width: 100%;
  margin: auto;
  max-width: 400px;
  padding: 12px;
  margin-top: 20%;
`;

const HistoryRow = ({
  userId,
  userInput,
  identityId,
  setHistoryVisible,
  fetchUserInputs,
  dispatch,
}) => {
  const [isEditing, setEditing] = React.useState(false);
  const [newName, setNewName] = React.useState(userInput.name);
  const [isDelete, setDelete] = React.useState(false);

  const handleNameChange = () => {
    if (isEditing && newName !== userInput.name) {
      updateInputName(userInput.input_id, newName, fetchUserInputs);
    }
    setEditing((e) => !e);
  };

  const handleInputDelete = () => {
    setDelete((d) => !d);
  };

  return (
    <>
      {isDelete && (
        <Modal
          open={isDelete}
          onClose={() => setDelete(false)}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <ModalContainer>
            <Box>
              <h4 id="parent-modal-title">Delete</h4>
              <p id="parent-modal-description">
                Are you sure you want to delete your configuration ?
              </p>
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => setDelete(false)}
                style={{ marginRight: 12, borderRadius: 0 }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  deleteUserInputs(userId, userInput.input_id, fetchUserInputs)
                }
                style={{ borderRadius: 0 }}
              >
                Delete
              </Button>
            </div>
          </ModalContainer>
        </Modal>
      )}
      <TableRow
        key={userInput.input_id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell onClick={handleNameChange} style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faPen} height="16px" />
        </TableCell>
        {isEditing ? (
          <TableCell component="th" scope="row" style={{ padding: "0 16px" }}>
            <TextField
              size="small"
              onChange={(e) => setNewName(e.target.value)}
              value={newName}
            />
            <Icons.Checkmark
              height="16px"
              onClick={handleNameChange}
              style={{ cursor: "pointer" }}
            />
          </TableCell>
        ) : (
          <TableCell component="th" scope="row">
            {userInput.name}
          </TableCell>
        )}
        <TableCell align="right">
          {new Date(userInput.date).toLocaleDateString()}
        </TableCell>
        <TableCell align="right">{userInput.endpoint}</TableCell>
        <TableCell
          align="right"
          onClick={() => {
            dispatch(showInfoSnackbar("Retrieving past result..."));
            setMenuValues(userInput, dispatch);
            getDataSets(identityId, userInput.heatmap_id, dispatch);
            setHistoryVisible(false);
          }}
          style={{ cursor: "pointer" }}
        >
          <FontAwesomeIcon icon={faArrowDown} height="16px" />
        </TableCell>
        <TableCell
          align="right"
          onClick={handleInputDelete}
          style={{ cursor: "pointer" }}
        >
          <Icons.Delete height="16px" />
        </TableCell>
      </TableRow>
    </>
  );
};

const History = ({ dispatch, identityId, userId, setHistoryVisible }) => {
  const [userInputs, setUserInputs] = React.useState();

  const fetchUserInputs = () => {
    getUserInputs(userId, setUserInputs);
  };

  React.useEffect(fetchUserInputs, []);

  if (!userInputs)
    return (
      <div
        style={{
          backgroundColor: "#f7f7f7",
          position: "fixed",
          zIndex: 100,
          top: "10vh",
          margin: "auto",
          left: 0,
          right: 0,
          width: 800,
          minHeight: 650,
        }}
      >
        <SimpleSpinner message="Loading history" />
      </div>
    );

  return (
    <div
      style={{
        backgroundColor: "#f7f7f7",
        position: "fixed",
        zIndex: 100,
        top: "10vh",
        margin: "auto",
        left: 0,
        right: 0,
        width: 800,
      }}
    >
      <div
        style={{
          position: "absolute",
          right: -10,
          borderRadius: 24,
          border: "2px solid rgb(128, 128, 128)",
          top: -10,
          background: "white",
          width: 24,
          textAlign: "center",
          zIndex: 2000,
        }}
        onClick={() => setHistoryVisible(false)}
      >
        X
      </div>
      <TableContainer component={Paper} style={{ height: "80vh" }}>
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Edit</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Load</TableCell>
              <TableCell align="right">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userInputs
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .map((userInput) => (
                <HistoryRow
                  key={userInput.input_id}
                  userId={userId}
                  userInput={userInput}
                  identityId={identityId}
                  setHistoryVisible={setHistoryVisible}
                  fetchUserInputs={fetchUserInputs}
                  dispatch={dispatch}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default History;
