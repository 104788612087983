import {getUserEmail} from "../userIdentity";
import {GetUserDetailsCommand, Region} from "@wisogis/user-details-sdk";
import {userApiClient} from "../apiClient";
import {showErrorSnackbar} from "../actions/snackbarActions";

export const getUserLicense = async (props) => {
  // props.dispatch({ type: SET_API_LOADING_LOCATION_DATA, payload: true });

  try {
    const userId = getUserEmail()
    const userDetail = await userApiClient.send(new GetUserDetailsCommand({
      uid: userId
    }))
    const license = userDetail.license
    let region = userDetail.region

    switch (region) {
      case Region.AFRICA: region="Africa"; break;
      case Region.ASIA: region="Asia"; break;
      case Region.EUROPE: region="Europe"; break;
      case Region.EURASIA: region="Eurasia"; break;
      case Region.MIDDLE_EAST: region="Middle East"; break;
      case Region.NORTH_AMERICA: region="North America"; break;
      case Region.OCEANIA: region="Oceania"; break;
      case Region.SOUTH_CENTRAL_AMERICA: region="South & Central America"; break;
    }

    props.dispatch({
      type: "SET_USER_DATA",
      payload: { //todo: update it with the new api response
        company: null,
        email: userId,
        license_type: license,
        region: region,
        user_group: null,
        user_id: userId,
      },
    });
  } catch (e) {
    props.dispatch(showErrorSnackbar("An error occured"))
  }
};
