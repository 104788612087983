import {simulationApiClient} from "../apiClient";
import {ListSimulationsCommand, SimulationState} from "@wisogis/simulation-sdk";
import {showErrorSnackbar} from "../actions/snackbarActions";

export const getUserInputs = async (userId, successCb) => {

  const simulationList = []
  let listSimulationResult
  try {
    do {
      listSimulationResult = await simulationApiClient.send(new ListSimulationsCommand({
        uid: userId,
        pageSize: 25,
        nextToken: listSimulationResult?.nextToken
      }))
      const simulationsReady = listSimulationResult.items.filter(
          simulation => simulation.state === SimulationState.READY
      )
      simulationList.push(...simulationsReady)
    } while (listSimulationResult?.nextToken !== undefined)
  } catch (err) {
    props.dispatch(showErrorSnackbar("An error occurred"))
    return "Error during data fetching"
  }

  const mappedSimulationList = simulationList.map((simulation) => ({
    layers: simulation.parameters.layer_params,
    input_id: simulation.id,
    heatmap_id: simulation.id,
    grid_connection_type: simulation.parameters.grid_connection_type.toLowerCase(),
    model_WT: simulation.parameters.model_WT,
    slope_cost_per_percent: simulation.parameters.slope_cost_per_km,
    name: simulation.name,
    LCOE_params: simulation.parameters.lcoe_params,
    user_id: simulation.uid,
    date: simulation.dates.created,
    opti: simulation.parameters.optimization.toLowerCase(),
    pv_tracking: simulation.parameters.pv_tracking,
    combined_slider: simulation.parameters.combined,
    grid_cost_per_km: simulation.parameters.grid_cost_per_km,
    max_slope: simulation.parameters.max_slope,
    endpoint: "heatmap",
  }))

  return successCb(mappedSimulationList)
};
